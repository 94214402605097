(function () {
    'use strict';
    angular
        .module('app')
        .component('authRedirectPage', {
            controller: AuthRedirectController,
            bindings: {},
            templateUrl: 'app/pages/auth-redirect-page/auth-redirect-page.component.html'
        });

    AuthRedirectController.$inject = ['authService'];

    function AuthRedirectController(authService) {
      authService.login();
    }

})();
