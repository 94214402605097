(function (){
    'use strict';

    angular
        .module('app')
        .service ('ErrorService', ErrorService);


    ErrorService.$inject = ['WebSocketConnectionService'];

    function ErrorService(WebSocketConnectionService) {

        var me = this;
        me.errors = [];

        var errorMessages = {
            RECORD_NOT_FOUND: "com.exceptions.RecordNotFoundException"
        };

        var handleErrorResponse = function(response,callback){
            var error = JSON.parse(response.content);
            console.debug(error);
            me.errors.push(error);
            if(callback)
                callback(error);
        };

        return {
            errorMessages: errorMessages,
            subscribeMediaErrors: function (projectId, callback) {
                me.errors = [];
                WebSocketConnectionService.subscribe('errors', projectId, function (response) {
                    handleErrorResponse(response, callback);
                });
            },
            unsubscribeFromMediaErrors: function (projectId) {
                WebSocketConnectionService.unsubscribe('errors', projectId);
            },
            subscribeUnexpectedErrors: function(userId, callback) {
                me.errors = [];
                WebSocketConnectionService.subscribe('unexpectedErrors', userId, function (response) {
                    handleErrorResponse(response, callback);
                });
            }
        };
    }

})();

