(function () {
    'use strict';

    angular
        .module('app')
        .directive('limitInputChar', limitInputChar);

    limitInputChar.$inject = ['$timeout'];

    function limitInputChar($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attrs, ngModelCtrl) {
                var limit = parseInt(attrs.limitInputChar);

                elem.bind('input', function(e) {

                    var me = this;
                    if (me.value.length >= limit) {
                        if (me.value.length > limit) {
                            $timeout(function () {
                                me.value = me.value.substring(0, limit);
                                ngModelCtrl.$setViewValue(me.value);
                            });
                        }
                        e.preventDefault();
                    }
                });
            }
        };
    }

})();

