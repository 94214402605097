(function () {
    'use strict';
    angular
        .module('app')
        .controller('latestTransactionController', LatestTransactionController);
    LatestTransactionController.$inject = [
        '$scope',
        '$state',
        '$window',
        'adminUserRestService',
        'languagesService',
        'latestTransactionService'
    ];

    function LatestTransactionController(
        $scope,
        $state,
        $window,
        adminUserRestService,
        languagesService,
        latestTransactionService

    ) {
        var temp = [];
        var counter = 0;
        $scope.selectedUser = null;
        $scope.language = '';
        $scope.totalTimeUsed = "";
        $scope.searchText = null;
        $scope.loadMoreBtn = "Load more " + $scope.pageSize + " records";
        function init() {
            $scope.transaction_ = [];
            $scope.hideMoreDiv = true;
            $scope.pageSize = 50;
            $scope.pageNumber = 0;
            counter = 0;
            $scope.loadMoreBtn = "Load more " + $scope.pageSize + " records";
        }

        init();
        getFilteredTransaction();
        languagesService.getAll()
            .then(function (languages) {

                $scope.languageRegions = buildLanguageRegionsToDisplay(languages);
                for (var i = 0; i < $scope.languageRegions.length; i++) {
                    var tempLanguage_ = $scope.languageRegions[i];
                    var tempLanguage = tempLanguage_.code + ':' + tempLanguage_.defaultSpeechApi;
                    temp.push(tempLanguage);
                }
                $scope.languageRegions_ = temp;
            });

        $scope.autoCompleteOptions = function (searchText) {
            var results = searchText ? $scope.usersListing.filter(createFilterFor(searchText)) : $scope.usersListing;
            return results;
        };

        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(item) {
                return ((angular.lowercase(item.fullName)).indexOf(lowercaseQuery) === 0
                    || (angular.lowercase(item.email)).indexOf(lowercaseQuery) === 0);
            };
        }
        $scope.getName = function (item) {
            return item.fullName + " (" + item.email + ")";
        }
        adminUserRestService.getAll()
            .then(function (response) {
                $scope.usersListing = response;
            });
        $scope.transactionHistory = function () {
            init();
            getFilteredTransaction();
        };
        function buildLanguageRegionsToDisplay(languages) {
            var languagesToDisplay = [];
            languages = _.orderBy(languages, ['label']);
            languages.forEach(function (language) {
                language.regions = _.orderBy(language.regions, ['name']);
                language.regions.forEach(function (region) {
                    languagesToDisplay.push({
                        label: language.label + " (" + region.name + ")",
                        code: language.code + (region.code ? "-" + region.code : ""),
                        defaultSpeechApi: region.defaultSpeechApi
                    });
                });
            });
            return languagesToDisplay;
        }
        function getFilteredTransaction(loadMore) {

            var day = null;
            var day_ = null;
            var userId = null;
            if ($scope.filterDate !== null && typeof $scope.filterDate !== 'undefined') {
                var date = new Date($scope.filterDate);
                day = date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate();
            }
            if ($scope.filterEndDate !== null && typeof $scope.filterEndDate !== 'undefined') {
                var date = new Date($scope.filterEndDate);
                date.setDate(date.getDate() + 1);
                day_ = date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate();
            }
            if ($scope.selectedUser) {
                userId = $scope.selectedUser.id;
            }

            var transactionFilterPayload = {
                "userIdSearch": userId,
                "fromDateSearch": day,
                "toDateSearch": day_,
                "pageNumber": $scope.pageNumber,
                "pageSize": $scope.pageSize,
                "enablePagination": true
            };
            latestTransactionService.getProjects(transactionFilterPayload)
                .then(function success(resource) {
                    $scope.transaction_ = $scope.transaction_ || [];
                    $scope.totalSeconds = resource.data.totalSeconds;
                    $scope.countOfCall = resource.data.countOfCall;
                    $scope.totalLength = resource.data.countOfCall;
                    for (var i = 0; i < resource.data.projects.length; i++) {
                        $scope.transaction_.push(resource.data.projects[i]);
                    }

                    if (resource.data.projects.length > 0) {
                        $scope.hideMoreDiv = false;
                        counter = counter + 1;
                        $scope.totalTimeUsed = resource.totalMinutes;
                    }


                    var countShowHideLoadMore = $scope.pageSize * counter;
                    if (countShowHideLoadMore >= $scope.totalLength) {
                        $scope.hideMoreDiv = true;
                    }
                }, function error(error) {
                    $scope.resource = error;
                });
        }


        $scope.loadMoreData = function () {
            $scope.pageNumber = counter;
            getFilteredTransaction();
        };
        $scope.getDetailedTransactionData = function (projectId) {
            if (projectId) {
              var url = $state.href('app.admin.detailedTransaction',  {projectId: projectId});
              $window.open(url, '_blank');
            }
        };
    }
}());
