(function () {
  'use strict';

  angular
    .module('app')
    .factory('authInterceptor', authInterceptor);

  authInterceptor.$inject = ['$q', 'authService', 'httpErrorHandler']

  function authInterceptor($q, authService, httpErrorHandler) {

    function constructToken(accessToken) {
      return authService.getTokenType() + ' ' + accessToken;
    };

    function setAuthHeader(config) {
      var token = authService.getAccessToken();
      config.headers[authService.getAuthHeaderName()] = constructToken(token);
    };

    return {
      request: function (config) {
        config.headers = config.headers || {};
        setAuthHeader(config);
        return config || $q.when(config);
      },
      responseError: function (rejection) {
        var config = rejection.config;
        var status = rejection.status;
        var data = rejection.data;
        var error = null;
        if (data && data.error) {
          error = data.error;
        }
        if (data && data.message) {
          error = data.message;
        }

        httpErrorHandler.manageHttpCode(status, error, config);

        return $q.reject(rejection) || {};
      }
    };
  }

})();
