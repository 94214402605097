(function () {
    'use strict';
    angular
        .module('app')
        .factory('usersRestService', usersRestService);

    usersRestService.$inject = ['$http', 'host'];
    function usersRestService($http, host) {
        var baseUrl = host + 'user';

        return {
            getUserInfo: getUserInfo,
            getHash: getHash,
            getTransactions: getTransactions,
            getInviteCode: getInviteCode,

            getUserTags: getUserTags,
            createUserTags: createUserTags,
            updateUserTags: updateUserTags,
            deleteUserTags: deleteUserTags,
            getUserProjectTags: getUserProjectTags,
            associateTagToProject: associateTagToProject,
            desassociateTagFromProject: desassociateTagFromProject
        };

        function getUserInfo() {
            // TODO: Change enpoint url this when you are going to be refatoring tokens
            return $http.get(baseUrl)
                .then(function (response) {
                    if (response.data && response.data.error) {
                        throw new Error(response.data.error);
                    }
                    return response.data;
                });
        }

        function getHash() {
            return $http.get(baseUrl + '/hash')
                .then(function (response) {
                    return response.data;
                });
        }

        function getTransactions() {
            return $http.get(host +'user/transactions')
                .then(function (response) {
                    return response.data;
                });
        }

        function getInviteCode() {
            return $http.get(host + 'user/inviteCode')
                .then(function (response) {
                    return response.data;
                });
        }

        function getUserTags() {
            return $http.get(baseUrl + '/tags')
                .then(function (response) {
                  return response.data;
                });
        }

        function createUserTags(userTag) {
            return $http.post(baseUrl + '/tags', userTag)
                .then(function (response) {
                    return response.data;
                });
        }

        function updateUserTags(tagId, userTag) {
            return $http.put(baseUrl + '/tags/' + tagId, userTag)
                .then(function (response) {
                    return response.data;
                });
        }

        function deleteUserTags(tagId) {
            return $http.delete(baseUrl + '/tags/' + tagId);
        }

        function getUserProjectTags() {
            return $http.get(baseUrl + '/project-tags')
            .then(function (response) {
                return response.data;
            });
        }

        function associateTagToProject(projectUserTag) {
            return $http.post(baseUrl + '/project-tags', projectUserTag)
            .then(function (response) {
                return response.data;
            });
        }

        function desassociateTagFromProject(projectUserTagId) {
            return $http.delete(baseUrl + '/project-tags/' + projectUserTagId);
        }
    }
})();
