(function () {
  'use strict';
  angular
    .module('app')
    .component('appPage', {
      controller: AppController,
      bindings: {
        user: '<'
      },
      templateUrl: 'app/pages/app-page/app-page.component.html'
    });

  AppController.$inject = [
    '$rootScope',
    '$state',
    '$window',
    'APP_CONFIG',
    'authService',
    'usersRestService',
    'fullStoryWrapperService',
    'WebSocketConnectionService',
    'whitelabel',
    'intercomId'
  ];

  function AppController(
    $rootScope,
    $state,
    $window,
    APP_CONFIG,
    authService,
    usersRestService,
    fullStoryWrapperService,
    WebSocketConnectionService,
    whitelabel,
    intercomId
  ) {

    var me = this;
    var userInfoChangeListener;
    var statesWithoutHeader = [
      'landingPage',
      'authCallbackPage'
    ];

    me.$onInit = onInit;
    me.$onChanges = onChanges;
    me.$onDestroy = onDestroy;

    me.canHideHeader = canHideHeader;
    me.isLandinPage = isLandinPage;
    me.onMenuItemClick = onMenuItemClick;
    me.logout = logout;
    me.login = login;
    me.userHasCredits = userHasCredits;
    me.isTeamOwner = isTeamOwner;
    me.whitelabel = whitelabel;

    function onInit() {
      me.logoLinkHref = APP_CONFIG.ROUTE_PREFIX;
    }

    function onChanges(changes) {
      if (changes.user && changes.user.currentValue) {
        $rootScope.user = changes.user.currentValue;
        $rootScope.credit = getCredits($rootScope.user);
        subscribeToUserDataUpdates();

        tryToInitializeIntercom($rootScope.user);
        fullStoryWrapperService.identify($rootScope.user);
      } else {
        unSubscribeToUserDataUpdates();
      }
      buildMenuItemList();
    }

    function onDestroy() {
      unsubscribeFromEvents();
    }

    function tryToInitializeIntercom(user) {
      if (user && user.id) {
        if (!$rootScope.isIntercomInitialized) {
          var userInfoForIntercom = angular.copy(user);
          userInfoForIntercom.app_id = intercomId;

          usersRestService.getHash()
            .then(function (response) {
              userInfoForIntercom.user_hash = response.value;
              $window.Intercom('boot', userInfoForIntercom);
              $rootScope.isIntercomInitialized = true;
            });
        } else {
          $window.Intercom("update");
        }
      }
    }

    function login() {
      authService.login();
    }

    function logout() {
      unSubscribeToUserDataUpdates();

      fullStoryWrapperService.logout();

      $rootScope.user = null;
      $rootScope.isIntercomInitialized = false;

      $window.Intercom('shutdown');
      authService.logout();
    }

    function unSubscribeToUserDataUpdates() {
      if ($rootScope.user && $rootScope.user.id) {
        WebSocketConnectionService.unsubscribe('userData', $rootScope.user.id);
      }
    }

    function subscribeToUserDataUpdates() {
      if ($rootScope.user && $rootScope.user.id) {
        WebSocketConnectionService.subscribe('userData', $rootScope.user.id, function (data) {
          if (data) {
            $rootScope.user = data;
            $rootScope.credit = getCredits($rootScope.user);
          }
        });
      }
    }

    function canHideHeader() {
      return statesWithoutHeader.indexOf($state.current.name) !== -1 || $rootScope.isStickyActive;
    }

    function isLandinPage() {
      return $state.current.name === 'landingPage';
    }

    function getCredits(user) {
      // TODO: Review if this calculation is correct
      var credit = 0;
      if (user && user.team) {
        credit = moment.duration(user.team.credit + user.team.subscriptionCredit, 'minutes')._data;
      } else if (user) {
        credit = moment.duration(user.credit + user.subscriptionCredit, 'minutes')._data;
      }
      return credit;
    }

    function buildMenuItemList() {
      if (!_.isEmpty($rootScope.user)) {
        var list = [
          {
            name: 'profile',
            displayName: 'My Profile',
            classAttribute: 'profile-link',
            iconClass: 'fa fa-cog',
            callback: function () {
              $state.go('app.accountSettings', { '#': 'Profile' });
            }
          },
          {
            name: 'transactionHistory',
            displayName: 'Transaction History',
            callback: function () {
              $state.go('app.accountSettings', { '#': 'TransactionHistory' });
            }
          }
        ];

        if (authService.isAdmin($rootScope.user)) {
          list = addAdminItems(list);
        }

        if (authService.isSuperAdmin($rootScope.user)) {
          list = addSuperAdminItems(list);
        }

        me.menuItemList = list;
      } else {
        me.menuItemList = [];
      }
    }

    function addAdminItems(menuItemList) {
      var adminItemList = [
        {
          name: 'latestTransactions',
          displayName: 'Latest Transactions',
          callback: function () {
            $state.go('app.admin.latestTransaction');
          }
        },
        {
          name: 'blackwhiteList',
          displayName: 'BlackList / WhiteList',
          callback: function () {
            $state.go('app.admin.blacklistWhitelist');
          }
        },
        {
          name: 'userLookUp',
          displayName: 'User Look-Up',
          callback: function () {
            $state.go('app.admin.userManagementList');
          }
        },
        {
          name: 'plans',
          displayName: 'Plans',
          callback: function () {
            $state.go('app.admin.plans');
          }
        },
        {
          name: 'defaultConfig',
          displayName: 'Default Config',
          callback: function () {
            $state.go('app.admin.defaultConfig');
          }
        },
        {
          name: 'team',
          displayName: 'Team',
          callback: function () {
            $state.go('app.admin.teamManagementList');
          }
        },
        {
          name: 'metrics',
          displayName: 'Metrics',
          callback: function () {
            $state.go('app.admin.metrics');
          }
        },
        {
          name: 'publishedProjects',
          displayName: 'Published Projects',
          callback: function () {
            $state.go('app.admin.publishedProjects');
          }
        }
      ]
      return menuItemList.concat(adminItemList);
    }

    function addSuperAdminItems(menuItemList) {
      var superAdminItemList = [
        {
          name: 'addRemoveAdmin',
          displayName: 'Add/Remove Admin',
          callback: function () {
            $state.go('app.admin.adminManagement');
          }
        }
      ]
      return menuItemList.concat(superAdminItemList);
    }

    function unsubscribeFromEvents() {
      unSubscribeToUserDataUpdates();

      if (angular.isFunction(userInfoChangeListener)) {
        userInfoChangeListener();
      }
    }

    function onMenuItemClick(itemName) {
      var item = _.find(me.menuItemList, { name: itemName });
      if (item && angular.isFunction(item.callback)) {
        item.callback();
      }
    }

    function userHasCredits() {
      if (!$rootScope.credit) {
        return false;
      }

      return $rootScope.credit.days > 0 ||
        $rootScope.credit.hours > 0 ||
        $rootScope.credit.minutes > 0 ||
        $rootScope.credit.seconds > 0;
    }

    function isTeamOwner() {
      if ($rootScope.user && $rootScope.user.team) {
        return [$rootScope.user.email, $rootScope.user.cleanEmail].indexOf($rootScope.user.team.owner) !== -1;
      }
      return false;
    }

  }

})();
