(function () {
    'use strict';

    angular
        .module('app')
        .component('quickTipsForUploadModal', {
            controller: QuickTipsForUploadModalController,
            bindings: {
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'app/components/quick-tips-for-upload-modal/quick-tips-for-upload-modal.html'
        });

    QuickTipsForUploadModalController.$inject = [];

    function QuickTipsForUploadModalController() {
        var me = this;

        me.cancel = cancel;

        function cancel() {
            me.dismiss();
        }
    }

})();
