(function () {
    'use strict';
    angular
        .module('app')
        .component('authCallbackPage', {
            controller: AuthCallbackController,
            bindings: {},
            templateUrl: 'app/pages/auth-callback-page/auth-callback-page.component.html'
        });

    AuthCallbackController.$inject = [];

    function AuthCallbackController() {}

})();
