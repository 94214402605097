(function () {
  'use strict';

  angular
    .module('app', [
        'ngConstants',
        'ngRoute',
        'dndLists',
        'ngSanitize',
        'ngAnimate',
        'ngMaterial',
        'ngCookies',
        'ui.router',
        'angular-loading-bar',
        'angular-svg-round-progressbar',
        'com.2fdevs.videogular',
        'com.2fdevs.videogular.plugins.controls',
        'com.2fdevs.videogular.plugins.overlayplay',
        'com.2fdevs.videogular.plugins.poster',
        'com.2fdevs.videogular.plugins.buffering',
        'uk.ac.soton.ecs.videogular.plugins.cuepoints',
        'angularSpinner',
        'slickCarousel',
        'sticky',
        'angular-google-analytics',
        'ui.bootstrap',
        'toastr',
        'angularMoment',
        'ui.mask',
        'ngFileUpload',
        '720kb.datepicker',
        'ui.select',
        'ng-visible-invisible',
        'auth0.auth0',
        'angular-storage',
        'lk-google-picker',
        'ai.simonCommonUi'
    ]);

})();
