(function () {
  'use strict';
  angular
    .module('app')
    .service('TranscriptionService', TranscriptionService);

    TranscriptionService.$inject = ['$http', '$q', 'authService', 'host'];

    function TranscriptionService($http, $q, authService, host) {
      return {
        saveTranscript: saveTranscript,
        delete: deleteTranscription,
        saveSync: saveSync,
        save: function (transcription, mediaId) {
          var transcriptionCopy = angular.copy(transcription);
          delete transcriptionCopy.owner;
          return $http.post(host + 'media/' + mediaId + '/transcription', transcriptionCopy);
        },
        bookmark: function (transcription, mediaId) {
          transcription.bookmark = !transcription.bookmark;
          return this.save(transcription, mediaId);
        },
        getByMedia: function (mediaId) {
          return $http({
            url: host + 'media/' + mediaId + '/transcription',
            method: 'GET'
          });
        }
      };

      function saveTranscript(transcriptionRows, mediaId) {
        var url = host + 'media/' + mediaId + '/transcript';

        // if (isSync) {
        //   return $q(function (resolve, reject) {
        //     $.ajax({
        //       url: url,
        //       async: false,
        //       type: 'POST',
        //       headers: {
        //         Authorization: authService.getTokenType() + ' ' + authService.getAccessToken()
        //       },
        //       data: JSON.stringify(transcriptionRows),
        //       contentType: 'application/json',
        //       dataType: 'json'
        //     })
        //       .done(function (data) {
        //         console.log('data');
        //         console.log(data);
        //         resolve(data);
        //       })
        //       .fail(function (reason) {
        //         console.log('reason');
        //         console.log(reason);
        //         reject(reason);
        //       })
        //   });
        // }

        return $http.post(url, transcriptionRows);

      }

      function deleteTranscription(mediaId, id) {
        return $http.delete(host + 'media/' + mediaId + '/transcription/' + id);
      }

      function saveSync(transcription, mediaId) {
        var transcriptionCopy = angular.copy(transcription);
        delete transcriptionCopy.owner;
        $.ajax({
          url: host + "transcription/media/" + mediaId,
          async: false,
          type: 'POST',
          data: JSON.stringify(transcriptionCopy),
          contentType: 'application/json',
          dataType: 'json'
        });
      }
    }

})();

