(function () {
  'use strict';
  angular
    .module('app')
    .component('adminPlansPage', {
      controller: AdminPlansController,
      bindings: {},
      templateUrl: 'app/admin/pages/admin-plans-page/admin-plans-page.component.html'
    });

  AdminPlansController.$inject = ['plansRestService'];

  function AdminPlansController(plansRestService) {

    var me = this;

    me.$onInit = onInit;

    function onInit() {
      plansRestService.getAll()
        .then(function (response) {
          me.plans = response;
        })
    }

  }
}());
