(function () {
    'use strict';

    angular
        .module("app")
        .filter('nl2br', nl2br);

        function nl2br($sce){
            return function nl2brFilter(msg) {
                var breakTag = '<br />';
                var msg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
                return $sce.trustAsHtml(msg);
            }
        }
})();
