(function () {
    'use strict';
    angular
        .module('app')
        .service('metricsRestService', metricsRestService);

    metricsRestService.$inject = ['$http', '$httpParamSerializer', 'host'];
    function metricsRestService($http, $httpParamSerializer, host) {
        return {
            query: query
        };

        function query(queryParams) {
            var queryString = $httpParamSerializer(queryParams);

            var resourceUrl = host + 'metrics?' + queryString ;
            return $http.get(resourceUrl)
                .then(function (response) {
                   return response.data;
                });
        }
    }
})();
