
(function () {
    'use strict';
    angular
        .module('app')
        .service('MediaService', MediaService);

    MediaService.$inject = [
        '$http',
        '$rootScope',
        '$timeout',
        '$window',
        'MEDIA_THUMBNAILS_IMAGES',
        'SUPPORTED_MEDIA_FILES',
        'authService',
        'fileHelperService',
        'host',
        'CustomAnalyticsService',
        'WebSocketConnectionService'
    ];

    function MediaService(
        $http,
        $rootScope,
        $timeout,
        $window,
        MEDIA_THUMBNAILS_IMAGES,
        SUPPORTED_MEDIA_FILES,
        authService,
        fileHelperService,
        host,
        CustomAnalyticsService,
        WebSocketConnectionService
    ) {

        var maxLocalSize = 5000000000;
        var maxSize = 20000000000;

        var errorMessageRemoteFileMaxSize = 'File size is far too large, my friend. I can only process files up to 20gb. Try uploading a smaller file size.';
        var errorMessageLocalUploader = 'Oh gosh. The file size limit is 5Gbs via local file upload. Instead import via a cloud drive such as Dropbox, Google Drive, or Box (20GB limit) or download our' +
          '<a target="_blank", href="https://itunes.apple.com/app/simon-says-transcription/id1441555493"> macOS app </a>' + ' (no limit)';

        var uploaderType = {
            local: 'LOCAL',
            dropbox: 'DROPBOX',
            drive: 'DRIVE',
            box: 'BOX'
        };

        var progressByProject = {};

        return {
            progressByProject: progressByProject,
            getBoxToken: getBoxToken,
            getURIBaseToken: getURIBaseToken,
            uploadFile: uploadFile,
            startListeningUploadEvents: startListeningUploadEvents,
            downloadBoxFiles: downloadBoxFiles,
            uploadFiles: uploadFiles,
            startListeningUploadProgress: startListeningUploadProgress,
            stopListeningUploadProgress: stopListeningUploadProgress,
            isMediaUploading: isMediaUploading,
            prepareLocalFile: prepareLocalFile,
            getSignedUrl: getSignedUrl,
            uploadLocalFile: uploadLocalFile,
            prepareDropboxFile: angular.bind(this, prepareRemoteFile, uploaderType.dropbox),
            prepareDriveFile: angular.bind(this, prepareRemoteFile, uploaderType.drive),
            prepareBoxFile: angular.bind(this, prepareRemoteFile, uploaderType.box),

            saveMediaData: saveMediaData,
            delete: deleteMedia,
            tryToLock: tryToLock,
            tryToUnLock: tryToUnLock,
            tryToUnLockSync: tryToUnLockSync,
            hasProjectUploads: hasProjectUploads
        };

        function getSignedUrl(projectId, data, callbackSuccess, callbackError) {

            return $http.post(host + 'projects/' + projectId + '/signed-url-to-upload', data)
            .then(function (response){
                callbackSuccess(response);
            }).catch(function (error){
                callbackError(error);
            });
        }

        function uploadFiles(files, project, callbackSuccess, callbackError) {
            for (var i = 0; i < files.length; ++i) {
                var file = files[i];

                var fileName = file.name || file.fileName;
                var fileExtension = fileHelperService.getFileExtension(fileName);

                if (SUPPORTED_MEDIA_FILES[fileExtension]) {
                    var mediaType = SUPPORTED_MEDIA_FILES[fileExtension].type;
                    var newMedia = {
                        name: file.name,
                        project: project,
                        frameFilePath: MEDIA_THUMBNAILS_IMAGES[mediaType]
                    };

                    create(
                        angular.copy(newMedia),
                        file,
                        (function (file, callbackSuccess) {
                            return function (media) {
                                callbackSuccess(media, file);
                            };
                        })(file, callbackSuccess),
                        callbackError
                    );
                } else {
                  callbackError('Gosh. ' + fileName + ' is an unsupported format. See FAQ for acceptable formats.');
                }
            }
        }
        function downloadBoxFiles(token, fileId) {
            return $http.get(host + 'boxAuth/downloadFile/' + token + "/" + fileId);
        }
        function startListeningUploadProgress(projectId) {

            if (!progressByProject[projectId])
                progressByProject[projectId] = {};
            if (!progressByProject[projectId].medias)
                progressByProject[projectId].medias = {};

            WebSocketConnectionService.subscribe('uploadProgress', projectId, function onStartUploadProgress(response) {
                console.log(response);
                var mediaId = response.mediaId;
                var nextProgress = parseInt(response.progress, 10);

                var status = nextProgress > 39 ? nextProgress > 99 ? 'ready' : 'transcoding' : 'uploading';

                if (!progressByProject[projectId].medias[mediaId]) {
                    progressByProject[projectId].medias[mediaId] = {};
                }

                var currentProgress = progressByProject[projectId].medias[mediaId].progress;
                if (!currentProgress || (nextProgress > currentProgress)) {
                  progressByProject[projectId].medias[mediaId] = {
                      status: status,
                      progress: nextProgress
                  };
                  $rootScope.$emit('mediaProgressChange', createMediaProgressUpdate(mediaId, projectId, progressByProject[projectId].medias[mediaId]));
                }

            });
        }

        function createMediaProgressUpdate(mediaId, projectId, info) {
            var mediaProgressUpdate = angular.copy(info);
            mediaProgressUpdate.mediaId = mediaId;
            mediaProgressUpdate.projectId = projectId;
            return mediaProgressUpdate;
        }

        function stopListeningUploadProgress(projectId) {
            WebSocketConnectionService.unsubscribe('uploadProgress', projectId);
            delete progressByProject[projectId];
        }

        function isMediaUploading(projectId, mediaId) {
            var project = progressByProject[projectId] || {};
            var medias = project.medias || {};
            var media = medias[mediaId];

            return media && media.progress < 100;
        }

        function prepareLocalFile(projectId, mediaId, file, callback, callbackError) {

            if (!progressByProject[projectId])
                progressByProject[projectId] = {};
            if (!progressByProject[projectId].medias)
                progressByProject[projectId].medias = {};

            if (file.size < maxLocalSize) {
                var reader = new FileReader();

                reader.onprogress = function (evt) {
                    // if evt is an ProgressEvent.
                    if (evt.lengthComputable) {
                        var percentLoaded = Math.ceil((evt.loaded / evt.total) * 100);
                        if (percentLoaded <= 100) {

                            progressByProject[projectId].medias[mediaId] = {
                                status: 'preparing',
                                progress: percentLoaded
                            };

                            $rootScope.$emit('mediaProgressChange', createMediaProgressUpdate(mediaId, projectId, progressByProject[projectId].medias[mediaId]));
                        }
                    }
                };
                reader.onload = (function (e) {
                    return (function () {
                        callback({
                            type: uploaderType.local,
                            mediaId: mediaId,
                            fileName: file.name,
                            fileContent: e.target.result,
                            fileSize: file.size
                        });
                    })();
                });
                reader.readAsDataURL(file);
            } else {
                callbackError(errorMessageLocalUploader, mediaId);
            }
        }

        function prepareRemoteFile(uploaderType, projectId, mediaId, file, callbackError) {

            var fileSize = file.bytes || file.size;
            if (fileSize >= maxSize) {
                callbackError(errorMessageRemoteFileMaxSize, mediaId);
                return;
            }

            if (!progressByProject[projectId])
                progressByProject[projectId] = {};
            if (!progressByProject[projectId].medias)
                progressByProject[projectId].medias = {};

            progressByProject[projectId].medias[mediaId] = {
                status: 'preparing',
                prepareProgress: 100
            };

            return {
                type: uploaderType,
                fileName: file.name,
                fileSize: fileSize,
                linkToFile: file.link,
                accessToken: file.accessToken
            };
        }

        function create(media, file, callbackSuccess, callbackError) {
            var copy = angular.copy(media);
            delete copy.project;

            return $http.post(host + 'project/' + media.project.id + '/media/', copy)
                .then(function (response) {
                    response.status = 'preparing';
                    callbackSuccess(response, file);
                })
                .catch(function (reason) {
                    if (reason && reason.data && reason.data.error) {
                        callbackError(reason.data.message);
                    }
                });
        }
        function getBoxToken(projectId) {
            var data = {
                projectId: projectId
            }
            return $http.post(host + 'boxAuth/authorize/' + projectId, data);
        }
        function getURIBaseToken(code) {
            var data = {
                grant_type: 'authorization_code',
                code: code,
                client_id: 'e1s0x5mmy04sb93o027bs5lker0lqofo',
                client_secret: '5uEju2T8eXNe1oTFQsp1Q0HWv4sIh3ty'
            }
            return $http.post("https://api.box.com/oauth2/token", data);

        }
        function saveMediaData(media) {
            return $http.put(host + 'media/' + media.id, media);
        }

        function deleteMedia(projectId, mediaId) {
            return new Promise(function(resolve, reject) {
              $http.delete(host + 'media/' + mediaId)
              .then(function() {
                //remove progress from project once deleted (if we did it earlier, it'd be recreated from socket update)
                delete progressByProject[projectId].medias[mediaId];
                resolve();
              })
              .catch(function(reason) {
                //remove even if failed
                delete progressByProject[projectId].medias[mediaId];
                reject(reason);
              })
            })
        }

        function uploadFile(preparedFile, media, projectId, callbackError) {
            CustomAnalyticsService.trackEvent('Upload', 'Uploading file', null, null);
            var mediaId = media.id;
            if (preparedFile.fileSize < maxSize) {

                var progressListener = function (evt) {
                    if (preparedFile.type === uploaderType.local) {
                        console.debug('Progress: ');

                        if (evt.lengthComputable) {
                            //Transcoding needs to be taken under consideration. That's above on the uploadProgress websocket connection.
                            var percentComplete = Math.ceil((evt.loaded / evt.total) * 40);
                            var mediaProcessing = progressByProject[projectId].medias[mediaId];

                            if (mediaProcessing) {
                                mediaProcessing.status = 'uploading';
                                mediaProcessing.progress = percentComplete;
                            }

                            $rootScope.$emit('mediaProgressChange', createMediaProgressUpdate(mediaId, projectId, progressByProject[projectId].medias[mediaId]));
                        }
                    }
                };

                // TODO: Refactor me for God sake
                $.ajax({
                    xhr: function () {
                        var xhr = new window.XMLHttpRequest();
                        xhr.upload.addEventListener('progress', progressListener, false);

                        return xhr;
                    },
                    type: 'POST',
                    contentType: 'application/json',
                    url: host + 'media/' + mediaId + '/upload',
                    headers: {
                      Authorization: authService.getTokenType() + ' ' + authService.getAccessToken()
                    },
                    data: JSON.stringify(preparedFile),
                    success: function (uploadedMedia) {
                        progressByProject[projectId].medias[mediaId].status= "🏎 Starting";
                        $rootScope.$emit('uploadCallResponse', mediaId);
                    },
                    error: function (err) {
                        if (err.status != 502) {
                            callbackError('Upload cancelled.', mediaId);
                            delete progressByProject[projectId].medias[mediaId];
                        }
                        console.debug(err);
                    }
                });
            } else {
                callbackError(errorMessageRemoteFileMaxSize, mediaId);
            }
        }

        function pushMessageQueueForUpload(mediaId,data, callbackSuccess, callbackError){
            return $http.post(host + 'media/' + mediaId + '/upload', data)
            .then(function (response){
                callbackSuccess(response);
            }).catch(function (error){
                callbackError(error);
            });
        }

        function uploadLocalFile(file, objectKey, mediaId, projectId, signedUrl, callbackError) {
            if (!progressByProject[projectId])
                progressByProject[projectId] = {};
            if (!progressByProject[projectId].medias)
                progressByProject[projectId].medias = {};

                progressByProject[projectId].medias[mediaId] = {
                status: 'uploading',
                progress: 0
            };

            CustomAnalyticsService.trackEvent('Upload', 'Uploading file', null, null);

            if (file.size <= maxLocalSize) {
                var progressListener = function (evt) {

                        if (evt.lengthComputable) {
                            //Transcoding needs to be taken under consideration. That's above on the uploadProgress websocket connection.
                            var percentComplete = Math.ceil((evt.loaded / evt.total) * 40);
                            var mediaProcessing = progressByProject[projectId].medias[mediaId];

                            if (mediaProcessing) {
                                mediaProcessing.status = 'uploading';
                                mediaProcessing.progress = percentComplete;
                            }
                            $rootScope.$emit('mediaProgressChange', createMediaProgressUpdate(mediaId, projectId, progressByProject[projectId].medias[mediaId]));
                        }
                };

                // TODO: Refactor me for God sake
                var uploaderPromise = $.ajax({
                    xhr: function () {
                        var xhr = new window.XMLHttpRequest();
                        xhr.upload.onprogress = progressListener;

                        return xhr;
                    },
                    url: signedUrl,
                    type: 'PUT',
                    data: file,
                    processData: false,
                    contentType: false,
                    headers: {'Content-Type': file.type},
                    success: function (uploadedMedia) {
                        const data = {fileName: objectKey, type: 'LOCAL', fileSize: file.file}
                        pushMessageQueueForUpload(mediaId,data, null, callbackError);
                        var mediaProcessing = progressByProject[projectId].medias[mediaId];
                        if (mediaProcessing) {
                          mediaProcessing.status= "🏎 Starting";
                          $rootScope.$emit('uploadCallResponse', mediaId);
                        }
                    },
                    error: function (err) {
                        if (err.status != 502) {
                            console.log(file);
                            callbackError(file.name + ' upload cancelled.', mediaId);
                            delete progressByProject[projectId].medias[mediaId];
                        }
                        console.debug(err);
                    }
                });
                return uploaderPromise;
            } else {
                callbackError(errorMessageRemoteFileMaxSize, mediaId);
            }
        }

        function hasNotStartedUploadingOrDownloading(media) {
            var incompleteStates = ['empty', 'preparing'];
            if (media.fileUploadType === uploaderType.local) {
                incompleteStates.push('uploading');
            }
            return incompleteStates.indexOf(media.status) !== -1;
        }

        function hasAnyMediaNotStartedUploading() {
            var foundMediaItem;
            angular.forEach(progressByProject, function (projectItem) {
                angular.forEach(projectItem.medias, function (mediaItem) {
                    if (hasNotStartedUploadingOrDownloading(mediaItem)) {
                        foundMediaItem = mediaItem;
                    }
                });
            });
            return !!foundMediaItem;
        }

        function startListeningUploadEvents() {
            var uploadFileListener = $rootScope.$on('media.uploadFile', function (event, data) {
                uploadFile(data.preparedFile, data.media, data.projectId, data.callbackError);
            });

            $window.onbeforeunload = function (event) {
                if (hasAnyMediaNotStartedUploading()) {
                    var dialogText = 'There are some uploads in process. If you refresh the web page, your uploaded files will be incomplete. Are you sure?';
                    event.returnValue = dialogText;
                    return dialogText;
                }
            };

            // lets avoid memory leaks
            $rootScope.$on('$destroy', function () {
                uploadFileListener();
            });
        }

        function tryToLock(mediaId) {
            return $http.post(host + 'media/' + mediaId + '/lock');
        }

        function tryToUnLock(mediaId) {
            return $http.post(host + 'media/' + mediaId + '/unlock');
        }

        function tryToUnLockSync(mediaId) {
          // TODO: Add auth header
            $.ajax({
                url: host + 'media/' + mediaId + '/unlock',
                async: false,
                type: 'POST'
            });
        }

        function hasProjectUploads(projectId) {
            var medias = (progressByProject[projectId] || {}).medias;

            return Object.keys(medias || {}).some(function (mediaId) {
                return medias[mediaId].status !== 'ready';
            });
        }
    }

})();
