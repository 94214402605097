(function () {
  'use strict';

  angular
    .module('app')
    .component('adminUserDetailPage', {
      bindings: {
        userId: '<'
      },
      templateUrl: 'app/admin/pages/admin-user-detail-page/admin-user-detail-page.component.html',
      controller: AdminUserDetailController
    });

  AdminUserDetailController.$inject = [
    '$state',
    '$window',
    'adminUserRestService',
    'adminSubscriptionRestService',
    'ssNotificationService'
  ];

  function AdminUserDetailController(
    $state,
    $window,
    adminUserRestService,
    adminSubscriptionRestService,
    ssNotificationService
  ) {

    var me = this;
    var backupFormData;

    me.$onInit = onInit;
    me.changeProjectPage = changeProjectPage;
    me.changeTransactionPage = changeTransactionPage;
    me.isSubscriptionCreditValid = isSubscriptionCreditValid;
    me.saveChanges = saveChanges;
    me.cancelChanges = cancelChanges;
    me.toggleUserActivation = toggleUserActivation;
    me.goToDetailedTransactionViewLink = goToDetailedTransactionViewLink;
    me.goToProjectDetailViewLink = goToProjectDetailViewLink;
    me.deleteUser = deleteUser;
    me.synchronizingSubscription = synchronizingSubscription;

    function onInit() {
      retrieveUserInfo();
      retrieveTransactions();
      retrieveProjects();
    }

    function makeBackupData() {
      backupFormData = Object.assign({}, me.user);
    }

    function goToDetailedTransactionViewLink(projectId) {
      if (projectId) {
        var url = $state.href('app.admin.detailedTransaction', { projectId: projectId });
        $window.open(url, '_blank');
      }
    }

    function goToProjectDetailViewLink(projectId) {
      if (projectId) {
        var url = $state.href('app.admin.projectManagementItem', { projectId: projectId });
        $window.open(url, '_blank');
      }
    }

    function retrieveUserInfo() {
      adminUserRestService.getUserById(me.userId)
        .then(function (response) {
          me.user = response;
          makeBackupData();
        })
        .catch(function (reason) {
          me.user = null;
          console.error(reason);
        });

      adminSubscriptionRestService.fetchUserSubscription(me.userId)
        .then(function (response) {
          me.stripeSubscriptionDetail = response;
        });

      adminUserRestService.fetchUserStripeInfo(me.userId)
        .then(function (response) {
          me.userStripeInfo = response;
        });
    }

    function retrieveTransactions(pageNumber) {
      if (!pageNumber || pageNumber < 0) {
        pageNumber = 0;
      }
      var pageSize = 20;
      adminUserRestService.getUserTransactions(me.userId, pageNumber, pageSize)
        .then(function (response) {
          me.transactionPage = response;
        });
    }

    function retrieveProjects(pageNumber) {
      if (!pageNumber || pageNumber < 0) {
        pageNumber = 0;
      }
      var pageSize = 20;
      adminUserRestService.getUserProjects(me.userId, pageNumber, pageSize)
        .then(function (response) {
          me.projectPage = response;
        });
    }

    function changeProjectPage() {
      retrieveProjects(me.projectPage.number - 1);
    }

    function changeTransactionPage() {
      retrieveTransactions(me.transactionPage.number - 1);
    }

    function isSubscriptionCreditValid() {
      if (!me.user) {
        return false;
      }

      return !me.user.subscriptionCredit ||
        (me.user.subscriptionCreditExpireDate &&
          me.user.subscriptionCreditExpireDate > new Date());
    }

    function saveChanges(propertiesToChange) {
      var defaultPropertiesToChange = [
        'credit',
        'subscriptionCredit',
        'subscriptionCreditExpireDate'
      ];

      var updatesForUser = _.pick(me.user, propertiesToChange || defaultPropertiesToChange);

      adminUserRestService.updateUser(me.user.id, updatesForUser)
        .then(function (response) {
          me.user = response;
          makeBackupData();
          me.userDetailsForm.$setPristine();
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({ type: 'danger', msg: "There was a problem updating user details." });
        });
    }

    function cancelChanges() {
      me.userDetailsForm.$setPristine();
      me.user = Object.assign({}, backupFormData);
    }

    function deleteUser() {
      var confirm = $window.confirm('Are you sure you want to delete this user?');
      if (!confirm) {
        return;
      }
      adminUserRestService.deleteUser(me.user.id)
        .then(function () {
          ssNotificationService.add({
            type: 'success',
            msg: 'User: ' + me.user.email + ' have been deleted successfully.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });
          $state.go('app.admin.userManagementList');
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({ type: 'danger', msg: "There was a problem deleting this user." });
        });
    }

    function synchronizingSubscription() {
      adminUserRestService.synchronizingSubscription(me.userId)
        .then(function () {
          retrieveUserInfo();

          ssNotificationService.add({
            type: 'success',
            msg: 'User: ' + me.user.email + ' Syncronization was performed succesfully.'
          });

        })
        .catch(function (reason) {
          console.error(reason);
          var errorMessage = 'There was a problem Syncronizing with Stripe subscription.';
          errorMessage += ' ' + reason;
          ssNotificationService.add({ type: 'danger', msg: errorMessage });
        });

    }

    function toggleUserActivation() {
      var confirmMessageToActivate = 'Are you sure to Activate this account?';
      var confirmMessageToDeactivate = 'Are you sure to put this account on HOLD?';
      var confirmMessage = me.user.active ? confirmMessageToActivate : confirmMessageToDeactivate;

      if ($window.confirm(confirmMessage)) {
        saveChanges(['active']);
      } else {
        cancelChanges();
      }

    }

  }

})();
