(function () {
    'use strict';

    angular
        .module('app')
        .factory('translationService', translationService);

    translationService.$inject = ['$http', 'host'];

    /* @ngInject */
    function translationService($http, host) {

        return {
            getProjectPrice: getProjectPrice,
            translateProject: translateProject
        };

        function translateProject(projectId, payload) {
            return $http.post(host + 'project/' + projectId + '/translation', payload)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (reason) {
                    throw reason.data.message;
                });
        }

        function getProjectPrice(projectId, languageCount) {
            var url = host + 'project/' + projectId + '/translation/price';

            if (languageCount) {
              url += "?languageCount=" + languageCount
            }

            return $http.get(url)
                .then(function (response) {
                    return response.data;
                });
        }
    }

})();

