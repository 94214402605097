(function () {
    'use strict';
    angular
        .module('app')
        .controller('PublishedProjectsController', PublishedProjectsController);

    PublishedProjectsController.$inject = ['$location', '$window', 'PUBLIC_SITE_URL', 'sharedProjectsService', 'publishedProjectsRestService', 'publishedProjects'];

    function PublishedProjectsController($location, $window, PUBLIC_SITE_URL, sharedProjectsService, publishedProjectsRestService, publishedProjects) {
        var me = this;

        me.saveAnnotationAdminComment = saveAnnotationAdminComment;
        me.removeAnnotation = removeAnnotation;
        me.loadMoreAnnotations = loadMoreAnnotations;
        me.loadMoreProjects = loadMoreProjects;
        me.getPublicProjectLink = getPublicProjectLink;
        me.getPublicProjectLinkFromProject = getPublicProjectLinkFromProject;

        init();

        function init() {
            me.annotationListPageSize = 15;
            me.publishedProjectListPageSize = 15;
            me.publishedProjects = publishedProjects;
            getPublishedAnnotations();
        }

        function getPublishedAnnotations() {
            publishedProjectsRestService.getAnnotations()
                .then(function (response) {
                    me.annotations = response;

                    me.annotations.sort(function (a, b) {
                        var dateA = new Date(a.lastUpdateIsoUtcDate);
                        var dateB = new Date(b.lastUpdateIsoUtcDate);
                        return dateB - dateA;
                    });
                });
        }

        function saveAnnotationAdminComment(annotation) {
            publishedProjectsRestService.updateAnnotation(annotation);
        }

        function removeAnnotation(annotation) {
            var confirm = $window.confirm('Are you sure you want to delete this comment?');

            if (confirm) {
                publishedProjectsRestService.removeAnnotation(annotation)
                    .then(function() {
                        _.remove(me.annotations, function(item) {
                            return item.transcriptionId === annotation.transcriptionId;
                        });
                    });
            }
        }

        function loadMoreAnnotations() {
            me.annotationListPageSize += me.annotationListPageSize;
        }

        function loadMoreProjects() {
            me.publishedProjectListPageSize += me.publishedProjectListPageSize;
        }

        function getPublicProjectLinkFromProject(project) {
          return sharedProjectsService.getProjectPublicLink(project);
        }

        function getPublicProjectLink(annotation) {
          var project = {
              id: annotation.projectId,
              name: annotation.projectName,
              owner: {
                id: annotation.projectOwnerId
              }
          };
          return sharedProjectsService.getProjectPublicLink(project);
        }
    }

})();
