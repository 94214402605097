(function () {
    'use strict';
    angular
            .module('app')
            .controller('blacklistWhitelistController', BlacklistWhitelistController);
    BlacklistWhitelistController.$inject = [
        '$scope',
        'blacklistWhitelistService',
        'adminDefaultConfigRestService',
        'ssNotificationService'
    ];
    function BlacklistWhitelistController(
            $scope,
            blacklistWhitelistService,
            adminDefaultConfigRestService,
            ssNotificationService
            ) {
        init();
        function init() {

            blacklistWhitelistService.getAll()
                    .then(function successCallback(response) {

                        $scope.whiteList = response.data[0].whiteListIds;
                        $scope.blackList = response.data[0].blackListIds;
                        $scope.blackListIps = response.data[0].blackListIps;
                        $scope.blackListWhiteListConfigId = response.data[0].id;
                        $scope.event = {
                            type: {
                                checked: response.data[0].blackListAll
                            }
                        };
                        $scope.whitelist = {
                            type: {
                                checked: response.data[0].whiteListAll
                            }
                        };
                        if ($scope.event.type.checked)
                            $scope.addBlackListBtn = true;
                        else
                            $scope.addBlackListBtn = false;
                        if ($scope.whitelist.type.checked)
                            $scope.addWhiteListBtn = true;
                        else
                            $scope.addWhiteListBtn = false;
                    });
//                $scope.getDefaultConfig();
        }


        $scope.defaultValueId = '';
        $scope.creditInSeconds = '';
        $scope.maxCallLengthSeconds = '';
        $scope.blackList = '';
        $scope.blackList = '';
        $scope.blackListWhiteListConfigId = '';
        $scope.addBlackList = function () {
            var inputPayloadToAddBlackList = {
                "blackListIds": $scope.blacklistTxt,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.save(JSON.stringify(inputPayloadToAddBlackList))
                    .then(function successCallback(response) {
                        $scope.blacklistTxt = '';
                        init();
                    }, function errorCallback(response) {

                    });
        };
        $scope.addBlackListIps = function () {
            var inputPayloadToAddBlackList = {
                "blackListIps": $scope.blacklistIpsTxt,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.save(JSON.stringify(inputPayloadToAddBlackList))
                    .then(function successCallback(response) {
                        $scope.blacklistIpsTxt = '';
                        init();
                    }, function errorCallback(response) {

                    });
        };
        $scope.addWhiteList = function () {
            var inputPayloadToAddWhiteList = {
                "whiteListIds": $scope.whitelistTxt,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.save(inputPayloadToAddWhiteList)
                    .then(function successCallback(response) {
                        $scope.whitelistTxt = '';
                        init();
                    });
        };
        $scope.blacklistSwitch = function () {
            var blacklistSwitchVal = '';
            var whitelistSwitchVal = '';
            if ($scope.event.type.checked) {
                $scope.addBlackListBtn = true;
                blacklistSwitchVal = 1;
                whitelistSwitchVal = 0;
                if ($scope.whitelist.type.checked) {
                    blacklistSwitchVal = 1;
                    whitelistSwitchVal = 0;
                }
            } else {
                $scope.addBlackListBtn = false;
                blacklistSwitchVal = 0;
                whitelistSwitchVal = 1;
                if (!$scope.whitelist.type.checked) {
                    blacklistSwitchVal = 0;
                    whitelistSwitchVal = 0;
                }
            }
            var inputPayloadForBlacklistSwitch = {
                "blackListAll": blacklistSwitchVal,
                "whiteListAll": whitelistSwitchVal,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.save(inputPayloadForBlacklistSwitch)
                    .then(function successCallback(response) {
                        $scope.whitelistTxt = '';
                        init();
                    });
        };
        adminDefaultConfigRestService.getDefaultConfig()
                .then(function (response) {
                    if (response) {
                        $scope.defaultConfig = response;
                    }
                });
        $scope.updateConfig = function () {

            adminDefaultConfigRestService.updateDefaultConfig($scope.defaultConfig).then(function successCallback(response) {
                ssNotificationService.add({
                    type: 'success',
                    msg: 'Detail have been updated successfully.'
                });
            }, function errorCallback(response) {
                console.log(response);
                ssNotificationService.add({
                    type: 'danger',
                    msg: 'There was an error updating value.'
                });
            });
        }
        $scope.whitelistSwitch = function () {
            var blacklistSwitchVal = '';
            var whitelistSwitchVal = '';
            if ($scope.whitelist.type.checked) {
                $scope.addWhiteListBtn = true;
                whitelistSwitchVal = 1;
                blacklistSwitchVal = 0;
                if ($scope.event.type.checked) {
                    whitelistSwitchVal = 1;
                    blacklistSwitchVal = 0;
                }
            } else {
                $scope.addWhiteListBtn = false;
                blacklistSwitchVal = 1;
                whitelistSwitchVal = 0;
                if (!$scope.event.type.checked) {
                    blacklistSwitchVal = 0;
                    whitelistSwitchVal = 0;
                }
            }
            var inputPayloadForWhitelistSwitch = {
                "blackListAll": blacklistSwitchVal,
                "whiteListAll": whitelistSwitchVal,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.save(inputPayloadForWhitelistSwitch)
                    .then(function successCallback(response) {
                        $scope.whitelistTxt = '';
                        init();
                    });
        };
        $scope.removeFromBlackList = function (blackListAll) {
            var inputPayloadToRemoveFromBlackList = {
                "blackListIds": blackListAll,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.deleteReq(inputPayloadToRemoveFromBlackList)
                    .then(function successCallback(response) {
                        $scope.whitelistTxt = '';
                        init();
                    });
        };
        $scope.removeFromWhiteList = function (whiteListAll) {
            var inputPayloadToRemoveFromWhiteList = {
                "whiteListIds": whiteListAll,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.deleteReq(inputPayloadToRemoveFromWhiteList)
                    .then(function successCallback(response) {
                        $scope.whitelistTxt = '';
                        init();
                    });
        };
        $scope.removeFromBlackListIps = function (blackListAll) {
            var inputPayloadToRemoveFromWhiteList = {
                "blackListIps": blackListAll,
                "id": $scope.blackListWhiteListConfigId
            };
            blacklistWhitelistService.deleteReq(inputPayloadToRemoveFromWhiteList)
                    .then(function successCallback(response) {
                        $scope.blacklistIpsTxt = '';
                        init();
                    });
        };
    }
}());
