(function () {
    'use strict';

    angular
        .module('app')
        .factory('sharedProjectsService', sharedProjectsService);

    sharedProjectsService.$inject = ['PUBLIC_SITE_URL', 'stringHelperService'];

    /* @ngInject */
    function sharedProjectsService(PUBLIC_SITE_URL, stringHelperService) {

        return {
            getUserNamePartLink: getUserNamePartLink,
            getProjectNamePartLink: getProjectNamePartLink,
            getProjectPublicLink: getProjectPublicLink
        };

        ////////////////

        function getProjectPublicLink(project) {
          var projectPart = getProjectNamePartLink(project);
          return PUBLIC_SITE_URL + '/' + project.owner.id + '/' + projectPart + '/' + project.id;
        }

        function getUserNamePartLink(user) {
            var usernamePart = user.fullName;
            return removeUnsafeChars(usernamePart);
        }

        function getProjectNamePartLink(project) {
            return removeUnsafeChars(project.name);
        }

        function removeUnsafeChars(stringToReplace) {
            var result = stringHelperService.removeAllSpecialChars(stringToReplace, true);
            result = stringHelperService.removeExtraWhiteSpaces(result);
            result = result.trim();
            result = result.toLowerCase();
            result = stringHelperService.replaceAll(result, ' ', '-');
            return result;
        }
    }

})();

