(function () {
    'use strict';

    angular
        .module('app')
        .component('projectTranscriptsPage', {
            controller: ProjectTranscriptsPageController,
            bindings: {
                project: '<',
                user: '<'
            },
            templateUrl: 'app/pages/project-transcripts-page/project-transcripts-page.component.html'
        });

    ProjectTranscriptsPageController.$inject = [
        '$location',
        '$state',
        '$rootScope',
        '$uibModal',
        '$window',
        'SPEECH_API_SPEED',
        'WebSocketConnectionService',
        'TranscriptionService',
        'CustomAnalyticsService',
        'MediaService',
        'sharedProjectsRestService',
        'speakerRestService',
        'languagesService',
        'ErrorService',
        'ssNotificationService',
        'projectService'
    ];

    function ProjectTranscriptsPageController(
        $location,
        $state,
        $rootScope,
        $uibModal,
        $window,
        SPEECH_API_SPEED,
        WebSocketConnectionService,
        TranscriptionService,
        CustomAnalyticsService,
        MediaService,
        sharedProjectsRestService,
        speakerRestService,
        languagesService,
        ErrorService,
        ssNotificationService,
        projectService
    ) {
        var me = this;

        var channelProjectMediaUpdate = 'mediaUpdate/project';
        var channelMediaTranscodingSpeed = 'mediaTranscodingSpeed';
        var channelProjectStatusUpdate = 'projectStatusUpdate';

        me.$onInit = onInit;
        me.$onDestroy = onDestroy;
        // me.$onChanges = onChanges;
        // me.$doCheck = doCheck;

        me.onProjectChange = onProjectChange;
        me.onProjectFpsChange = onProjectFpsChange;

        me.onProjectTranscribed = onProjectTranscribed;
        me.onTranscriptionChange = onTranscriptionChange;
        me.getProjectLanguageDirection = getProjectLanguageDirection;

        me.onClickHome = onClickHome;

        me.onTranscriptionAdd = onTranscriptionAdd;
        me.onTranscriptionDelete = onTranscriptionDelete;
        me.onProjectUpdate = onProjectUpdate;
        me.onProjectFpsUpdate = onProjectFpsUpdate;
        me.onMediaChange = onMediaChange;
        me.onMediaSelect = onMediaSelect;
        me.onClickShareButton = onClickShareButton;
        me.onClickExportButton = onClickExportButton;
        me.onSpeakerAdd = onSpeakerAdd;
        me.onSpeakerDelete = onSpeakerDelete;
        me.onSpeakerUpdate = onSpeakerUpdate;
        me.isAdmin = isAdmin;
        me.isProjectOwner = isProjectOwner;
        me.isTeamMember = isTeamMember;
        me.isCurrentMediaLockByOtherUser = isCurrentMediaLockByOtherUser;

        me.waveSoundOptions = {
            waveColor: '#BAA6CB',
            progressColor: '#7e56a2'
        };

        function onInit() {
            $rootScope.isStickyActive = false;

            languagesService.getAll()
                .then(function (languages) {
                    me.languages = languages;
                });

            checkIfProjectIsSharedWithTeam();
            checkIfProjectIsPublic();
            isReadOnly();

            var currentMedia = null;
            var searchObject = $location.search();
            if (searchObject.fileId) {
              currentMedia = getMediaFromId(me.project.medias, searchObject.fileId);
            }

            if (!currentMedia) {
              currentMedia = getShortestMedia(me.project.medias);
            }

            if (currentMedia) {
                onMediaSelect(currentMedia);
            }

            setInitialRemainingTime();

            subscribeToEvents();
        }

        function checkIfProjectIsSharedWithTeam() {
          sharedProjectsRestService.getAll()
            .then(function (projectsIShared) {
                me.isProjectShared = _.some(projectsIShared, {id: me.project.id});
            });
        }

        function checkIfProjectIsPublic() {
          sharedProjectsRestService.isPublic(me.project.id)
            .then(function (response) {
                me.isProjectPublished = response && response.isPublic === true;
            });
        }

        function isReadOnly() {
            me.isReadOnly = true;
            if(isProjectOwner() || isAdmin()) {
                me.isReadOnly = false;
                return;
            }
            sharedProjectsRestService.getSharedInfo(me.project.id)
            .then(function(shareInfo){
                console.log('isReadOnly')
                me.isReadOnly = _.some(shareInfo, {type: 'VIEW'});
                console.log(me.isReadOnly)
            });
        }

        function onDestroy() {
          $rootScope.isStickyActive = false;
          unsubscribeFromEvents();
        }

        function onClickHome() {
          $state.go('app.dashboard');
        }

        function subscribeToEvents() {
            WebSocketConnectionService.subscribe(channelProjectMediaUpdate, me.project.id, function (updateForMedia) {

                var media = me.project.medias.find(function (mediaItem) {
                    return updateForMedia.id === mediaItem.id;
                });

                if (me.currentMedia.id === updateForMedia.id) {

                  if (me.currentMedia.status !== updateForMedia.status) {
                    console.log('showMediaTranscriptions');
                    showMediaTranscriptions(updateForMedia);
                  }

                    var oldIsLockState = isCurrentMediaLockByOtherUser();

                    angular.merge(me.currentMedia, updateForMedia);

                    var newIsLockState = isCurrentMediaLockByOtherUser();

                    if (!newIsLockState && oldIsLockState) {
                        var lockAndStartEditing = $window.confirm('This file has been unlocked for editing. Do you want to start editing it?');

                        if (lockAndStartEditing) {
                            MediaService.tryToLock(media.id);
                            showMediaTranscriptions(media);
                        } else {
                            me.currentMedia.lockByUserId = 'ContinueReadOnlyMode';
                        }
                    }
                }

                if (media) {
                    angular.merge(media, updateForMedia);
                }
            });

            WebSocketConnectionService.subscribe(channelProjectStatusUpdate, me.project.id, function (data) {

              console.log('channelProjectStatusUpdate', data);

              if (data && data.status) {
                  if(data.status === 'failed') {
                    $state.reload();
                  }

                  if (me.project.status !== data.status && (data.status === 'translated' || data.status === 'subtitled' || data.status === 'ready')) {
                    if (data.status === 'translated') {
                      ssNotificationService.add({
                        type: 'success',
                        msg: 'Project has been translated.'
                      });
                    }
                    else if (data.status === 'subtitled') {
                      ssNotificationService.add({
                        type: 'success',
                        msg: 'Project has been subtitled.'
                      });
                    } else {
                      onProjectTranscribed();
                    }
                  }

                  me.project.status = data.status;
                  projectService.redirectToSubState(me.project);
              }
            });


            var longestMedia = getLongestDurationMedia();
            if (longestMedia) {
                WebSocketConnectionService.subscribe(channelMediaTranscodingSpeed, longestMedia.id, function (data) {
                    me.endTimeForTranscribing = getEndTimeForTranscribing(data.speed, data.leftToTranscribeInSeconds);
                });
            }

            if (me.user) {
                WebSocketConnectionService.subscribe('unsharedProjectWith/user', me.user.id, function (unsharedProjectId) {
                    if (me.project.id === unsharedProjectId) {
                        $window.alert('This project has been deleted or unshared with you.');
                        $state.go('app.dashboard');
                    }
                });
            }

            $window.onbeforeunload = function () {
                if (isCurrentMediaLockByCurrentUser()) {
                  console.log('calling sync request');
                  MediaService.tryToUnLockSync(me.currentMedia.id);
                }
            };

            ErrorService.subscribeMediaErrors(me.project.id, function (error) {
                onError({$error: error});
            });

        }

        function unsubscribeFromEvents() {
            WebSocketConnectionService.unsubscribe(channelProjectMediaUpdate, me.project.id);

            if (me.user && me.user.id) {
                WebSocketConnectionService.unsubscribe('unsharedProjectWith/user', me.user.id);
            }

            WebSocketConnectionService.unsubscribe(channelMediaTranscodingSpeed, getLongestDurationMedia().id);

            if (me.project && me.project.id) {
              WebSocketConnectionService.unsubscribe(channelProjectStatusUpdate, me.project.id);
            }

            if (me.project && me.project.medias) {
                me.project.medias.forEach(function (media) {
                    MediaService.tryToUnLock(media.id);
                });
            }

            ErrorService.unsubscribeFromMediaErrors(me.project.id);
        }

        function onSpeakerAdd(newSpeaker, mediaId, transcription) {
            speakerRestService.add(newSpeaker, mediaId)
                .then(function (createdSpeaker) {
                    $rootScope.$broadcast('projectTranscripts.createdSpeaker', {
                        mediaId: mediaId,
                        createdSpeaker: createdSpeaker,
                        transcription: transcription
                    });
                });
        }

        function onSpeakerDelete(speakerToDelete, mediaId) {
            speakerRestService.remove(speakerToDelete, mediaId)
                .then(function () {
                    $rootScope.$broadcast('projectTranscripts.deletedSpeaker', {
                        mediaId: mediaId,
                        speakerToDeleteId: speakerToDelete.id
                    });

                    _.remove(me.currentMedia.speakers, function (speakerItem) {
                        return speakerItem.id === speakerToDelete.id;
                    });
                });
        }

        function onSpeakerUpdate(speakerToUpdate, mediaId) {
            speakerRestService.update(speakerToUpdate, mediaId)
                .then(function (updatedSpeaker) {
                    $rootScope.$broadcast('projectTranscripts.updatedSpeaker', {
                        mediaId: mediaId,
                        updatedSpeaker: updatedSpeaker
                    });
                });
        }

        function isProjectOwner() {
            if (me.user && me.project) {
                return me.project.owner.id === me.user.id;
            }
        }

        function isAdmin() {
          if (!me.user) {
            return false;
          }
          var userRole =  me.user.role.name;
          if (['ROLE_SUPERADMIN', 'ROLE_ADMIN'].indexOf(userRole) !== -1) {
              return true;
          }
          return false;
        }

        function isTeamMember() {
            if (me.user && me.user.team && me.project.owner && me.project.owner.team) {
                return me.user.team.id === me.project.owner.team.id;
            }
        }

        function isCurrentMediaLockByCurrentUser() {
            if (me.user && me.currentMedia && me.currentMedia.lockByUserId) {
                return me.currentMedia.lockByUserId === me.user.id;
            }
            return false;
        }

        function getShortestMedia(medias) {
            var shortestMediaDuration = Number.MAX_VALUE;
            var shortestMedia = null;
            if (medias && medias.length) {
                if (['translated', 'translating', 'subtitled', 'subtitling'].indexOf(me.project.status) !== -1 && medias[1]) {
                  return medias[1];
                }

                medias
                    .filter(function (media) {
                        return media.status && media.status !== 'empty' && media.duration > 0;
                    })
                    .forEach(function (media) {
                        if (media.duration < shortestMediaDuration) {
                            shortestMediaDuration = media.duration;
                            shortestMedia = media;
                        }
                    });
            }

            return shortestMedia;
        }

        function getEndTimeForTranscribing(transcriptionSpeed, leftToTranscribeInSeconds) {
            var transcriptionProcessDuration = transcriptionSpeed * leftToTranscribeInSeconds;

            var now = new Date();

            var endTranscriptionDate = $window.moment(now).add(transcriptionProcessDuration, 'seconds').toDate();

            return endTranscriptionDate.getTime();
        }

        function getLongestDurationMedia() {
            return _.maxBy(me.project.medias, 'duration');
        }

        function isCurrentMediaLockByOtherUser() {
            if (me.user && me.currentMedia && me.currentMedia.lockByUserId) {
              return me.currentMedia.lockByUserId !== me.user.id;
            }
            return false;
        }

        function getProjectLanguageDirection(project) {
            if (angular.isArray(me.languages) && project.languageRegionCode) {
                var languageCode = project.languageRegionCode.split(/-(.+)/)[0];
                var language = me.languages.find(function (languageItem) {
                    return languageItem.code === languageCode;
                });

                if (language) {
                    return language.direction;
                }
            }
            return '';
        }

        function onProjectFpsChange(projectId, fps) {
          projectService.updateToAllMedias(projectId, {fps: fps})
                    .catch(function () {
                        ssNotificationService.add({
                            type: "danger",
                            msg: "Error updating Frame Rate to all Project Files. Refresh the page and try again."
                        });
                    });
        }

        function onProjectChange(updateForProject) {
          angular.merge(me.project, updateForProject);

          projectService.save(me.project)
              .catch(function (reason) {
                console.error(reason);

                ssNotificationService.add({
                    type: 'danger',
                    msg: reason && reason.data && reason.data.message ? reason.data.message : 'There was an error saving the project.'
                });

              });
        }

        function onProjectUpdate(updatedProject) {
            me.onProjectChange({updatedProject: updatedProject});
        }

        function onProjectFpsUpdate(projectId, fps) {
            me.onProjectFpsChange(projectId, fps);
        }

        function onProjectTranscribed() {
          ssNotificationService.add({
            type: 'success',
            msg: 'TaDa! Here you go mate, ' + me.project.name + ' transcribed.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });

          // This is a hack because of production soemtimes lost real time connection
          if (me.currentMedia.status !== 'transcribed' && me.currentMedia.status !== 'translated' && me.currentMedia.status !== 'subtitled') {
            $state.reload();
          }
        }

        function onClickExportButton() {
            $uibModal.open({
                animation: true,
                component: 'exportTranscriptionModal',
                resolve: {
                    mediaCount: function () {
                        return angular.isArray(me.project.medias) ? me.project.medias.length : 0;
                    },
                    currentMediaId: function () {
                        return me.currentMedia.id;
                    },
                    project: function () {
                        return me.project;
                    }
                }
            });
        }

        function onClickShareButton() {
            if (!isProjectOwner()) {
                return;
            }

            var modalInstance = $uibModal.open({
                animation: true,
                backdrop: 'static',
                component: 'shareProjectModal',
                resolve: {
                    project: function () {
                        return me.project;
                    },
                    isProjectOwner: isProjectOwner,
                    isTeamMember: isTeamMember
                }
            });

            modalInstance.result
                .then(function (response) {
                    var sharePayload = {
                        id: me.project.id,
                        target: response.shareTarget || 'noTeam',
                        members: response.members,
                        editAccess: response.editAccessTarget
                    };

                    var publishPayload = {
                        id: me.project.id,
                        target: response.publishTarget || 'noPublic'
                    };

                    sharedProjectsRestService.add(sharePayload)
                        .then(function () {
                            checkIfProjectIsSharedWithTeam();
                        })
                        .catch(function (reason) {
                            var error = {
                                message: 'There was an error sharing this project.',
                                reason: reason
                            };
                            onError({$error: error});
                        });

                    sharedProjectsRestService.add(publishPayload)
                        .then(function () {
                            checkIfProjectIsPublic();
                        })
                        .catch(function (reason) {
                            var error = {
                                message: 'There was an error sharing this project.',
                                reason: reason
                            };
                            onError({$error: error});
                        });

                    // In order to save the public description
                    me.onProjectChange({updatedProject: me.project});
                });
        }

        function onMediaChange(updatedMedia) {
            MediaService.saveMediaData(updatedMedia)
                .then(function () {
                    CustomAnalyticsService.trackEvent('Media', 'Save TimeCode success', null, null);
                })
                .catch(function (reason) {
                    CustomAnalyticsService.trackEvent('Media', 'Save TimeCode error', null, reason);

                    var error = {
                        message: 'Error! Your timecode couldn\'t be saved. Refresh the page and try again.',
                        reason: reason
                    };

                    onError({$error: error});
                });
        }

        function onMediaSelect(selectedMedia) {
          var previousMediaId = me.currentMedia ? me.currentMedia.id : null;

          me.currentMedia = selectedMedia;

          me.project.medias.forEach(function (mediaItem) {
              if (isTeamMember() && mediaItem.id === previousMediaId) {
                  MediaService.tryToUnLock(mediaItem.id);
              }
          });

          if (isTeamMember()) {
              MediaService.tryToLock(me.currentMedia.id);
          }

          showMediaTranscriptions(me.currentMedia);
        }

        function showMediaTranscriptions(media) {
          if (media.status === 'transcribed' || media.status === 'translated' || media.status === 'subtitled') {
            TranscriptionService.getByMedia(media.id)
                .then(function (response) {
                    me.transcriptions = response.data;
                })
                .catch(function (reason) {
                    console.error(reason);
                });
        } else {
            me.transcriptions = [];

            me.project.medias.forEach(function (mediaItem) {
                WebSocketConnectionService.unsubscribe('transcriptions', mediaItem.id);
            });

            WebSocketConnectionService.subscribe('transcriptions', media.id, function (response) {
                var transcription = angular.fromJson(response.content);
                $rootScope.$broadcast('projectTranscripts.addedTranscription', {mediaId: media.id, transcription: transcription});
            });
        }
        }

        function onTranscriptionChange(updatedTranscription, mediaId) {
            saveTranscription(updatedTranscription, mediaId);
        }

        function onTranscriptionAdd(newTranscription, mediaId, indexToInsertNewTranscription) {
            TranscriptionService.save(newTranscription, mediaId)
                .then(function (result) {
                    $rootScope.$broadcast('projectTranscripts.addedTranscription', {
                        mediaId: me.currentMedia.id,
                        transcription: result.data,
                        autoFocus: true,
                        indexToInsertNewTranscription: indexToInsertNewTranscription
                    });
                });
        }

        function onTranscriptionDelete(transcriptionToDeleteId, mediaId) {
            TranscriptionService.delete(mediaId, transcriptionToDeleteId);
        }

        function saveTranscription(transcription, mediaId) {
            if (transcription) {
                CustomAnalyticsService.trackEvent('Transcriptions', 'Save Transcription', null, null);

                TranscriptionService.save(transcription, mediaId)
                    .then(function success() {
                        CustomAnalyticsService.trackEvent('Transcriptions', 'Save Transcription success', null, null);
                    })
                    .catch(function (reason) {
                        CustomAnalyticsService.trackEvent('Transcriptions', 'Save Transcription error', null, reason);

                        var error = {
                            message: 'Error! Your transcription couldn\'t be saved. Refresh the page and try again.',
                            reason: reason
                        };

                        onError(error);
                    });
            }
        }

        function onError(error) {
            ssNotificationService.add({type: 'danger', msg: error.message});
            if (error.reason) {
                console.error(error.reason);
            }
        }

        function setInitialRemainingTime() {
            var projectTranscriptionSpeed = 1;
            if (me.project && me.project.defaultSpeechApi) {
                var speechApiSpeed = SPEECH_API_SPEED[me.project.defaultSpeechApi];
                projectTranscriptionSpeed = speechApiSpeed ? speechApiSpeed : 1;
            }
            me.endTimeForTranscribing = getEndTimeForTranscribing(projectTranscriptionSpeed, getLeftTimeToTranscribe());
        }

        function getLeftTimeToTranscribe() {
            if (getLastTranscriptionEndTime()) {
                return getLongestDurationMedia().duration - getLastTranscriptionEndTime();
            }

            var startDate = new Date();
            if (me.currentMedia && me.currentMedia.startedTranscriptionTimeInUtc) {
              startDate = new Date(me.currentMedia.startedTranscriptionTimeInUtc);
            }

            var expectedEndDate = $window.moment(startDate).add(getLongestDurationMedia().duration, 'seconds').toDate();
            var now = new Date();

            return (expectedEndDate.getTime() - now.getTime()) / 1000;
        }

        function getLastTranscriptionEndTime() {
            if (me.transcriptions && me.transcriptions.length) {
                return parseFloat(me.transcriptions[me.transcriptions.length - 1].endTime);
            }
            return 0;
        }

    }
})();
