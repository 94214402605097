(function () {
    'use strict';

    angular
        .module('app')
        .component('projectDetailsPage', {
          controller: ProjectDetailsController,
          bindings: {
            project: '<'
          },
          templateUrl: 'app/pages/project-details-page/project-details-page.component.html'
        });

    ProjectDetailsController.$inject = [];

    function ProjectDetailsController() {
        var me = this;

        me.$onInit = onInit;

        function onInit() {
          console.log('projectDetailsPage:', 'Never will be logged');
        }

    }

}());
