(function () {
  'use strict';
  angular
    .module('app')
    .component('publicPage', {
      controller: PublicController,
      bindings: {},
      templateUrl: 'app/pages/public-page/public-page.component.html'
    });

  PublicController.$inject = [
    'APP_CONFIG',
    'authService',
    'whitelabel'
  ];

  function PublicController(
    APP_CONFIG,
    authService,
    whitelabel
  ) {

    var me = this;

    me.$onInit = onInit;
    me.login = login;
    me.whitelabel = whitelabel;

    function onInit() {
      me.logoLinkHref = APP_CONFIG.ROUTE_PREFIX;
    }

    function login() {
      authService.login();
    }

  }

})();
