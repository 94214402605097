(function () {
    'use strict';

    angular
        .module('app')
        .factory('detailedTransactionService', detailedTransactionService);

    detailedTransactionService.$inject = ['$http', 'host'];

    /* @ngInject */
    function detailedTransactionService($http, host) {
        var basePathUrl = host + 'admin/projects';

        var service = {
            getProject: getProject,
            update: update
        };
        return service;

        ////////////////

        function getProject(id) {
            return $http.get(basePathUrl + '/' + id)
                .then(function (response) {
                    return response.data;

                });
        }
        function update(payload, id) {
            return $http.put(basePathUrl + '/' + id, payload);
        }

    }

})();

