(function () {
  'use strict';

  angular
    .module('app')
    .component('folderStructure', {
      controller: FolderStructureController,
      bindings: {
        data: '=',
        directories: '=',
        projects: "="
      },
      templateUrl: 'app/components/folder-structure/folder-structure.component.html'
    });

  FolderStructureController.$inject = [
    '$scope',
    '$rootScope',
    'projectService',
    'ssNotificationService',
    '$window',
    'usersRestService'
  ];

  function FolderStructureController($scope, 
    $rootScope, 
    projectService, 
    ssNotificationService, 
    $window,
    usersRestService) {
    var me = this;

    me.$onInit = onInit;
    me.toggleFolder = toggleFolder;
    me.zoomImage = zoomImage;
    me.zoomoutImage = zoomoutImage;
    // me.selectedFolderName = null;
    // me.selectedFolderId = null;
    me.selectProjects = selectProjects;
    me.selectedProjects = [];
    me.moveType = null;
    me.showConfirmDelete = showConfirmDelete;
    me.showConfirmDeleteFolder = showConfirmDeleteFolder;


    //for tag purpose
    me.prepareProjectToTag = prepareProjectToTag;
    me.getProjectTag = getProjectTag;
    me.createLabel = createLabel;
    me.updateLabel = updateLabel;
    me.toogleTagToProject = toogleTagToProject;
    me.deleteUserTag = deleteUserTag;
    me.labelFilter = labelFilter;

    me.popoverTags = {};
    me.newUserTag = {};

    me.tagFilters = [];

    //for temp
    me.selectedProjectId = null;

    // $rootScope.$on("retrieveUserProjectTags", function () {
    //   retrieveUserProjectTags();
    // });

    $rootScope.$on("labelFilter", function (event, data) {
      me.tagFilters = data;
    });

    function onInit() {
      // console.log(me.projects);
      // console.log(me.directories)
      // console.log(me.popoverTag);
      retrieveUserTags();
      retrieveUserProjectTags();
    };

    function toggleFolder(index, folder) {
      if (me.directories[index].isOpen || me.directories[index].isOpen === true) {
        me.directories[index].isOpen = false;

        // me.selectedFolderName = null;
        // me.selectedFolderId = null;
      }
      else {
        // for (let i = 0; i < me.directories.length; i++) {
        //   me.directories[i].isOpen = false
        // }

        me.directories[index].isOpen = true;

        // me.selectedFolderName = folder.name;
        // me.selectedFolderId = folder.id;

      }
      // document.cookie = "selectedFolderName=" + me.selectedFolderName;
      // document.cookie = "selectedFolderId=" + me.selectedFolderId;
    }

    function zoomImage(project) {
      project.isImagezoom = true;

      if (project.img === null || project.img === undefined) {
        // project.zoomImageSrc = project.medias[0].frameFilePath;
        project.zoomImageSrc = project.staticImgPath;
      }
      else {
        // project.zoomImageSrc = project.img;
        project.zoomImageSrc = project.staticImgPath;
      }
    }

    function zoomoutImage(project) {
      project.isImagezoom = false;
      project.zoomImageSrc = null;
    }

    function selectProjects(project, folder) {
      if (project.selected === true) {
        project.selected = false;
        var id = project.id;

        for (var i = 0; i < me.selectedProjects.length; i++) {
          if (me.selectedProjects[i].id == id) {

            me.selectedProjects.splice(i, 1);
            break;
          }
        }

      }
      else {
        if (me.selectedProjects.length > 0) {
          let currentProjectDirectory = me.selectedProjects[0].directory.name;
          // debugger;
          if (currentProjectDirectory === folder.name) {
            project.selected = true;
            me.selectedProjects.push(project)
          }
          else {

            for (var i = 0; i < me.selectedProjects.length; i++) {
              me.selectedProjects[i].selected == false
            }
            me.selectedProjects = [];
            project.selected = true;
            me.selectedProjects.push(project)
          }
        }
        else {
          project.selected = true;
          me.selectedProjects.push(project)
        }

      }
      // console.log(me.selectedProjects)
    }

    $scope.getSelectedItemsIncluding = function (list, item) {
      // console.log('getSelectedItemsIncluding')
      // console.log(list)
      // console.log(item)
      // item.selected = true;

      if (list === undefined) {
        me.moveType = 'MainToFolder';
      }
      else {
        me.moveType = null;
      }
      me.selectedProjectId = item.projectId;
      return true;
    };

    $scope.onDragstart = function (list, event) {
      // console.log('onDragstart')
      // console.log(list)
      // console.log(event)
    };

    $scope.onDrop = function (list, items, index) {
      // console.log('onDrop')
      let mType = null;
      let projectId = null;
      let directoryId = null
      if (list != null) {
        //folder to folder
        mType = 'FolderToFolder';
        list.empty = false;
        projectId = me.selectedProjectId;
        directoryId = list.id;
        let result = me.projects.filter(function (item) { return item.projectId === me.selectedProjectId; });
        result[0].directory = list;
      }
      else {
        //folder to outside
        mType = 'FolderToMain';
        projectId = me.selectedProjectId;
        directoryId = null;
        let result = me.projects.filter(function (item) { return item.projectId === me.selectedProjectId; });
        result[0].directory = null;
      }

      if (me.moveType === null) {
        me.moveType = mType;
      }

      let payload = {
        projectsId: [projectId],
        folderId: directoryId,
        isFrom: me.moveType
      }

      projectService.moveProject(payload)
        .then(function (response) {
          // console.log(response)
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error creating the project.'
          });
        });

      // console.log(result)
      // console.log(me.directories)
      // console.log(me.projects)
      return true;
    }

    $scope.onMoved = function (list) {
      // console.log('onMoved')
      // console.log(list)
    };

    function showConfirmDelete(projectId) {
      var confirm = $window.confirm('Are you sure you want to delete this project and ALL its content?');
      if (confirm) {
        deleteProject(projectId);
      }
    }

    function deleteProject(projectId) {
      projectService.remove(projectId)
        .then(function (response) {
          var myProjectIndex = _.findIndex(me.projects, { id: projectId });

          if (myProjectIndex !== -1) {
            me.projects.splice(myProjectIndex, 1);
          }
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: "danger",
            msg: 'Error deleting project.'
          });
        });
    }

    function showConfirmDeleteFolder(folderId) {
      var confirm = $window.confirm('Are you sure you want to delete this folder and ALL its content?');
      if (confirm) {
        deleteFolder(folderId);
      }
    }

    function deleteFolder(folderId) {
      projectService.removeDirectory(folderId)
        .then(function (response) {
          console.log(response);
          var myDirectoryIndex = _.findIndex(me.directories, { id: folderId });

          if (myDirectoryIndex !== -1) {
            me.directories.splice(myDirectoryIndex, 1);
          }
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: "danger",
            msg: 'Error deleting folder.'
          });
        });
    }

    function prepareProjectToTag(project) {
      me.newUserTag = {};
      me.userTagToUpdate = {};
      me.projectToTag = project;
      me.stepTag = 0;
    }

    function retrieveUserTags() {
      usersRestService.getUserTags()
        .then(function (response) {
          me.userTags = response;
        });
    }

    function retrieveUserProjectTags() {
      usersRestService.getUserProjectTags()
        .then(function (response) {
          me.userProjectTags = response;
        });
    }

    function getProjectTag(projectId) {
      if (angular.isArray(me.userProjectTags)) {
        var userProjectTag = me.userProjectTags.find(function (userProjectTag) {
          return userProjectTag.projectId === projectId;
        });
        if (userProjectTag && me.userTags) {
          return me.userTags.find(function (userTag) {
            return userTag.id === userProjectTag.tagId;
          })
        }
      }
    }

    function createLabel() {
      usersRestService.createUserTags(me.newUserTag)
        .then(function (response) {
          retrieveUserTags();
          return toogleTagToProject(response);
        })
        .then(function () {
          me.newUserTag = {};
          me.stepTag = 0;
        })
        .catch(function (reason) {
          console.log(reason)
          var errorMessage = 'There was an error creating the label';
          if (reason.status === 422 && reason && reason.data && reason.data.message) {
            errorMessage = reason.data.message;
          }
          ssNotificationService.add({
            type: 'danger',
            msg: errorMessage
          });
        });
    }

    function toogleTagToProject(userTag, isSelected) {
      var promise;
      if (!isSelected) {
        promise = usersRestService.associateTagToProject({
          tagId: userTag.id,
          projectId: me.projectToTag.id
        });
      } else {
        var userProjectTag = me.userProjectTags.find(function (userProjectTag) {
          return userProjectTag.projectId === me.projectToTag.id;
        });

        promise = usersRestService.desassociateTagFromProject(userProjectTag.id);
      }

      return promise
        .then(function () {
          retrieveUserProjectTags();
          me.popoverTags[me.projectToTag.id] = false;
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error assigning the label'
          });
        });
    }

    function updateLabel() {
      usersRestService.updateUserTags(me.userTagToUpdate.id, me.userTagToUpdate)
        .then(function (response) {
          retrieveUserTags();
        })
        .then(function () {
          me.userTagToUpdate = {};
          me.stepTag = 0;
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error updating the label'
          });
        });
    }

    function deleteUserTag(tagId) {
      var confirm = $window.confirm('Are you sure you want to delete this label? This will affect all your projects that were using this label.');
      if (!confirm) {
        return;
      }

      usersRestService.deleteUserTags(tagId)
        .then(function () {
          retrieveUserTags();
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error deleting the label'
          });
        });
    }

    function labelFilter(project) {
      if (!angular.isArray(me.userTags)) {
        return true;
      }

      // me.tagFilters = me.userTags.filter(function (userTag) {
      //   return userTag.filterActive;
      // });

      if (me.tagFilters && me.tagFilters.length) {
        var projectTag = getProjectTag(project.id)
        if (!projectTag) {
          return false;
        }
        return me.tagFilters.find(function (tag) {
          return tag.id === projectTag.id;
        });
      }

      return true;
    }
  }

})();