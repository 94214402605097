(function () {
    'use strict';

    var SUPPORTED_MEDIA_FILES = {
        // '3gp': {
        //     type: 'video',
        //     mimeType: 'video/3gpp'
        // },
        '3g2': {
            type: 'video',
            mimeType: 'video/3gpp2'
        },
        aac: {
            type: 'audio',
            mimeType: 'audio/aac'
        },
        aif: {
            type: 'audio',
            mimeType: 'audio/aiff'
        },
        aiff: {
            type: 'audio',
            mimeType: 'audio/aiff'
        },
        asf: {
            type: 'video',
            mimeType: 'video/x-ms-asf'
        },
        avchd: {
            type: 'video'
        },
        // avi: {
        //     type: 'video',
        //     mimeType: 'video/x-msvideo'
        // },
        iff: {
            type: 'audio'
        },
        flac: {
            type: 'audio',
            mimeType: 'audio/flac'
        },
        flv: {
            type: 'video',
            mimeType: 'video/x-flv'
        },
        m3u: {
            type: 'audio',
            mimeType: 'audio/x-mpegurl'
        },
        m4a: {
            type: 'audio',
            mimeType: 'audio/mp4'
        },
        m4v: {
            type: 'video',
            mimeType: 'audio/mp4'
        },
        mxf: {
            type: 'video',
            mimeType: 'application/mxf'
        },
        mid: {
            type: 'audio',
            mimeType: 'audio/midi'
        },
        mkv: {
            type: 'video',
            mimeType: 'video/x-matroska'
        },
        mov: {
            type: 'video',
            mimeType: 'video/quicktime'
        },
        mp2: {
            type: 'audio',
            mimeType: 'audio/mpeg'
        },
        mp3: {
            type: 'audio',
            mimeType: 'audio/mp3'
        },
        mp4: {
            type: 'video',
            mimeType: 'video/mp4'
        },
        mpa: {
            type: 'audio',
            mimeType: 'audio/mpeg'
        },
        mpeg: {
            type: 'video',
            mimeType: 'video/mpeg'
        },
        mpg: {
            type: 'video',
            mimeType: 'video/mpeg'
        },
        mts: {
            type: 'video',
            mimeType: 'video/MP2T'
        },
        ogg: {
            type: 'video',
            mimeType: 'audio/ogg'
        },
        // ogv: {
        //     type: 'video',
        //     mimeType: 'audio/ogg'
        // },
        opus: {
            type: 'audio',
            mimeType: 'audio/ogg'
        },
        rm: {
            type: 'video'
        },
        srt: {
            type: 'video'
        },
        swf: {
            type: 'video',
            mimeType: 'application/x-shockwave-flash'
        },
        vob: {
            type: 'video'
        },
        wav: {
            type: 'audio',
            mimeType: 'audio/wav'
        },
        // webm: {
        //     type: 'video',
        //     mimeType: 'video/webm'
        // },
        wma: {
            type: 'audio',
            mimeType: 'audio/x-ms-wma'
        },
        wmv: {
            type: 'video',
            mimeType: 'video/x-ms-wmv'
        }
    };

    var MIME_TYPES = Object.keys(SUPPORTED_MEDIA_FILES)
        .reduce(function(mimeTypes, SUPPORTED_MEDIA_FILE_KEY) {
            var SUPPORTED_MEDIA_FILE = SUPPORTED_MEDIA_FILES[SUPPORTED_MEDIA_FILE_KEY];

            if (SUPPORTED_MEDIA_FILE.mimeType && !mimeTypes.includes(SUPPORTED_MEDIA_FILE.mimeType)) {
                mimeTypes.push(SUPPORTED_MEDIA_FILE.mimeType);
            }

            return mimeTypes;
        }, []);

    angular
        .module('app')
        .constant('SUPPORTED_MEDIA_FILES', SUPPORTED_MEDIA_FILES)
        .constant('MIME_TYPES', MIME_TYPES);
})();
