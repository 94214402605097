(function () {
    'use strict';

    angular
        .module('app')
        .factory('syncAudioService', syncAudioService);

    syncAudioService.$inject = ['$http', 'host'];

    function syncAudioService($http, host) {
        var basePathUrl = host + 'devices';
        return {
            getAllDevices: getAllDevices,
            syncFiles: syncFiles
        };

        function getAllDevices() {
            return $http.get(basePathUrl)
                .then(function (response) {
                    return response;
                });
        }

        function syncFiles(projectId) {
            return $http.get(host + "projects/" + projectId + "/files/sync")
                .then(function (response) {
                    return response;
                });
        }

    }

})();

