(function () {
    'use strict';

    angular
        .module('app')
        .factory('caretPositionHelper', caretPositionHelper);

    caretPositionHelper.$inject = [];

    /* @ngInject */
    function caretPositionHelper() {
        return {
            setCaretPosition: setCaretPosition
        };

        ////////////////

        function setCaretPosition(elem, caretPos) {
            if (elem) {
                if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                } else if (elem.setSelectionRange) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                } else {
                    elem.focus();
                }
            }
        }
    }

})();

