(function () {
  'use strict';
  angular
    .module('app')
    .component('accountSettingsPage', {
      controller: ProfileController,
      bindings: {},
      templateUrl: 'app/pages/account-settings-page/account-settings-page.component.html'
    });

  ProfileController.$inject = [
    '$timeout',
    '$rootScope',
    '$location',
    '$anchorScroll',
    'PLANS',
    'authService',
    'usersRestService',
    'ssNotificationService',
    '$uibModal',
    'subscriptionRestService',
    'boxAuthenticationService',
    '$window',
    'whitelabel'
  ];

  function ProfileController(
    $timeout,
    $rootScope,
    $location,
    $anchorScroll,
    PLANS,
    authService,
    usersRestService,
    ssNotificationService,
    $uibModal,
    subscriptionRestService,
    boxAuthenticationService,
    $window,
    whitelabel
  ) {
    const gapInMilliseconds = 3600000;
    var me = this;

    me.$onInit = onInit;
    me.isTabSelected = isTabSelected;
    me.goTo = goTo;
    me.formatDuration = formatDuration;
    me.getInviteLink = getInviteLink;

    me.getInviteCode = getInviteCode;
    me.getExpDate = getExpDate;
    me.startSubscribeFlow = startSubscribeFlow;
    me.updateSubscribeFlow = updateSubscribeFlow;
    me.isTeamOwner = isTeamOwner;
    me.isPlanActive = isPlanActive;
    me.cancelUserPlan = cancelUserPlan;
    me.undoDontCancelUserPlan = undoDontCancelUserPlan;
    me.boxAuthorize = boxAuthorize;
    me.boxDeAuthorize = boxDeAuthorize;
    me.toggleCreationPassword = toggleCreationPassword;
    me.createPassword = createPassword;
    me.changePassword = changePassword;
    me.cleanPasswordCreation = cleanPasswordCreation;

    me.isUserUsingGoogleAuth = isUserUsingGoogleAuth;
    me.connectGoogleAccount = connectGoogleAccount;

    me.isUserUsingEmailAuthentication = isUserUsingEmailAuthentication;
    me.whitelabel = whitelabel;

    function onInit() {
      me.showOrHideCreationPassword = false;
      me.creationUserInfo = {};

      me.PLANS = PLANS;

      retrieveTransactions();

      $anchorScroll.yOffset = 150;

      $timeout(function () {
        $anchorScroll();
      }, 1000);

      // appUserService.retrieveAndSetUser();

      fetchSubscriptionDetails();

    }

    function isUserUsingGoogleAuth() {
      return $rootScope.user && $rootScope.user.identities.indexOf('google-oauth2') !== -1;
    }

    function connectGoogleAccount() {
      authService.loginWithGoogle();
    }

    function isUserUsingEmailAuthentication() {
      return $rootScope.user && $rootScope.user.identities.indexOf('auth0') !== -1;
    }

    function toggleCreationPassword() {
      me.showOrHideCreationPassword = !me.showOrHideCreationPassword;
    }

    function cleanPasswordCreation() {
      me.creationUserInfo = {};
      me.showOrHideCreationPassword = false;
    }

    function createPassword() {
      me.creationUserInfo.email = $rootScope.user.email;
      me.creationUserInfo.userMetadata = {
        name: $rootScope.user.fullName
      };

      authService.createPassword(me.creationUserInfo)
        .then(function () {
          notifySuccessForCreatingPassword();
        })
        .catch(function (reason) {
          var errorMessage = "";

          if(reason.policy) {
            var message = designPasswordString(reason.policy);
            errorMessage = "Create another password <br>" + message;
          }else
            errorMessage = 'Some error happened at the time to create password.';
          
          ssNotificationService.add({ type: 'danger', msg: errorMessage });
        });
    }

    function designPasswordString(policy) {
      return policy.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    function changePassword() {
      authService.changePassword($rootScope.user.email)
        .then(function () {
          ssNotificationService.add({ type: 'success', msg: 'An email has been sent with instructions to change your password.' });
        })
        .catch(function (reason) {
          var errorMessage = reason || 'Some error happened at the time to create password.';
          ssNotificationService.add({ type: 'danger', msg: errorMessage });
        });
    }

    function notifySuccessForCreatingPassword() {
      ssNotificationService.add({ type: 'success', msg: 'Password successfully created.' });
      // appUserService.retrieveAndSetUser();
      cleanPasswordCreation();
    }

    function undoDontCancelUserPlan() {
      var confirm = $window.confirm('Are you sure you want to undo the cancellation of your current subscription?');
      if (!confirm) {
        return;
      }
      subscriptionRestService.undoDontCancelUserPlan()
        .then(function () {
          ssNotificationService.add({
            type: 'success',
            msg: 'The cancellation is rescinded and your original subscription will continue.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });

          fetchSubscriptionDetails();
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: 'danger',
            msg: 'Error processing your request.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });
        });

    }

    function cancelUserPlan() {
      var confirm = $window.confirm('Are you sure you want to cancel your current subscription?');
      if (!confirm) {
        return;
      }
      subscriptionRestService.cancelUserPlan()
        .then(function () {
          ssNotificationService.add({
            type: 'success',
            msg: 'Your subscription will be canceled at the end of the current subscription period.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });

          fetchSubscriptionDetails();
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: 'danger',
            msg: 'Error processing your request.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });
        });
    }

    function isTabSelected(hashValue) {
      return $location.hash() === hashValue;
    }

    function formatDuration(duration) {
      return moment.duration(duration, 'minutes')._data;
    }

    function getInviteLink(inviteCode) {
      return $location.protocol() + '://' + location.host + '/?inviteCode=' + inviteCode;
    }

    function getInviteCode() {
      usersRestService.getInviteCode()
        .then(function (response) {
          $rootScope.user.inviteCode = response.code;
          $rootScope.user.hasInviteCodeExpired = response.hasExpired;
        });
    }


    function goTo(event, hash) {
      $location.hash(hash);
      $anchorScroll();
    }

    function startSubscribeFlow(plan) {
      var modalInstance = $uibModal.open({

        animation: true,
        backdrop: 'static',
        component: 'subscribeToPlanModal',
        resolve: {
          planInfo: function () {
            return plan;
          },
          expDate: function () {
            return getExpDate();
          }
        }
      });
      modalInstance.result
        .then(function (modalResponse) {
          // appUserService.retrieveAndSetUser();
          ssNotificationService.add({
            type: 'success',
            msg: 'Your subscription plan ' + modalResponse.planName + ' has been renewed. We\'re happy we will be seeing you around more often.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });

        });
    }

    function updateSubscribeFlow(plan) {
      var modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'updatePaymentDetails',
        resolve: {
          planInfo: function () {
            return plan;
          }
        }
      });
      modalInstance.result
        .then(function (modalResponse) {

          // appUserService.retrieveAndSetUser();
          ssNotificationService.add({
            type: 'success',
            msg: 'Your card details has been updated.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });
        });
    }

    function getExpDate() {

      if ($rootScope.user) {
        var expDate = $rootScope.user.subscriptionCreditExpireDate;
        if (isTeamOwner() || $rootScope.user.team) {
          expDate = $rootScope.user.team.subscriptionCreditExpireDate;
        }
        if (expDate === null) {
          return null;
        }
        var exDate = expDate - gapInMilliseconds;
        return new Date(exDate).getTime();
      }
    }
    function onSuccessPasswordChange(successMessage) {
      ssNotificationService.add({ type: "success", msg: successMessage });
    }

    function onFailedPasswordChange(errorMessage) {
      ssNotificationService.add({ type: "danger", msg: errorMessage });
    }

    function retrieveTransactions() {
      usersRestService.getTransactions()
        .then(function (response) {
          me.transactions = response;
        });
    }

    function isTeamOwner() {
      return ($rootScope.user.team && $rootScope.user.email === $rootScope.user.team.owner);
    }
    function isPlanActive() {
      if ($rootScope.user && ($rootScope.user.plan.planEnum == PLANS.NO_SUBSCRIPTION_PLAN)) {
        return true;
      }
      var date = new Date();
      var expdate = getExpDate();
      expdate = new Date(expdate);
      return date <= expdate;
    }
    function boxAuthorize() {
      boxAuthenticationService.authorize().then(function (response) {
        $window.location.href = response.data.boxURI;
      });
    }
    function boxDeAuthorize() {
      boxAuthenticationService.deAuthorize().then(function (response) {
        // appUserService.retrieveAndSetUser();
        ssNotificationService.add({
          type: 'success',
          msg: 'Your Box account is disconnected',
          metadata: {
            keepAliveAfterTransition: true
          }
        });
      });
    }

    function fetchSubscriptionDetails() {
      subscriptionRestService.fetchCurrentUserSubscription()
        .then(function (response) {
          me.subscriptionDetail = response;
        }).catch(function (err) {
          me.subscriptionDetail = null;
        });
    }

  }
})();
