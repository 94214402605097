(function () {
  'use strict';

  angular
    .module('app')
    .component('breadcrumbs', {
      controller: BreadcrumbsController,
      bindings: {
        items: '<',
        minLength: '<'
      },
      templateUrl: 'app/components/breadcrumbs/breadcrumbs.component.html'
    });

  BreadcrumbsController.$inject = [];

  function BreadcrumbsController() {
    var me = this;

    me.$onInit = function () {
      me.minLength = me.minLength || 0;
    };
  }
})();
