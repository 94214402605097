(function () {
  'use strict';
  angular
    .module('app')
    .component('landingPage', {
      controller: LandingController,
      bindings: {},
      templateUrl: 'app/pages/landing-page/landing-page.component.html'
    });


  LandingController.$inject = [
    '$window',
    'LANDING_PAGE_URL'
  ];

  function LandingController(
    $window,
    LANDING_PAGE_URL
  ) {
    var me = this;

    me.$onInit = onInit;

    function onInit() {
      $window.open(LANDING_PAGE_URL, '_self');
    }



  }
})();
