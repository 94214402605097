(function () {
  'use strict';

  angular
    .module('app')
    .component('projectOpenPage', {
      controller: ProjectOpenPageController,
      templateUrl: 'app/pages/project-open-page/project-open-page.component.html',
      bindings: {
        project: '<'
      }
    });

  ProjectOpenPageController.$inject = [
    '$httpParamSerializer',
    '$rootScope',
    '$state',
    '$http',
    '$uibModal',
    '$window',
    'MediaService',
    'projectService',
    'usSpinnerService',
    'CustomAnalyticsService',
    'customEcommerceAnalytics',
    'ErrorService',
    'host',
    'languagesService',
    'WebSocketConnectionService',
    'ssNotificationService',
    'boxAuthenticationService',
    '$scope',
    'whitelabel'
  ];

  function ProjectOpenPageController(
    $httpParamSerializer,
    $rootScope,
    $state,
    $http,
    $uibModal,
    $window,
    MediaService,
    projectService,
    usSpinnerService,
    CustomAnalyticsService,
    customEcommerceAnalytics,
    ErrorService,
    host,
    languagesService,
    WebSocketConnectionService,
    ssNotificationService,
    boxAuthenticationService,
    $scope,
    whitelabel
  ) {
    var me = this;
    var filePicker;
    var dropBoxUploadingInProgress = false;
    var boxUploadingInProgress = false;

    var channelProjectMediaUpdate = 'mediaUpdate/project';
    var channelProjectUpdate = 'projectUpdate';
    var channelProjectStatusUpdate = 'projectStatusUpdate';

    var progressChangeListener;
    var uploadCallResponseListener;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;

    me.openQuickTips = openQuickTips;
    me.hasProjectUploads = hasProjectUploads;
    me.getMediaProgress = getMediaProgress;
    me.changeLanguage = changeLanguage;
    me.saveProject = saveProject;
    me.encodeFramePath = encodeFramePath;
    me.hasMedias = hasMedias;
    me.uploadLocalFiles = uploadLocalFiles;
    me.uploadDropboxFiles = uploadDropboxFiles;
    me.transcribe = transcribe;
    me.deleteMedia = deleteMedia;
    me.startTranscribeFlow = startTranscribeFlow;
    me.getStatusLabel = getStatusLabel;
    me.groupByFavorite = groupByFavorite;

    me.onPickedGoogleFiles = onPickedGoogleFiles;

    me.whitelabel = whitelabel;
    me.cancellableUploaders = {};

    $scope.$on('$routeChangeStart', function (scope, next, current) {
      hideBoxPicker();
      if (filePicker) {
        filePicker.hide();
      }
    });
    function onInit() {

      me.notConnectedToBox = false;
      me.selectedLanguageRegion = {};

      try {
        usSpinnerService.stop();
      } catch (e) { }


      languagesService.getAll()
        .then(function (languages) {
          me.languageRegions = buildLanguageRegionsToDisplay(languages);

          if (me.project.languageRegionCode) {

            me.selectedLanguageRegion.value = _.find(me.languageRegions, {
              code: me.project.languageRegionCode,
              defaultSpeechApi: me.project.defaultSpeechApi
            });

          }
        });

      subscribeToEvents();
      initBox();
    }

    function onDestroy() {
      unsubscribeFromEvents();
    }

    function subscribeToEvents() {
      WebSocketConnectionService.subscribe(channelProjectUpdate, me.project.id, function (updateForProject) {
        updateForProject.medias = updateForProject.medias.filter(function (media) {
            return media.status !== 'empty';
        });

        angular.merge(me.project, updateForProject);
      });

      WebSocketConnectionService.subscribe(channelProjectStatusUpdate, me.project.id, function (data) {
        if (data && data.status) {
            if(data.status === 'failed') {
              $state.reload();
            }

            if (me.project.status !== data.status && data.status === 'translated') {
              ssNotificationService.add({
                type: 'success',
                msg: 'Project has been translated.'
              });
            }
            if (me.project.status !== data.status && data.status === 'translated') {
              ssNotificationService.add({
                type: 'success',
                msg: 'Project has been subtitled.'
              });
            }

            me.project.status = data.status;
            projectService.redirectToSubState(me.project);
        }
      });

      MediaService.startListeningUploadProgress(me.project.id);

      progressChangeListener = $rootScope.$on('mediaProgressChange', function (event, mediaProgressUpdate) {

        var mediaToUpdate = _.find(me.project.medias, { id: mediaProgressUpdate.mediaId });
        if (mediaToUpdate) {
          mediaToUpdate.status = mediaProgressUpdate.status;
        }

        if (!MediaService.hasProjectUploads(me.project.id) && me.hasMedias()) {
          me.startTranscribeFlow();
        }
        $scope.$digest();
      });

      uploadCallResponseListener = $rootScope.$on('uploadCallResponse', function (event, updatedMediaId) {
        var mediaToUpdate = _.find(me.project.medias, { id: updatedMediaId });

        if (mediaToUpdate) {
          mediaToUpdate.status = "preparing"
          $scope.$digest();
        }
      });

      WebSocketConnectionService.subscribe(channelProjectMediaUpdate, me.project.id, function (updateForMedia) {
        var media = me.project.medias.find(function (mediaItem) {
          return updateForMedia.id === mediaItem.id;
        });

        if (media) {
          angular.merge(media, updateForMedia);
        }
      });


      ErrorService.subscribeMediaErrors(me.project.id, function (error) {

        ssNotificationService.add({ type: 'danger', msg: error.message });

        if (error.mediaId && error.deleteMedia) {
          me.deleteMedia(error.mediaId);
        }
      });
    }

    function unsubscribeFromEvents() {
      if (angular.isFunction(progressChangeListener)) {
        progressChangeListener();
      }
      if (angular.isFunction(uploadCallResponseListener)) {
        uploadCallResponseListener();
      }
      MediaService.stopListeningUploadProgress(me.project.id);
      WebSocketConnectionService.unsubscribe(channelProjectMediaUpdate, me.project.id);
      ErrorService.unsubscribeFromMediaErrors(me.project.id);

      if (me.project && me.project.id) {
        WebSocketConnectionService.unsubscribe(channelProjectUpdate, me.project.id);
        WebSocketConnectionService.unsubscribe(channelProjectStatusUpdate, me.project.id);
      }
    }


    function changeLanguage() {
      me.disabledSubmitButton = true;

      me.project.languageRegionCode = me.selectedLanguageRegion.value.code;
      me.project.defaultSpeechApi = me.selectedLanguageRegion.value.defaultSpeechApi;

      if (me.project.languageRegionCode !== 'en-US') {
        me.project.dictionaryId = null;
      }

      saveProject()
        .then(function () {
          me.disabledSubmitButton = false;
        })
        .catch(function () {
          me.disabledSubmitButton = false;
        });
    }

    function saveProject() {
      return projectService.save(me.project);
    }

    function encodeFramePath(framePath) {
      if (framePath && framePath.indexOf('http') !== -1) {
        var path = framePath.substring(framePath.lastIndexOf('/') + 1);
        var hostAndContext = framePath.substring(0, framePath.lastIndexOf('/') + 1);

        return hostAndContext + $window.encodeURIComponent(path);
      }
      return framePath;
    }

    function hasMedias() {
      if (angular.isArray(me.project.medias) && me.project.medias.length) {
        var emptyMedias = me.project.medias.filter(function (media) {
          return media.status === 'empty'
        });

        var completedMedias = me.project.medias.filter(function (media) {
          return ['empty', 'ready'].indexOf(media.status) !== -1;
        });

        return completedMedias.length > emptyMedias.length;
      }

      return false;
    }

    function handleUploadError(message, mediaId) {
      CustomAnalyticsService.trackEvent('Upload', 'Error upload file', message, null);

      ssNotificationService.add({ type: 'danger', msg: message });

      if (mediaId) {
        var media = _.find(me.project.medias, { id: mediaId });

        if (media) {
          me.deleteMedia(mediaId);
        }
      }
    }

    function uploadLocalFiles(files) {
      CustomAnalyticsService.trackEvent('Upload', 'Load local files', 'Local', null);

      MediaService.uploadFiles(files, me.project, function onUploadFilesSuccess(response, file) {
        CustomAnalyticsService.trackEvent('Upload', 'upload file success', 'Local', null);
        var media = response.data;

        media.status = 'preparing';
        me.project.medias.push(media);

        var preSignedUrlParams = {
          fileName : file.name,
          mimeType : file.type,
          mediaId : media.id
        }

        MediaService.getSignedUrl(me.project.id, preSignedUrlParams, function (res){
          me.cancellableUploaders[media.id] = MediaService.uploadLocalFile(file, res.data.key, media.id, me.project.id, res.data.url,handleUploadError);
        }, handleUploadError);

        // MediaService.prepareLocalFile(me.project.id, media.id, file, function (preparedFile) {
        //   var dataUploadFileEvent = {
        //     preparedFile: preparedFile,
        //     media: media,
        //     projectId: me.project.id,
        //     callbackError: handleUploadError
        //   };
        //   $rootScope.$emit('media.uploadFile', dataUploadFileEvent);
        // }, handleUploadError);
      }, handleUploadError);
    }

    function uploadDropboxFiles() {
      CustomAnalyticsService.trackEvent('Upload', 'Loading Dropbox files', 'Dropbox', null);

      $window.Dropbox.choose({
        linkType: 'direct',
        multiselect: true,
        success: function onDropboxChooseSuccess(files) {
          if (dropBoxUploadingInProgress) {
            return;
          }
          var filesCount = files.length;
          dropBoxUploadingInProgress = true;
          CustomAnalyticsService.trackEvent('Upload', 'Selected Dropbox files', 'Dropbox', null);

          MediaService.uploadFiles(files, me.project,
            function onUploadFilesSuccess(response, file) {
              CustomAnalyticsService.trackEvent('Project Open', 'uploaded dropbox files success', null, null);
              var media = response.data;

              me.project.medias.push(media);

              var preparedFile = MediaService.prepareDropboxFile(me.project.id, media.id, file, function (message, mediaId) {
                --filesCount;
                if (filesCount === 0) {
                  dropBoxUploadingInProgress = false;
                }
                handleUploadError(message, mediaId);
              });
              console.log('DDDDDDDDDDD');
              console.log(preparedFile);
              if (!preparedFile) {
                return;
              }

              var dataUploadFileEvent = {
                preparedFile: preparedFile,
                media: media,
                projectId: me.project.id,
                callbackError: handleUploadError
              };
              --filesCount;
              if (filesCount === 0) {
                dropBoxUploadingInProgress = false;
              }
              console.log('dataUploadFileEvent');
              console.log(dataUploadFileEvent);
              $rootScope.$emit('media.uploadFile', dataUploadFileEvent);
            },
            function (message) {
              --filesCount;
              if (filesCount === 0) {
                dropBoxUploadingInProgress = false;
              }
              handleUploadError(message);
            }
          );
        },
        cancel: function () {
          dropBoxUploadingInProgress = false;
        }
      });
    }

    function onPickedGoogleFiles(docs, accessToken) {
      var files = [];

      angular.forEach(docs, function (doc, index) {
        files.push({
          name: doc.name,
          bytes: doc.sizeBytes,
          link: doc.id,
          accessToken: accessToken
        });
      });

      MediaService.uploadFiles(files, me.project, function onUploadFilesSuccess(response, file) {
        var media = response.data;

        me.project.medias.push(media);

        var preparedFile = MediaService.prepareDriveFile(me.project.id, media.id, file, function (message, mediaId) {
          handleUploadError(message, mediaId);
        });

        var dataUploadFileEvent = {
          preparedFile: preparedFile,
          media: media,
          projectId: me.project.id,
          callbackError: handleUploadError
        };
        $rootScope.$emit('media.uploadFile', dataUploadFileEvent);
      }, handleUploadError);
    }

    me.uploadBoxFiles = function () {
      if (me.notConnectedToBox) {
        $('.main-app-header-container').show();
        $('.navbar-fixed-top').show();
        $('.bg-footer-login').show();
        ssNotificationService.add({
          type: "danger",
          msg: "First connect your Box Account from your <a style='text-decoration:underline; color:white' href='/account-settings/#connectedApps'> Profile page.</a>"
        });
      } else {
        $('.ui-box-file-picker').trigger('click');
        $('.main-app-header-container').hide();
        $('.navbar-fixed-top').hide();
        $('.bg-footer-login').hide();
      }

    }

    // TODO: Refactor me please, I am legacy code.
    function transcribe(useSavedCard, card, shouldSaveCard) {

      if (!me.project.languageRegionCode || !me.selectedLanguageRegion.value) {
        ssNotificationService.add({ type: 'warning', msg: 'Select a language first and I can transcribe your files.' });
        return;
      }

      CustomAnalyticsService.trackEvent('Transcribe', 'Click pay', null, null);
      var url = host + 'projects/checkout?projectId=' + me.project.id + '&replaceCard=' + !useSavedCard;
      me.disabledSubmitButton = true;

      if (me.price && !useSavedCard) {
        CustomAnalyticsService.trackEvent('Transcribe', 'Starting payment - data verified', null, null);
        usSpinnerService.spin('spinner-1');

        var additionalQueryParams = {
          cardHolderName: card.cardHolderName,
          number: card.cardNumber,
          exp_month: card.expirationMonth,
          exp_year: card.expirationYear,
          cvc: card.cvv,
          saveCard: shouldSaveCard
        };
        var queryString = $httpParamSerializer(additionalQueryParams);
        url += '&' + queryString;

        $http.post(url)
          .then(function (response) {
            var data = response.data.response;
            if (data === "OK") {
              // Clear transaction
              try {
                customEcommerceAnalytics.clearTrans();

                if (me.credit === 0) {
                  customEcommerceAnalytics.addItem({
                    id: me.project.id,
                    name: $rootScope.user.plan.planEnum,
                    sku: $rootScope.user.plan.planEnum + '-TX',
                    category: $rootScope.user.plan.planEnum,
                    price: me.price / me.totalTimeInMinutes,
                    quantity: me.totalTimeInMinutes
                  });
                } else {
                  var quantityToPass = '';
                  if (me.totalTimeInMinutes > me.credit) {
                    quantityToPass = me.credit;
                  }
                  if (me.totalTimeInMinutes === me.credit) {

                    quantityToPass = me.totalTimeInMinutes;
                  }
                  if (me.totalTimeInMinutes < me.credit) {
                    quantityToPass = me.totalTimeInMinutes;
                  }
                  customEcommerceAnalytics.addItem({
                    id: me.project.id,
                    name: $rootScope.user.plan.planEnum,
                    sku: $rootScope.user.plan.planEnum + '-SUB',
                    category: $rootScope.user.plan.planEnum,
                    price: '0',
                    quantity: quantityToPass
                  });

                  if (me.chargeableTimeInMinutes !== null && me.chargeableTimeInMinutes > 0) {
                    customEcommerceAnalytics.addItem({
                      id: me.project.id,
                      name: $rootScope.user.plan.planEnum,
                      sku: $rootScope.user.plan.planEnum + '-TX',
                      category: $rootScope.user.plan.planEnum,
                      price: me.price / me.chargeableTimeInMinutes,
                      quantity: me.chargeableTimeInMinutes
                    });
                  }
                }

                // Complete transaction
                customEcommerceAnalytics.trackTrans();
              } catch (error) {
                console.error(error);
              }


              CustomAnalyticsService.trackEvent('Transcribe', 'Payment success', null, null);
              $('#myModal').css('display', 'none');
              $('.modal-backdrop').css('display', 'none');
              $('#transButton').attr('disabled', 'disabled');
              $('#transButton').css('background-color', '#4cae4c');
              $('#alert-congratulation').css('display', 'block');

              $('#myModal').modal('hide');
              usSpinnerService.stop('spinner-1');
              $('#modal-success').modal('show');

              projectService.transcribe(me.project.id)
                .then(function success(response) {
                  CustomAnalyticsService.trackEvent('Transcribe', 'Starting transcription success', null, null);
                  console.debug('Transcribing project: ' + me.project.id);
                  $window.location.reload();
                })
                .catch(function error(reason) {
                  CustomAnalyticsService.trackEvent('Transcribe', 'Payment success', null, reason);
                  console.error("Error transcribing", reason);
                  me.disabledSubmitButton = false;

                  ssNotificationService.add({ type: 'danger', msg: reason || 'Something wrong happened. The transcribing request could not be processed.' });
                });

              return;
            }

            CustomAnalyticsService.trackEvent('Transcribe', 'Payment error', null, response);
            usSpinnerService.stop();
            me.disabledSubmitButton = false;

            var errorMessage = response && response.data && response.data.response ? response.data.response : 'Boo. Your payment failed.';

            ssNotificationService.add({ type: 'danger', msg: errorMessage });

          })
          .catch(function (reason) {
            console.log(reason);
            CustomAnalyticsService.trackEvent('Transcribe', 'Payment error 2', null, reason);

            usSpinnerService.stop();
            $('#myModal').css('display', 'none');
            $('.modal-backdrop').css('display', 'none');

            me.disabledSubmitButton = false;

            var errorMessage = 'Boo. Your payment failed.';
            if (reason.data && reason.data.message) {
              errorMessage = reason.data.message;
            }

            ssNotificationService.add({ type: 'danger', msg: errorMessage });
          });


      } else {
        usSpinnerService.spin('spinner-1');
        try {
          $http.post(url).then(
            function (response) {
              var data = response.data.response;
              if (data === "OK") {

                try {
                  // Clear transaction
                  customEcommerceAnalytics.clearTrans();

                  // start the transaction

                  customEcommerceAnalytics.addTransaction({
                    id: me.project.id,
                    affiliation: $rootScope.user.plan.planEnum,
                    revenue: me.price,
                    shipping: '0',
                    tax: '0'
                  });

                  if (me.credit === 0) {

                    customEcommerceAnalytics.addItem({
                      id: me.project.id,
                      name: $rootScope.user.plan.planEnum,
                      sku: $rootScope.user.plan.planEnum + '-TX',
                      category: $rootScope.user.plan.planEnum,
                      price: me.price / me.totalTimeInMinutes,
                      quantity: me.totalTimeInMinutes
                    });

                  } else {
                    var quantityToPass = '';
                    if (me.totalTimeInMinutes > me.credit) {
                      quantityToPass = me.credit;
                    }
                    if (me.totalTimeInMinutes === me.credit) {
                      quantityToPass = me.totalTimeInMinutes;
                    }
                    if (me.totalTimeInMinutes < me.credit) {
                      quantityToPass = me.totalTimeInMinutes;
                    }

                    customEcommerceAnalytics.addItem({
                      id: me.project.id,
                      name: $rootScope.user.plan.planEnum,
                      sku: $rootScope.user.plan.planEnum + '-SUB',
                      category: $rootScope.user.plan.planEnum,
                      price: 0,
                      quantity: quantityToPass
                    });

                    if (me.chargeableTimeInMinutes !== null && me.chargeableTimeInMinutes > 0) {
                      customEcommerceAnalytics.addItem({
                        id: me.project.id,
                        name: $rootScope.user.plan.planEnum,
                        sku: $rootScope.user.plan.planEnum + '-TX',
                        category: $rootScope.user.plan.planEnum,
                        price: me.price / me.chargeableTimeInMinutes,
                        quantity: me.chargeableTimeInMinutes
                      });

                    }
                  }

                  // Complete transaction
                  customEcommerceAnalytics.trackTrans();
                } catch (error) {
                  console.error(error);
                }


                CustomAnalyticsService.trackEvent('Transcribe', 'Payment success', null, null);
                $('#myModal').css('display', 'none');
                $('.modal-backdrop').css('display', 'none');
                $('#transButton').attr('disabled', 'disabled');
                $('#transButton').css('background-color', '#4cae4c');
                $('#alert-congratulation').css('display', 'block');

                $('#myModal').modal('hide');
                usSpinnerService.stop('spinner-1');
                $('#modal-success').modal('show');

                projectService.transcribe(me.project.id)
                  .then(function success(response) {
                    CustomAnalyticsService.trackEvent('Transcribe', 'Starting transcription success', null, null);
                    console.debug('Transcribing project: ' + me.project.id);
                    $window.location.reload();
                  })
                  .catch(function error(reason) {
                    CustomAnalyticsService.trackEvent('Transcribe', 'Payment success', null, reason);
                    console.error("Error transcribing", reason);
                    console.log(reason);
                    me.disabledSubmitButton = false;
                    ssNotificationService.add({ type: 'danger', msg: reason || 'Something wrong happened. The transcribing request could not be processed.' });
                  });

                return;
              }

              CustomAnalyticsService.trackEvent('Transcribe', 'Payment error', null, response);
              usSpinnerService.stop();
              var errorMessage = response && response.data && response.data.response ? response.data.response : 'Boo. Your payment failed.';

              ssNotificationService.add({ type: 'danger', msg: errorMessage });
              me.disabledSubmitButton = false;
            })
            .catch(function (reason) {
              CustomAnalyticsService.trackEvent('Transcribe', 'Payment error 2', null, reason);
              console.log(reason);

              var errorMessage = 'Boo. Your payment failed.';
              if (reason.data && reason.data.message) {
                errorMessage = reason.data.message;
              }

              $('#myModal').css('display', 'none');
              $('.modal-backdrop').css('display', 'none');
              usSpinnerService.stop();

              ssNotificationService.add({ type: 'danger', msg: errorMessage });
              me.disabledSubmitButton = false;
            });
        } catch (e) {
          me.disabledSubmitButton = false;
        }
      }
    }

    function deleteMedia(mediaId) {
      CustomAnalyticsService.trackEvent('Project Open', 'click delete media button', null, null);
      // we need to check if project is uploading, and if so, delete it
      if (me.cancellableUploaders[mediaId]) {
        me.cancellableUploaders[mediaId].abort()
      }


      MediaService.delete(me.project.id, mediaId)
        .then(function onMediaDeleteSuccess() {
          removeFromUI(mediaId);
          CustomAnalyticsService.trackEvent('Project Open', 'media deleted', null, null);
        })
        .catch(function (reason) {
          removeFromUI(mediaId);
          CustomAnalyticsService.trackEvent('Project Open', 'media delete error', null, reason);
          console.error(reason);
        });
    }

    function removeFromUI(mediaId) {
      var projectMedias = me.project.medias;
      for (var i = 0; i < projectMedias.length; ++i) {
        if (projectMedias[i].id === mediaId) {
          projectMedias.splice(i, 1);
          break;
        }
      }
      //check we're ready to transcribe if a still-working project has been removed.
      if (!MediaService.hasProjectUploads(me.project.id) && me.hasMedias()) {
        me.startTranscribeFlow();
      }
      $scope.$digest();
    }

    function startTranscribeFlow() {

      if (!hasMedias()) {
        ssNotificationService.add({ type: 'warning', msg: 'Upload a file first and I can transcribe it.' });
        return;
      }

      updateProjectInfo()
        .then(function () {

          if (!areAllFilesReady()) {
            ssNotificationService.add({ type: 'warning', msg: 'There are some files that still need to be processed/uploaded before continue transcribing.' });
            return;
          }

          $http.get(host + 'projects/getprice?projectId=' + me.project.id)
            .then(function (response) {
              var priceJSON = response.data;
              me.totalTimeInMinutes = priceJSON.totalDuration / 60;
              me.totalTime = moment.duration(priceJSON.totalDuration / 60, 'minutes')._data;
              var chargeableTimeInMinutes = priceJSON.chargeableTimeInSeconds / 60;

              me.chargeableTimeInMinutes = chargeableTimeInMinutes;
              me.chargeableTime = moment.duration(chargeableTimeInMinutes, 'minutes')._data;

              me.price = priceJSON.totalPrice;
              me.credit = priceJSON.credit;
              me.rate = priceJSON.rate;
              me.chargeableTimeInSeconds = priceJSON.chargeableTimeInSeconds;
              me.minimumChargeableTimeInSeconds = priceJSON.minimumChargeableTimeInSeconds;
              me.minimumPrice = priceJSON.minimumPrice;

              $('#myModal').modal('show');
            })
            .catch(function (reason) {
              console.log(reason);
            });
        });
    }

    function updateProjectInfo() {
      return projectService.get(me.project.id)
        .then(function (response) {
          me.project = response.data;
        })
    }

    function areAllFilesReady() {
      if (!me.project.medias || !me.project.medias.length) {
        return false;
      }

      var completedMedias = me.project.medias.filter(function (media) {
        return ['empty', 'ready'].indexOf(media.status) !== -1;
      });

      return me.project.medias.length === completedMedias.length;
    }

    function getStatusLabel(projectId, mediaId) {
      var progressStatus = (((MediaService.progressByProject[projectId] || {}).medias || {})[mediaId] || {}).status;
      var mediaProjectStatus = (me.project.medias.find(function (media) {
        return media.id === mediaId;
      }) || {}).status;
      var status = progressStatus || mediaProjectStatus;

      if (status && status.toLowerCase() === 'ready') {
        return 'ready!';
      } else if (status && status.toLowerCase() === 'transcoding') {
        return 'preparing';
      }

      return status;
    }

    function buildLanguageRegionsToDisplay(languages) {
      var languagesToDisplay = [];

      languages.forEach(function (language) {
        language.regions.forEach(function (region) {
          languagesToDisplay.push({
            label: language.label + " (" + region.name + ")",
            code: language.code + (region.code ? "-" + region.code : ""),
            defaultSpeechApi: region.defaultSpeechApi,
            isFavorite: region.favorite
          });
        });
      });

      languagesToDisplay = _.orderBy(languagesToDisplay, ['label'])

      var frequentlyUsed = _.filter(languagesToDisplay, { isFavorite: true });
      var others = _.filter(languagesToDisplay, { isFavorite: false });

      return _.concat(frequentlyUsed, others);
    }

    function groupByFavorite(languageToDisplayItem) {
      if (languageToDisplayItem.isFavorite) {
        return 'Frequently Used';
      }
      return 'Others';
    }

    function openQuickTips() {
      $uibModal.open({
        animation: true,
        component: 'quickTipsForUploadModal'
      });
    }

    function hasProjectUploads() {
      return MediaService.hasProjectUploads(me.project.id);
    }

    function getMediaProgress(mediaId) {
      if (mediaId &&
        MediaService.progressByProject[me.project.id] &&
        MediaService.progressByProject[me.project.id].medias &&
        MediaService.progressByProject[me.project.id].medias[mediaId]
      ) {
        return MediaService.progressByProject[me.project.id].medias[mediaId].progress
      }
      return null;
    }

    function initBox() {
      boxAuthenticationService.getAuthToken()
        .then(function (response) {
          me.notConnectedToBox = false;
          var token = response.data.accessToken;
          filePicker = new Box.FilePicker(token);
          filePicker.addListener('choose', function (files) {
            $('.main-app-header-container').show();
            $('.navbar-fixed-top').show();
            $('.bg-footer-login').show();
            if (boxUploadingInProgress) {
              return;
            }
            var filesCount = files.length;
            boxUploadingInProgress = true;
            CustomAnalyticsService.trackEvent('Upload', 'Selected Box files', 'Box', null);
            MediaService.uploadFiles(files, me.project,
              function onUploadFilesSuccess(response, file) {
                CustomAnalyticsService.trackEvent('Project Open', 'uploaded box files success', null, null);
                var media = response.data;

                me.project.medias.push(media);

                var preparedFile = MediaService.prepareBoxFile(me.project.id, media.id, file, function (message, mediaId) {
                  --filesCount;
                  if (filesCount === 0) {
                    boxUploadingInProgress = false;
                  }
                  handleUploadError(message, mediaId);
                });

                if (!preparedFile) {
                  return;
                }
                preparedFile.boxFileId = file.id;

                var dataUploadFileEvent = {
                  preparedFile: preparedFile,
                  media: media,
                  projectId: me.project.id,
                  callbackError: handleUploadError
                };
                --filesCount;
                if (filesCount === 0) {
                  boxUploadingInProgress = false;
                }
                $rootScope.$emit('media.uploadFile', dataUploadFileEvent);
              },
              function (message) {
                --filesCount;
                if (filesCount === 0) {
                  boxUploadingInProgress = false;
                }
                handleUploadError(message);
              }
            );

          });
          filePicker.addListener('cancel', function () {
            hideBoxPicker();
          });

          filePicker.show('0', token, {
            container: '.preview-container',
            modal: {
              buttonLabel: 'Content Tree',
              buttonClassName: 'ui-box-file-picker'
            }

          });
        }).catch(function (err) {
          console.log(err);
          me.notConnectedToBox = true;
        });
    }
    function hideBoxPicker() {
      $('.main-app-header-container').show();
      $('.navbar-fixed-top').show();
      $('.bg-footer-login').show();
    }

  }

})();
