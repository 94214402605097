(function () {
    'use strict';

    angular
        .module('app')
        .component('shareProjectModal', {
            controller: ShareProjectModalController,
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'app/components/share-project-modal/share-project-modal.component.html'
        });

    ShareProjectModalController.$inject = [
        '$rootScope',
        '$q',
        '$location',
        'sharedProjectsService',
        'sharedProjectsRestService',
        'teamRestService',
        'PLANS'
    ];

    function ShareProjectModalController(
        $rootScope,
        $q,
        $location,
        sharedProjectsService,
        sharedProjectsRestService,
        teamRestService,
        PLANS
    ) {
        var me = this;
        var originalShareTarget = null;
        var originalPublishTarget = null;
        var originalEditAccessTarget = null;
        var originaleditAccessSelectedIndex = null;
        var originalMembers = null;
        var originalPublicDescription;

        me.$onInit = onInit;
        me.ok = ok;
        me.reset = reset;
        me.cancel = cancel;
        me.getProjectPublicLink = getProjectPublicLink;
        me.hasShareOptionChange = hasShareOptionChange;
        me.hasPublishOptionChange = hasPublishOptionChange;
        me.PLANS = PLANS;
        me.generateEditAccessLink = generateEditAccessLink;
        me.editAccessTarget = null;
        me.editAccessOptions = null;
        me.editAccessSelectedIndex = 0;
        me.dropdownSelected = dropdownSelected;
        me.hasEditAccessChange = hasEditAccessChange;

        function onInit() {
            me.isProjectOwner = me.resolve.isProjectOwner;
            me.isTeamMember = me.resolve.isTeamMember;
            me.publicationDescriptionLimit = 255;
            me.isFromAdmin = me.resolve.isFromAdmin;
            me.publicDescription = me.resolve.project.publicDescription;
            originalPublicDescription = me.publicDescription;

            const userPlan = me.resolve.project.owner.plan.planEnum;
            if (userPlan === 'PAY_AS_YOU_GO' || userPlan.startsWith('STARTER')) {
                me.editAccessOptions = [
                    'can view'
                ];
            } else {
                me.editAccessOptions = [
                    'can view',
                    'can edit'
                ];
            }
            var allMembersPromise;
            var peopleISharedProjectWithPromise;
            var peopleISharedProjectWithPromise;
            if (me.isFromAdmin) {
                allMembersPromise = teamRestService.getProjectMembers(me.resolve.project.owner.id);
                peopleISharedProjectWithPromise = sharedProjectsRestService.getPeopleUserSharedProjectWith(me.resolve.project.id);
            } else {
                allMembersPromise = teamRestService.getMembers();
                peopleISharedProjectWithPromise = sharedProjectsRestService.getPeopleISharedProjectWith(me.resolve.project.id);
            }


            $q.all([allMembersPromise, peopleISharedProjectWithPromise])
                .then(function (data) {
                    var allMembers = data[0];
                    var peopleISharedWith = data[1];
                    me.members = allMembers.map(function (memberItem) {
                        return {
                            email: memberItem.email,
                            isSharedWith: _.findIndex(_.intersectionBy(allMembers, peopleISharedWith, 'email'), { email: memberItem.email }) !== -1
                        };
                    });
                    originalMembers = allMembers.map(function (memberItem) {
                        return {
                            email: memberItem.email,
                            isSharedWith: _.findIndex(_.intersectionBy(allMembers, peopleISharedWith, 'email'), { email: memberItem.email }) !== -1
                        };
                    });
                    if (peopleISharedWith.length > 0) {
                        me.isProjectShared = true;
                        me.shareTarget = allMembers.length === peopleISharedWith.length ? 'allTeam' : 'fewMembers';
                        originalShareTarget = me.shareTarget;
                    }
                });

            sharedProjectsRestService.getSharedInfo(me.resolve.project.id)
                .then(function (response) {
                    if(response && response.length > 0) {
                        if(me.resolve.isProjectOwner) {
                            const userPlan = me.resolve.project.owner.plan.planEnum;
                            if(userPlan === 'PAY_AS_YOU_GO' || userPlan.startsWith('STARTER')) {
                                //share view only
                                const shareInfo = _.find(response, function(o) { return o.type === 'VIEW' && !o.userId; });
                                if(shareInfo) {
                                    me.editAccessTarget = 'editAccess';
                                    originalEditAccessTarget = 'editAccess';
                                    me.editAccessSelectedIndex = 0;
                                    originaleditAccessSelectedIndex = 0;
                                }
                            } else if(userPlan.startsWith('PRO' || userPlan.startsWith('TEAM'))) {
                                const shareInfo = _.find(response, function(o) { return (o.type === 'VIEW' || o.type === 'EDIT') && !o.userId; });
                                if(shareInfo) {
                                    me.editAccessTarget = 'editAccess';
                                    originalEditAccessTarget = 'editAccess';
                                    originaleditAccessSelectedIndex = shareInfo.type === 'VIEW' ? 0 : 1;
                                    me.editAccessSelectedIndex = originaleditAccessSelectedIndex;
                                }
                            } else if(userPlan.startsWith('TEAM')) {
                                console.log(response)
                                const shareInfo = _.find(response, function(o) { return (o.type === 'VIEW' || o.type === 'EDIT') && o.userId; });
                                if(shareInfo) {
                                    me.editAccessTarget = 'editAccess';
                                    originalEditAccessTarget = 'editAccess';
                                    originaleditAccessSelectedIndex = shareInfo.type === 'VIEW' ? 0 : 1;
                                    me.editAccessSelectedIndex = originaleditAccessSelectedIndex;
                                }
                            }
                        }
                    }
                });

            sharedProjectsRestService.isPublic(me.resolve.project.id)
                .then(function (response) {
                    if (response && response.isPublic === true) {
                        me.isProjectPublished = true;
                        me.publishTarget = 'public';
                        originalPublishTarget = me.publishTarget;
                    }
                });
        }

        function ok() {
            me.resolve.project.publicDescription = me.publicDescription;
            me.close({
                $value: {
                    shareTarget: me.shareTarget,
                    publishTarget: me.publishTarget,
                    members: me.members,
                    editAccessTarget: me.editAccessTarget && me.editAccessSelectedIndex === 0 ? 'VIEW' : 'EDIT'
                }
            });
        }

        function reset() {
            me.shareTarget = originalShareTarget;
            me.publishTarget = originalPublishTarget;
            me.publicDescription = originalPublicDescription;
            me.editAccessTarget = originalEditAccessTarget;
            me.editAccessSelectedIndex = originaleditAccessSelectedIndex;
        }

        function cancel() {
            me.dismiss();
        }

        function generateEditAccessLink() {
            return $location.protocol() + '://' + location.host + '/app/projects/' + me.resolve.project.id + '?sharing=' + me.resolve.project.id;
        }

        function getProjectPublicLink() {
            return sharedProjectsService.getProjectPublicLink(me.resolve.project);
        }

        function dropdownSelected(index) {
            me.editAccessSelectedIndex = index;
        }

        var isEqual = function (value, other) {

            var type = Object.prototype.toString.call(value);

            if (type !== Object.prototype.toString.call(other)) return false;

            if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

            var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;

            var match = true;

            var compare = function (item1, item2) {
                if (item1.isSharedWith != item2.isSharedWith) {
                    match = false
                }
            };

            if (type === '[object Array]') {
                for (var i = 0; i < valueLen; i++) {
                    compare(value[i], other[i]);
                }
            } else {
                for (var key in value) {
                    if (value.hasOwnProperty(key)) {
                        compare(value[key], other[key]);
                    }
                }
            }

            return match;

        };

        function hasShareOptionChange() {
            return me.shareTarget && (originalShareTarget !== me.shareTarget || !isEqual(originalMembers, me.members));
        }

        function hasEditAccessChange() {
            return (me.editAccessTarget && originalEditAccessTarget !== me.editAccessTarget) ||
            (originaleditAccessSelectedIndex !== me.editAccessSelectedIndex);
        }

        function hasPublishOptionChange() {
            return me.publishTarget && (originalPublishTarget !== me.publishTarget) || (originalPublicDescription !== me.publicDescription);
        }
    }

})();
