(function () {
    'use strict';

    angular
        .module('app')
        .factory('projectAdminService', projectAdminService);

    projectAdminService.$inject = ['$http', 'host'];

    /* @ngInject */
    function projectAdminService($http, host) {
        var basePathUrl = host + 'admin/projects';
        
        var service = {
            retryJobRequest: retryJobRequest,
            updateMedia:updateMedia,
            deleteMediaFile:deleteMediaFile
        };
        return service;

        ////////////////

        function retryJobRequest(projectId) {
            return $http.get(basePathUrl + '/' + projectId + '/retry-job');
        }
        function updateMedia(id,mediaPayload){
            return $http.put(host+"/media/" + id,mediaPayload);
        }
        function deleteMediaFile(id){
            return $http.delete(host+"/media/"+id);
        }
        
    }
    
})();

