(function () {
  'use strict';
  angular
    .module('app')
    .filter('priceCentPerMinute', getPriceCentPerMinute);

    getPriceCentPerMinute.$inject = ['$filter'];
    function getPriceCentPerMinute($filter) {
      return function (pricePerHour) {
        var priceCentPerMinute = $filter('number')(pricePerHour  * 100 / 60 , 1);

        return parseFloat(priceCentPerMinute) + '¢/minute';
      }
    }


})();
