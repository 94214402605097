(function () {
  'use strict';
  angular
    .module('app')
    .component('loadingIndicator', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/components/loading-indicator/loading-indicator.component.html'
    });

})();
