(function () {
  'use strict';
  angular
    .module('app')
    .component('noAuthorizePage', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/pages/no-authorize-page/no-authorize-page.component.html'
    });
})();
