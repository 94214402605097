(function () {
    'use strict';

    angular
        .module('app')
        .factory('mergeProjectService', mergeProjectService);

    mergeProjectService.$inject = ['$http', 'host'];

    /* @ngInject */
    function mergeProjectService($http, host) {
        var basePathUrl = host + 'admin/projects';
        var basePathUrlUser = host + 'admin/users';

        var service = {
            mergeProjectRequest: mergeProjectRequest,
            loadSourceProjects: loadSourceProjects
        };
        return service;

        ////////////////

        function mergeProjectRequest(payload) {
            return $http.post(basePathUrl + '/project/merge', payload);
        }

        function loadSourceProjects(projectId, userId) {
            return $http.get(basePathUrlUser + '/' + userId + "/projects")
                .then(function (response) {
                    var userProjects = response.data;
                    var sourceProjects = [];
                    angular.forEach(userProjects, function (value, key) {
                        if (projectId !== value.id)
                            sourceProjects.push(value);
                    });
                    return sourceProjects;
                });
        }

    }

})();

