(function () {
    'use strict';

    angular
        .module('app')
        .factory('languageInfoService', languageInfoService);


    languageInfoService.$inject = [];

    /* @ngInject */
    function languageInfoService() {

        return {
            getLanguageRegionLabelFromCode: getLanguageRegionLabelFromCode
        };

        function getLanguageRegionLabelFromCode(languages, languageRegionCode, defaultSpeechApi) {
            if (angular.isArray(languages)) {

                var languageCode = languageRegionCode.split(/-(.+)/)[0];
                var regionCode = languageRegionCode.split(/-(.+)/)[1];

                var region;
                var language = languages.find(function (languageItem) {
                    if (languageItem.code === languageCode) {
                        region = languageItem.regions.find(function (regionItem) {
                            return regionItem.code === regionCode && regionItem.defaultSpeechApi === defaultSpeechApi;
                        });
                        return region;
                    }
                });


                if (language && region) {
                    return language.label + " (" + region.name + ")";
                }
            }
        }
    }

})();
