(function () {
    'use strict';

    angular
        .module('app')
        .filter('linkyWithHtml', linkyWithHtml);

    linkyWithHtml.$inject = ['$filter'];
    function linkyWithHtml($filter) {
        return function(value, target) {
            if (!value) {
                return value;
            }

            value = value.replace(/\n/g, " <br/> ");
            var linked = $filter('linky')(value, target);
            return linked.replace(/\&gt;/g, '>').replace(/\&lt;/g, '<');
        };
    }

})();
