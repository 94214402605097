angular.module('app')
    .service ('CustomAnalyticsService', ['$filter', '$location', function ($filter, $location) {
        function trackEvent(eventCategory, eventAction, eventLabel, fieldsObject, eventValue) {
            var DEFAULT_CATEGORY = "default";
            var DEFAULT_LABEL = "default";

            var event = {
                eventCategory: eventCategory || DEFAULT_CATEGORY,
                eventAction: eventAction,
                eventLabel: eventLabel || DEFAULT_LABEL,
                fieldsObject: fieldsObject
            };

            if (eventValue) {
                event.eventValue = eventValue;
            }

            if ($location.host() === 'localhost') {
                console.debug($filter('json')(event));
            } else {
                ga('send', 'event', event);
            }
        };

        return {
            trackEvent: trackEvent
        };
    }]);
