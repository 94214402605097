(function () {
    'use strict';

    angular
        .module('app')
        .filter('initials', initials);

    initials.$inject = [];
    function initials() {
        return initialsFilter;

        function initialsFilter(fullName) {
            var result = '';
            if (fullName){
                var parts = fullName.split(' ');
                if (parts.length > 1) {

                    result = parts.map(function (part) {
                        var possibleNumber = parseInt(part, 10);
                        if (isNaN(possibleNumber)) {
                            return part[0];
                        }
                        return possibleNumber;
                    }).join('').toUpperCase();

                    result = result.substring(0, 3);
                } else if (parts.length === 1) {
                    result = fullName.substring(0, 1).toUpperCase() + fullName.substring(1, 3);
                }
            }
            return result;
        }
    }

})();
