(function () {

  'use strict';

  angular
    .module('app')
    .service('authService', authService);

  authService.$inject = [
    '$injector',
    '$state',
    'APP_CONFIG',
    'angularAuth0',
    'ssStorageService'
  ];

  function authService(
    $injector,
    $state,
    APP_CONFIG,
    angularAuth0,
    ssStorageService
  ) {

    return {
      getAccessToken: getAccessToken,
      getAuthHeaderName: getAuthHeaderName,
      getTokenType: getTokenType,
      handleAuthentication: handleAuthentication,
      isAdmin: isAdmin,
      isAuthenticated: isAuthenticated,
      isSuperAdmin: isSuperAdmin,
      login: login,
      loginWithGoogle: loginWithGoogle,
      createPassword: createPassword,
      changePassword: changePassword,
      logout: logout,
      renewTokens: renewTokens
    };

    function getAuthHeaderName() {
      return 'Authorization';
    }

    function getTokenType() {
      return 'Bearer';
    }

    function getAccessToken() {
      return ssStorageService.get('accessToken');
    }

    function getExpiresAt() {
      return ssStorageService.get('expiresAt');
    }

    function login() {
      angularAuth0.authorize();
    }

    function loginWithGoogle() {
      angularAuth0.authorize({
        prompt: 'login',
        connection: 'google-oauth2'
      });
    }

    function createPassword(options) {
      return new Promise(function(resolve, reject) {
        angularAuth0.signup({
          email: options.email,
          password: options.password,
          userMetadata: options.userMetadata,
          connection: 'Username-Password-Authentication'
        }, function (error) {
            if (error) {
              reject(error);
              return;
            }
            resolve(options.email);
        })
      });
    }

    function changePassword(email) {
      return new Promise(function(resolve, reject) {
        angularAuth0.changePassword({
          email: email,
          connection: 'Username-Password-Authentication'
        }, function (error) {
            if (error) {
              reject(error);
              return;
            }
            resolve(email);
        })
      });
    }

    function goHomePage() {
      $state.go('app.dashboard');
    }

    function goLandingPage() {
      $state.go('landingPage');
    }

    function goSharingProject() {
      var projectId = document.cookie.replace(/(?:(?:^|.*;\s*)sharing\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      if(projectId){
        document.cookie = "sharing= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
        window.location.replace('/app/projects/'+ projectId);
      }
    }

    function handleAuthentication() {
      var ssNotificationService = $injector.get('ssNotificationService');

      angularAuth0.parseHash(function (reason, authResult) {
        if (authResult && authResult.accessToken && authResult.idToken && authResult.idTokenPayload) {
          localLogin(authResult);
          goSharingProject();
          goHomePage();
        } else if (reason) {
          console.error(reason);
          var errorMessage = 'This user can\'t access to the site. For more info please contact Support.';

          if (reason.errorDescription) {
            errorMessage = reason.errorDescription;
          } else if (reason.error) {
            errorMessage = reason.error;
          }

          ssNotificationService.add({
            type: 'danger',
            msg: errorMessage,
            metadata: {
              keepAliveAfterTransition: true
            }
          });

          logout();

          goLandingPage();

        }
      });
    }

    function localLogin(authResult) {
      ssStorageService.set('accessToken', authResult.accessToken);
      ssStorageService.set('expiresAt', (authResult.expiresIn * 1000) + new Date().getTime());
    }

    function renewTokens() {
      angularAuth0.checkSession({},
        function (err, result) {
          if (err) {
            console.error(err);
            logout();
          } else {
            localLogin(result);
            if ($state.current.name === 'authCallbackPage') {
              goHomePage();
            }
          }
        }
      );
    }

    function logout() {
      ssStorageService.remove('accessToken');
      ssStorageService.remove('expiresAt');
      angularAuth0.logout({
        returnTo: APP_CONFIG.LOGOUT_REDIRECT_TO,
      });
    }

    function isAuthenticated() {
      return getAccessToken() && new Date().getTime() < getExpiresAt();
    }

    function isAdmin(user) {
      if (!user || !user.role) {
        return false;
      }
      var userRole =  user.role.name;
      if (['ROLE_SUPERADMIN', 'ROLE_ADMIN'].indexOf(userRole) !== -1) {
          return true;
      }
      return false;
    }

    function isSuperAdmin(user) {
      if (!user || !user.role) {
        return false;
      }
      var userRole =  user.role.name;
      if (userRole === 'ROLE_SUPERADMIN') {
          return true;
      }
      return false;
    }

  }
})();
