/**
 * Created by Luiz on 05/05/2017.
 */
angular.module("app").directive("credits", function() {
    return {
        restrict: 'E',
        scope: {
            ngModel: '='
        },
        templateUrl: 'app/directives/credits.html',
        link: function(scope, element, attr, ngModelController) {
            scope.getHours = getHours;
            function getHours() {
                if (scope.ngModel) {
                    return (scope.ngModel.days * 24) + scope.ngModel.hours;
                }
            }
        }
    };
});
