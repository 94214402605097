/**
 * Created by Luiz on 01/06/2017.
 */
angular.module("app").directive("limitToMax", function() {
    return {
        link: function(scope, element, attributes) {
            scope.$watch(function() {return attributes.max;}, function(newVal, oldVal){
                if (Number(element.val()) > Number(attributes.max)) {
                    element.val(Math.ceil(attributes.max) - 1);
                }
            }, true);

            element.on("keydown keyup", function(e) {
                if (Number(element.val()) >= Number(attributes.max) &&
                    e.keyCode != 46 // delete
                    &&
                    e.keyCode != 8 // backspace
                ) {
                    e.preventDefault();
                    element.val(Math.ceil(attributes.max) - 1);
                }
            });
        }
    };
});