(function () {
    'use strict';

    angular
        .module('app')
        .factory('teamRestService', teamRestService);

    teamRestService.$inject = ['$http', 'host'];

    /* @ngInject */
    function teamRestService($http, host) {
        var basePathUrl = host + 'team';

        var service = {
            getMembers: getMembers,
            getProjectMembers: getProjectMembers
        };
        return service;

        ////////////////

        function getMembers() {
            return $http.get(basePathUrl + '/members')
                .then(function (response) {
                    return response.data;
                });
        }

        function getProjectMembers(projectOwner) {
            return $http.get(basePathUrl + '/members/' + projectOwner)
                .then(function (response) {
                    return response.data;
                });
        }
    }

})();
