(function () {
  'use strict';

  angular
    .module('app')
    .factory('adminDefaultConfigRestService', adminDefaultConfigRestService);

  adminDefaultConfigRestService.$inject = ['$http', 'host'];

  /* @ngInject */
  function adminDefaultConfigRestService($http, host) {

    var basePathUrl = host + 'admin/config';

    var service = {
      getDefaultConfig: getDefaultConfig,
      updateDefaultConfig: updateDefaultConfig
    };
    return service;

    ////////////////

    function getDefaultConfig() {
      return $http.get(basePathUrl)
        .then(function (response) {
          return response.data;
        });
    }

    function updateDefaultConfig(payload) {
      return $http.put(basePathUrl, payload)
        .then(function (response) {
          return response.data;
        });
    }

  }

})();

