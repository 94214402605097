(function () {
    'use strict';

    angular
        .module('app')
        .constant('MEDIA_THUMBNAILS_IMAGES', {
            audio: 'img/poster-audio.png',
            video: 'img/poster-video.png'
        });
})();
