(function () {
    'use strict';

    angular
        .module('app')
        .component('syncAudioModal', {
            controller: SyncAudioModelController,
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'app/components/sync-audio-modal/sync-audio-modal.component.html'
        });

    SyncAudioModelController.$inject = [
        'usSpinnerService',
        'ssNotificationService',
        'syncAudioService'
    ];

    function SyncAudioModelController(
        usSpinnerService,
        ssNotificationService,
        syncAudioService
    ) {
        var me = this;
        me.$onInit = onInit;
        me.ok = ok;
        me.cancel = cancel;

        function onInit() {
            me.disabledSubmitButton = false;

            syncAudioService.getAllDevices()
                .then(function (response) {
                    me.deviceList = response.data;
                });
            console.log(me.resolve.filesToSync);
        }
        function ok() {
            usSpinnerService.spin('spinner-1');
            me.disabledSubmitButton = true;
            console.log("Okayyy pressed" + me.resolve.project.id);
            syncAudioService.syncFiles(me.resolve.project.id)
                .then(function (response) {
                    usSpinnerService.stop('spinner-1');
                    me.close();
                    console.log("Innnnnnn" + response);
                })
                .catch(function (reason) {
                    console.error(reason);
                    ssNotificationService.add({
                        type: 'danger',
                        msg: reason && reason.data && reason.data.message ? reason.data.message : 'There was an error processing the files.'
                    });
                    me.disabledSubmitButton = false;
                    usSpinnerService.stop('spinner-1');
                })
        }

        function cancel() {
            me.dismiss();
        }

    }

})();
