/**
 * Created by Luiz on 17/02/2017.
 */
angular.module("app").filter('milliToTime', function() {

    function padTime(t) {
        return t < 10 ? "0"+t : t;
    }

    return function(_milliseconds, addMilliseconds, showHours) {
        var _seconds = _milliseconds / 1000;

        var millisecondsString = '';
        if (addMilliseconds) {
            millisecondsString =  Math.floor(_milliseconds % 1000);
            millisecondsString = ',' + padTime(millisecondsString);
        }

        if (typeof _seconds !== "number" || _seconds < 0)
            return "00:00:00";

        var hours = Math.floor(_seconds / 3600),
            minutes = Math.floor((_seconds % 3600) / 60),
            seconds = Math.floor(_seconds % 60);

        if (showHours || hours > 0)
            return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds) + millisecondsString;
        else
            return padTime(minutes) + ":" + padTime(seconds) + millisecondsString;
    };
});
