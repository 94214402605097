(function () {
    'use strict';
    angular.
        module('app').
        factory('filesToSyncRestService', filesToSyncRestService);

    filesToSyncRestService.$inject = ['$http', 'host'];

    function filesToSyncRestService($http, host) {

        return {
            getFilesToSync: getFilesToSync,
            deleteFileToSync: deleteFileToSync,
        };

        function getFilesToSync(projectId) {
            var url = host + 'project/' + projectId + '/mobile/files';
            return $http.get(url)
                .then(function (response) {
                    return response.data;
                });
        }

        function deleteFileToSync(projectId, fileToSyncId) {
            var url = host + 'project/' + projectId + '/mobile/files/' + fileToSyncId;
            return $http.delete(url);
        }
    }

})();
