(function () {
    'use strict';
    angular
      .module('app')
      .config(appConfig);

      appConfig.$inject = [
        '$httpProvider',
        '$locationProvider',
        '$qProvider',
        'AUTH0_CONFIG',
        'GDRIVE_CLIENT_ID',
        'AnalyticsProvider',
        'angularAuth0Provider',
        'customEcommerceAnalyticsProvider',
        'host',
        'hotkeysProvider',
        'toastrConfig',
        'lkGoogleSettingsProvider',
      ];

      function appConfig(
          $httpProvider,
          $locationProvider,
          $qProvider,
          AUTH0_CONFIG,
          GDRIVE_CLIENT_ID,
          AnalyticsProvider,
          angularAuth0Provider,
          customEcommerceAnalyticsProvider,
          host,
          hotkeysProvider,
          toastrConfig,
          lkGoogleSettingsProvider
      ) {

      $locationProvider.html5Mode(true);
      $locationProvider.hashPrefix('');

      hotkeysProvider.templateFooter = '' +
          '<div class="smart-commands-help-footer">' +
          '<h4 class="cfp-hotkeys-title"> What can I do on this page? </h4>' +
          '<ol>\n' +
          '    <li> Play your media and adjust the playback speed, edit the text, and add speaker labels.</li>\n' +
          '    <li> Export your transcript.</li>\n' +
          '    <li> Share your project with your team (monthly subscription required; contact us to activate the feature).</li>\n' +
          '    <li> Synchronize timecode.</li>\n' +
          '    <li> Search for keywords, bookmark rows, and annotate.</li>\n' +
          '</ol>' +
          '</div>';

      angularAuth0Provider.init(AUTH0_CONFIG);

      // googleAuthenticationProvider.setClientId('152544279525-ch4ilufbugjort8monvml57l14qtmhj6.apps.googleusercontent.com');
      // googleAuthenticationProvider.setScopes(['profile', 'email']);
      // googleAuthenticationProvider.setVerificationUrl(host + 'auth/google');

      lkGoogleSettingsProvider.configure({
        clientId : GDRIVE_CLIENT_ID,
        scopes   : ['https://www.googleapis.com/auth/drive.readonly'],
        features : ['SUPPORT_TEAM_DRIVES', 'MULTISELECT_ENABLED']
      });

      $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      $httpProvider.interceptors.push('authInterceptor');

      // Google Analytics Configuration
      var isEcommerceEnabled = true; // Set this true on production
      AnalyticsProvider.setAccount('UA-87674016-1').logAllCalls(true).useECommerce(isEcommerceEnabled, isEcommerceEnabled);
      customEcommerceAnalyticsProvider.enableEcommerce(isEcommerceEnabled);

      $qProvider.errorOnUnhandledRejections(false);

      angular.extend(toastrConfig, {
        allowHtml: true,
        closeButton: true,
        closeHtml: '<button>&times;</button>',
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false,
        preventDuplicates: false,
        preventOpenDuplicates: true,
        positionClass: 'toast-top-center'
      });

    }

  })();
