(function () {
  'use strict';
  angular
    .module('app')
    .component('boxCallbackPage', {
      controller: BoxCallbackController,
      bindings: {},
      templateUrl: 'app/pages/box-callback-page/box-callback-page.component.html'
    });

  BoxCallbackController.$inject = [
    '$location',
    '$state',
    'boxAuthenticationService',
    'ssNotificationService'
  ];

  function BoxCallbackController(
    $location,
    $state,
    boxAuthenticationService,
    ssNotificationService
  ) {

    var me = this;

    me.$onInit = onInit;

    function onInit() {
      boxAuthenticationService.complete($location.search().accesstoken, $location.search().refreshtoken)
        .then(function () {
          ssNotificationService.add({
            type: 'success',
            msg: 'Your Box account is connected successfully',
            metadata: {
              keepAliveAfterTransition: true
            }
          });
          $state.go('app.accountSettings');
        });
    }

  }

})();
