(function () {
  'use strict';

  angular
    .module('app')
    .factory('subscriptionRestService', subscriptionRestService);

  subscriptionRestService.$inject = ['$http', 'host'];

  /* @ngInject */
  function subscriptionRestService($http, host) {
    var baseUrl = host + 'subscription';

    var service = {
      cancelUserPlan: cancelUserPlan,
      undoDontCancelUserPlan: undoDontCancelUserPlan,
      fetchCurrentUserSubscription: fetchCurrentUserSubscription
    };
    return service;

    function cancelUserPlan() {
      return $http.delete(baseUrl + "/user");
    }

    function undoDontCancelUserPlan() {
      return $http.post(baseUrl + "/user");
    }

    function fetchCurrentUserSubscription() {
      return $http.get(baseUrl + '/user')
        .then(function (response) {
          return response.data;
        });
    }

  }

})();

