/**
 * Created by Luiz on 31/05/2017.
 */
//We need to refactor this.
angular.module("app").directive("timeInput", function($timeout, $filter){
    return {
        restrict: 'E',
        scope: {
            ngModel: '=',
            showSaveCancel: '=',
            readOnly: '=',
            saveCallback: '=?',
            parameterSaveCallback: '=?',
            cancelCallback: '=?',
            maxFrame: '=?',
            frameSeparatorString: '@'
        },
        templateUrl: 'app/directives/time-input.directive.html',
        link: function(scope, element, attr, ngModelController) {
            scope.timeInputValue = "00000000";
            scope.lastChangedValue = angular.copy(scope.ngModel);
            try {
                if (scope.lastChangedValue) {
                    var frames = scope.lastChangedValue.frames;
                    var time = scope.lastChangedValue.time || '00:00:00';
                    var lastValueArr = time.split(":");
                    lastValueArr[0] = lastValueArr[0].length === 1 || lastValueArr[0].length == null ? "0" + lastValueArr[0] : lastValueArr[0];
                    lastValueArr[1] = lastValueArr[1].length === 1 || lastValueArr[1].length == null ? "0" + lastValueArr[1] : lastValueArr[1];
                    lastValueArr[2] = lastValueArr[2].length === 1 || lastValueArr[2].length == null ? "0" + lastValueArr[2] : lastValueArr[2];
                    lastValueArr[3] = frames.length === 1 || frames.length == null ? "0" + frames : frames;

                    scope.timeInputValue = lastValueArr.join("");
                }
            } catch(e) {
                console.log(e);
            }


            scope.$watch(function() {
              return scope.timeInputValue;
            }, function(newVal, oldVal){

              console.debug(newVal);

              if (newVal) {
                var p1 = newVal.substring(0, 2);
                var p2 = newVal.substring(2, 4);
                p2 = parseInt(p2) > 59 ? "59" : p2;
                var p3 = newVal.substring(4, 6);
                p3 = parseInt(p3) > 59 ? "59" : p3;

                var ff = newVal.substring(6, 8);
                ff = ff > parseFloat(scope.parameterSaveCallback.fps) ? Math.ceil(parseFloat(scope.parameterSaveCallback.fps)-1) : ff;

                var timestamp = p1+":"+p2+":"+p3;

                scope.ngModel.time = timestamp;
                scope.ngModel.frames = ff;

                scope.timeInputValue = p1+p2+p3+ff;
              } else {
                scope.ngModel.frames = "00";
                scope.ngModel.time = "00:00:00";
              }
            }, true);

            scope.edit = function() {
                scope.isEditing = true;
                scope.lastChangedValue = angular.copy(scope.ngModel);

                setTimeout(function() {
                    var input = $(element[0]).find("input")[0];
                    input.select();
                }, 100);
            };

            scope.ok = function() {
              if (!scope.timeInputForm.timeInputValue.$error.mask) {
                console.log(scope.parameterSaveCallback);
                scope.isEditing = false;
                if (scope.parameterSaveCallback) {
                  if (scope.saveCallback) scope.saveCallback(scope.parameterSaveCallback);
                } else {
                  if (scope.saveCallback) scope.saveCallback();
                }
              } else {
                alert("Please, fill timecode completely: HH:MM:SS;FF");
              }
            };

            scope.cancel = function() {
                scope.isEditing = false;
                scope.ngModel = angular.copy(scope.lastChangedValue);

                if (scope.parameterSaveCallback) {
                    if (scope.cancelCallback) scope.cancelCallback(scope.parameterSaveCallback);
                } else {
                    if (scope.cancelCallback) scope.cancelCallback();
                }
            };


            $timeout(function() {
                var input = $(element).find("input");

                input.on("keydown", function (e) {
                    if (e.keyCode == 13) {
                        console.log("ok");
                        scope.ok();

                        input.blur();
                    }
                });
            }, 100);

        }
    }
});
