(function () {
    'use strict';
    angular
        .module('app')
        .factory('blacklistWhitelistService', blacklistWhitelistService);
    blacklistWhitelistService.$inject = ['$http', 'host'];
    /* @ngInject */
    function blacklistWhitelistService($http, host) {
        var basePathUrl = host + 'admin';
        var service = {
            getAll: getAll,
            save: save,
            getOne: getOne,
            deleteReq: deleteReq
        };
        return service;
        ////////////////

        function getAll() {


            return $http.get(basePathUrl + '/blacklist-whitelist')
                .then(function (response) {
                    return response;
                });
        }

        function save(payload) {
            return $http.post(basePathUrl + '/blacklist-whitelist', payload);
        }

        function getOne(id) {
            return $http.get(basePathUrl + '/blacklist-whitelist/' + id)
                .then(function (response) {
                    return response.data;
                });
        }
        function deleteReq(payload) {
            return $http.post(basePathUrl + '/blacklist-whitelist/delete', payload);
        }

    }

})();

