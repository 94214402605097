(function () {
  'use strict';

  angular
    .module('app')
    .component('subscribePage', {
      controller: SubscribeController,
      bindings: {
        fromLanding: '<'
      },
      templateUrl: 'app/pages/subscribe-page/subscribe-page.component.html'
    });

  SubscribeController.$inject = [
    '$uibModal',
    'APP_CONFIG',
    'authService',
    'plansRestService',
    'ssNotificationService'
  ];

  function SubscribeController(
    $uibModal,
    APP_CONFIG,
    authService,
    plansRestService,
    ssNotificationService
  ) {

    var me = this;

    me.$onInit = onInit;
    me.startSubscribeFlow = startSubscribeFlow;
    me.login = login;

    function onInit() {
      me.logoLinkHref = APP_CONFIG.ROUTE_PREFIX;
      retrievePlans();
    }

    function login() {
      authService.login();
    }

    function retrievePlans() {
      plansRestService.getAll()
        .then(function (response) {
          me.planList = response;
        });
    }

    function startSubscribeFlow(plan) {
      var modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'subscribeToPlanModal',
        resolve: {
          planInfo: function () {
            return plan;
          }
        }
      });

      modalInstance.result
        .then(function (modalResponse) {
          ssNotificationService.add({
            type: 'success',
            msg: 'Thanks for subscribing to the ' + modalResponse.planName + ' plan. We\'re happy we will be seeing you around more often.',
            metadata: {
              keepAliveAfterTransition: true
            }
          });
        });
    }
  }

})();
