(function () {
  'use strict';

  angular
    .module('app')
    .component('adminMetricsPage', {
      bindings: {},
      controller: MetricsController,
      templateUrl: 'app/admin/pages/admin-metrics-page/admin-metrics-page.component.html'
    });

  MetricsController.$inject = [
    '$filter',
    '$state',
    'dateHelperService',
    'metricsRestService'
  ];

  function MetricsController(
    $filter,
    $state,
    dateHelperService,
    metricsRestService
  ) {
    var me = this;

    me.getMetrics = getMetrics;

    init();

    function init() {
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      var currentUtcDate = dateHelperService.toUTCDate(yesterday);
      var yesterdayUtcDate = dateHelperService.toUTCDate(today);
      me.startDate = $filter('date')(currentUtcDate, 'yyyy-MM-dd');
      me.endDate = $filter('date')(yesterdayUtcDate, 'yyyy-MM-dd');
      getMetrics();
    }

    function requestAndSetMetrics(queryParams) {
      metricsRestService.query(queryParams)
        .then(function (response) {
          if (!response) {
            $state.go("app.dashboard");
            return;
          }

          var transcribedInfoMetrics = _.filter(response, { type: 'transcribedMedia' });
          var createdProjectsMetrics = _.filter(response, { type: 'createdProject' });
          var exportedFileMetrics = _.filter(response, { type: 'exportedFile' });

          var exportedFileEventLogs = exportedFileMetrics.map(function (fileMetricItem) {
            return angular.fromJson(fileMetricItem.logData);
          });

          var exportedDocFiles = _.filter(exportedFileEventLogs, { fileType: 'doc' });
          var exportedCsvFiles = _.filter(exportedFileEventLogs, { fileType: 'csv' });
          var exportedSrtFiles = _.filter(exportedFileEventLogs, { fileType: 'srt' });
          var exportedXmlFiles = _.filter(exportedFileEventLogs, { fileType: 'xml' });
          var exportedEdlFiles = _.filter(exportedFileEventLogs, { fileType: 'edl' });
          var exportedFcpXmlFiles = _.filter(exportedFileEventLogs, { fileType: 'fcpXml' });
          var exportedTxtFiles = _.filter(exportedFileEventLogs, { fileType: 'txt' });
          var exportedAafFiles = _.filter(exportedFileEventLogs, { fileType: 'aaf' });
          var exportedAvidLocatorFiles = _.filter(exportedFileEventLogs, { fileType: 'avidLocator' });
          var exportedJsonFiles = _.filter(exportedFileEventLogs, { fileType: 'json' });

          me.transcribedFilesCount = transcribedInfoMetrics.length;
          me.createdProjectsCount = createdProjectsMetrics.length;
          me.exportedFilesCount = exportedFileMetrics.length;

          me.exportedDocFilesCount = exportedDocFiles.length;
          me.exportedCsvFilesCount = exportedCsvFiles.length;
          me.exportedSrtFilesCount = exportedSrtFiles.length;
          me.exportedXmlFilesCount = exportedXmlFiles.length;
          me.exportedEdlFilesCount = exportedEdlFiles.length;
          me.exportedFcpXmlFilesCount = exportedFcpXmlFiles.length;
          me.exportedTxtFilesCount = exportedTxtFiles.length;
          me.exportedAafFilesCount = exportedAafFiles.length;
          me.exportedAvidLocatorFiles = exportedAvidLocatorFiles.length;
          me.exportedJsonFiles = exportedJsonFiles.length;

          var accumulativeMediaTranscribed = transcribedInfoMetrics
            .map(function (transcribedInfoItem) {
              return angular.fromJson(transcribedInfoItem.logData);
            })
            .reduce(function (a, b) {
              return { duration: a.duration + b.duration }
            }, { duration: 0 });

          me.minutesTranscribed = accumulativeMediaTranscribed.duration / 60;
        })
        .catch(function (e) {
          console.log(e);
          $state.go("app.dashboard");
        })
    }


    function getMetrics() {
      var queryParams = {};
      if (me.startDate) {
        queryParams.starUtcTime = new Date(me.startDate).toISOString().slice(0, -1);
      }
      if (me.endDate) {
        queryParams.endUtcTime = new Date(me.endDate).toISOString().slice(0, -1);;
      }
      requestAndSetMetrics(queryParams);
    }
  }

})();
