(function () {
  'use strict';

  angular
    .module('app')
    .component('adminUserListPage', {
      bindings: {},
      templateUrl: 'app/admin/pages/admin-user-list-page/admin-user-list-page.component.html',
      controller: AdminUserListController
    })

  AdminUserListController.$inject = ['$state', 'adminUserRestService'];

  function AdminUserListController($state, adminUserRestService) {
    var me = this;

    me.$onInit = onInit;
    me.updateFilteredList = updateFilteredList;
    me.goToUserDetailPage = goToUserDetailPage;
    me.getUserDisplayName = getUserDisplayName;
    me.querySearch = querySearch;

    function onInit() {
      me.searchText = null;
      me.filterOptions = [
        'recentlyActive',
        'recentlyJoined',
      ];

      me.filterType = me.filterOptions[0];
      getRecentActiveUsers();
    }

    function getRecentActiveUsers() {
      adminUserRestService.getRecentActiveUsers(50)
        .then(function (response) {
          me.users = response;
        })
    }

    function getRecentJoinedUsers() {
      adminUserRestService.getRecentJoinedUsers(50)
        .then(function (response) {
          me.users = response;
        })
    }

    function querySearch(searchText) {
      if (!searchText) {
        return;
      }

      adminUserRestService.getAllThatMatchesKeyword(searchText)
        .then(function (response) {
           me.userMatches = response;
           return response;
        })
    }

    function updateFilteredList() {
      if (me.filterType === me.filterOptions[0]) {
        getRecentActiveUsers();
      } else {
        getRecentJoinedUsers();
      }
    }

    function goToUserDetailPage(userId) {
      if (userId) {
        $state.go('app.admin.userManagementItem', {userId: userId} );
      }
    }

    function getUserDisplayName(item) {
      return item.fullName + " (" + item.email + ")";
    }
  }

})();
