(function () {
  'use strict';

  angular
    .module('app')
    .component('adminTeamListPage', {
      controller: AdminTeamListController,
      bindings: {},
      templateUrl: 'app/admin/pages/admin-team-list-page/admin-team-list-page.component.html'
    });

  AdminTeamListController.$inject = ['adminTeamRestService'];

  function AdminTeamListController(adminTeamRestService) {

    var me = this;

    me.$onInit = onInit;

    function onInit() {
      adminTeamRestService.getTeams()
          .then(function (response) {
              me.teamList = response;
          });
    }

  }

})();
