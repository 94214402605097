(function () {
  'use strict';

  angular
    .module('app')
    .controller('mergeProjectController', MergeProjectController);

  MergeProjectController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'detailedTransactionService',
    'mergeProjectService',
    'timecodeHelperService'
  ];

  function MergeProjectController(
    $scope,
    $state,
    $stateParams,
    detailedTransactionService,
    mergeProjectService,
    timecodeHelperService
  ) {
    $scope.projectId = $stateParams.projectId;
    $scope.userId = $stateParams.userId;
    var duration;
    var temp = [];
    function init() {
      $scope.selectedList = {};
      mergeProjectService.loadSourceProjects($scope.projectId, $scope.userId)
        .then(function success(response) {
          $scope.sourceProjectListing = response;
        }, function error(error) {
          $scope.resource = error;
        });

      detailedTransactionService.getProject($scope.projectId)
        .then(function successCallback(response) {
          $scope.project = response;
          $scope.files = response.files;
          for (var i = 0; i < $scope.files.length; i++) {
            var t = $scope.files[i];
            t.fps = String(t.fps);

            t.mediaTimecode = timecodeHelperService.getMediaTimeCode(t);

            duration = $scope.files[i].duration;
            t.duration_ = toHHMMSSFormat(duration);
            temp.push(t);
          }
          $scope.files = temp;

          $scope.projectName = response.name;
        });
    }

    init();

    $scope.userDetailedView = function () {
      $state.go('app.admin.userManagementItem', { userId: $scope.userId });
    };
    $scope.getFrameSeparatorString = function (media) {
      var frameSeparatorString = ';';

      if (media && (media.fps % 1 === 0 || media.fps === 23.976)) {
        frameSeparatorString = ':';
      }
      return frameSeparatorString;
    };
    var toHHMMSSFormat = function (duration) {
      var sec_num = parseInt(duration, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return hours + ':' + minutes + ':' + seconds;
    };
    $scope.checkIteams = function () {
      var sourceProject = [];
      angular.forEach($scope.selectedList, function (selected, sourceProjectIds) {
        if (selected) {
          sourceProject.push(sourceProjectIds);
        }
      });

      var mergeProjectPayload = {
        target: $scope.projectId,
        'source-projects': sourceProject
      };

      mergeProjectService.mergeProjectRequest(mergeProjectPayload)
        .then(function success(resource) {
          init();
        }, function error(error) {
          $scope.resource = error;
        });
    };
  }
})();
