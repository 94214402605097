(function () {
    'use strict';

    angular
        .module('app')
        .factory('timecodeHelperService', timecodeHelperService);

    timecodeHelperService.$inject = [];

    /* @ngInject */
    function timecodeHelperService() {
        return {
            getMediaTimeCode: getMediaTimeCode
        };

        function getMediaTimeCode(media) {
            var mediaTimecode = {time: null, frames: null};

            if (media.userDefinedTimeCode || media.userDefinedTimeCodeFrames) {
                if (media.userDefinedTimeCode) {
                    var momObj = moment.duration(media.userDefinedTimeCode);

                    var hours = momObj._data.hours;
                    if (momObj._data.days) {
                        hours += 24 * momObj._data.days;
                    }

                    mediaTimecode.time = hours + ':' + momObj._data.minutes + ':' + momObj._data.seconds;
                }

                if (media.userDefinedTimeCodeFrames) {
                    mediaTimecode.frames = String(media.userDefinedTimeCodeFrames);
                }
            } else if (media.timeCode) {
                mediaTimecode.time = media.timeCode.substring(0, 8);
                mediaTimecode.frames = media.timeCode.substring(9, 11);
            }

            if (!mediaTimecode.time) {
                mediaTimecode.time = '00:00:00';
            }

            if (!mediaTimecode.frames) {
                mediaTimecode.frames = '00';
            }

            return mediaTimecode;
        }
    }
})();

