(function () {
    'use strict';

    angular
        .module('app')
        .controller('detailedTransactionController', DetailedTransactionController);

    DetailedTransactionController.$inject = [
        '$window',
        '$scope',
        '$state',
        '$location',
        '$stateParams',
        'detailedTransactionService',
        'sharedProjectsService',
        'timecodeHelperService'
    ];

    function DetailedTransactionController(
        $window,
        $scope,
        $state,
        $location,
        $stateParams,
        detailedTransactionService,
        sharedProjectsService,
        timecodeHelperService
    ) {
        var temp = [];
        var duration;
        $scope.projectId = $stateParams.projectId;
        $scope.project = null;

        detailedTransactionService.getProject($scope.projectId)
                .then(function successCallback(response) {
                    $scope.project = response;
                    $scope.project.failedMediaEventLogs = response.failedMediaEventLogs.map(function (eventLog) {
                      return {
                        logData: angular.fromJson(eventLog.logData),
                        timestampInUtc: eventLog.timestampInUtc,
                      };
                    });

                    $scope.files = response.files;
                    for (var i = 0; i < $scope.files.length; i++) {
                        var t = $scope.files[i];
                        t.fps = String(t.fps);

                        t.mediaTimecode = timecodeHelperService.getMediaTimeCode(t);

                        duration = $scope.files[i].duration;
                        t.duration_ = toHHMMSSFormat(duration);
                        temp.push(t);
                    }
                    $scope.files = temp;
                    getPublicProjectLinkFromProject();
                });

        $scope.userDetailedView = function () {
            $state.go('app.admin.userManagementItem', {userId: $scope.project.userId});
        };
        $scope.getFrameSeparatorString = function (media) {
            var frameSeparatorString = ';';

            if (media && (media.fps % 1 === 0 || media.fps === 23.976)) {
                frameSeparatorString = ':';
            }
            return frameSeparatorString;
        };
        $scope.loadProjectDetailView = function () {
            $state.go('app.admin.projectManagementItem' , {projectId: $scope.projectId});
        };

        $scope.loadOriginalProjectDetailView = function () {
            $state.go('app.admin.projectManagementItem' + {projectId: $scope.project.translatedFromProjectId});
        };

        $scope.openNewTab = function (url) {
            $window.open(url, '_blank');
        };
        var toHHMMSSFormat = function (duration) {
            var sec_num = parseInt(duration, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = '0' + hours;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            return hours + ':' + minutes + ':' + seconds;
        };

        function getPublicProjectLinkFromProject() {
          return sharedProjectsService.getProjectPublicLink($scope.project);
        }
    }
})();
