(function () {
    'use strict';

    angular
        .module('app')
        .factory('planInfoService', planInfoService);

    planInfoService.$inject = [];

    /* @ngInject */
    function planInfoService() {
        return {
            getLowestPricePerHour: getLowestPricePerHour,
            getPlanInfo: getPlanInfo,
            getPlanPricePerHour: getPlanPricePerHour
        };

        ////////////////

        function getLowestPricePerHour(planList) {
          if (!planList) {
            return;
          }
          const lowestPlan = planList
            .filter(function (plan) {
              return plan.active && plan.pricePerHour;
            })
            .reduce(function(prev, current){
              return (prev.pricePerHour < current.pricePerHour) ? prev : current;
            } , {pricePerHour: Number.MAX_SAFE_INTEGER});

          if (lowestPlan) {
            return lowestPlan.pricePerHour;
          }
        }

        function getPlanInfo(planName, planList) {
            return _.find(planList, {planEnum: planName});
        }

        function getPlanPricePerHour(planName, planList) {
            var plan = getPlanInfo(planName, planList);
            if (plan) {
                return plan.pricePerHour;
            }
        }

    }

})();

