(function () {
  'use strict';
  angular
    .module('app')
    .component('noFoundOops', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/components/no-found-oops/no-found-oops.component.html'
    });

})();
