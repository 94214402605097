(function () {
  angular
    .module('app')
    .component('pricingPage', {
      controller: PricingPageController,
      bindings: {
        planList: '<',
        user: '<',
        onSubscribe: '&'
      },
      templateUrl: 'app/pages/pricing-page/pricing-page.component.html'
    });

    PricingPageController.$inject = ['$filter', '$window', 'PLANS', 'authService', 'planInfoService'];

    function PricingPageController($filter, $window, PLANS, authService, planInfoService) {
      var me = this;

      me.$onInit = onInit;
      me.$onChanges = onChanges;
      me.Math = $window.Math;
      me.buildPlanListToDisplay = buildPlanListToDisplay;
      me.getSubscriptionPricePerMonthLabel = getSubscriptionPricePerMonthLabel;
      me.getMaxDiscountInAnnual = getMaxDiscountInAnnual;
      me.isSubscribeButtonAvailable = isSubscribeButtonAvailable;
      me.isCurrentUserPlan = isCurrentUserPlan;
      me.getSubscriptionOperationLabel = getSubscriptionOperationLabel;
      me.isBasicPlan = isBasicPlan;
      me.getDiscountForAnnualLabel = getDiscountForAnnualLabel;
      me.goToLoginPage = goToLoginPage;

      function onInit() {
        me.planList = me.planList || [];
        me.planListToDisplay = [];

        me.PEOPLE_TYPES = PLANS.PEOPLE_TYPES;
        me.INTERVAL_TYPES = PLANS.INTERVAL_TYPES;
        me.isAnnualIntervalSelected = me.user ? me.user.plan.interval === PLANS.INTERVAL_TYPES.YEAR : true;
        me.lowestPricePerHour = planInfoService.getLowestPricePerHour(me.planList);
        buildPlanListToDisplay();
      }

      function onChanges(changes) {
        if (changes.planList && changes.planList.currentValue) {
          me.lowestPricePerHour = planInfoService.getLowestPricePerHour(me.planList);
          buildPlanListToDisplay();
        }
      }

      function goToLoginPage() {
        authService.login();
      }

      function buildPlanListToDisplay() {
        me.planListToDisplay = me.planList.filter(function (plan) {
          return plan.active && checkInterval(plan);
        });
      }

      function getSubscriptionPricePerMonthLabel(plan) {
        if (plan && plan.subscriptionCharge) {
          var chargePerMonth =  me.isAnnualIntervalSelected ? (plan.subscriptionCharge / 12) : plan.subscriptionCharge;
          return '$' + chargePerMonth + '/mo';
        }
        return me.isAnnualIntervalSelected ? 'No annual cost' : 'No monthly cost';
      }

      function calculateAnnualDiscount(monthlyPlan, annualPlan) {
        return 100 * (monthlyPlan.subscriptionCharge - annualPlan.subscriptionCharge / 12) / monthlyPlan.subscriptionCharge;
      }

      function getDiscountForAnnual(annualPlan) {
        if (annualPlan.interval === PLANS.INTERVAL_TYPES.YEAR) {
          var monthlyVersionOfPlan = me.planList.find(function (planItem) {
            return planItem.active && planItem.name === annualPlan.name && planItem.interval === PLANS.INTERVAL_TYPES.MONTH;
          });

          if (monthlyVersionOfPlan) {
            return calculateAnnualDiscount(monthlyVersionOfPlan, annualPlan);
          }
        }
      }

      function getDiscountForAnnualLabel(plan) {
        var discount = getDiscountForAnnual(plan);
        if (discount && !Number.isNaN(discount)) {
          return '(save ' + $filter('number')(discount, 0) + '%)';
        }
        return '';
      }

      function getMaxDiscountInAnnual() {
        return _.chain(me.planList)
        .filter(function (plan) {
          return plan.active && plan.interval === PLANS.INTERVAL_TYPES.YEAR;
        })
        .map(function (annualPlan) {
          var discount = getDiscountForAnnual(annualPlan);
          if (discount && !Number.isNaN(discount)) {
            return Math.round(discount);
          }
        })
        .max()
        .value();
      }

      function checkInterval(plan) {
        return !plan.interval || (plan.interval === (me.isAnnualIntervalSelected ? PLANS.INTERVAL_TYPES.YEAR : PLANS.INTERVAL_TYPES.MONTH));
      }

      function isSubscribeButtonAvailable(plan) {
        return me.user &&
               plan.subscriptionCharge &&
               getSubscriptionOperationLabel(plan);
      }

      function getSubscriptionOperationLabel(plan) {
        if (me.user && me.user.plan.upgradeOptions && me.user.plan.upgradeOptions.indexOf(plan.planEnum) !== -1) {
          return 'Upgrade';
        }
        if (me.user && me.user.plan.downgradeOptions && me.user.plan.downgradeOptions.indexOf(plan.planEnum) !== -1) {
          return 'Downgrade';
        }
      }

      function isCurrentUserPlan(plan) {
        return me.user.plan.planEnum === plan.planEnum
      }


      function isBasicPlan(plan) {
        return plan.planEnum === PLANS.NO_SUBSCRIPTION_PLAN;
      }

    }

})();
