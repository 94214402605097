(function () {
  'use strict';

  angular
    .module('app')
    .filter('estTime', estTime);

  estTime.$inject = ['$filter'];
  function estTime($filter) {
    return estTimeFilter;

    function estTimeFilter(date) {
      return $filter('date')(date, 'short', '-0400');
    }
  }

})();

