(function () {
    'use strict';
    angular
        .module('app')
        .factory('publishedProjectsRestService', publishedProjectsRestService);

    publishedProjectsRestService.$inject = ['host', '$http'];

    function publishedProjectsRestService(host, $http) {
        var baseUrl = host + 'published-projects';

        return {
            getAll: getAll,
            getAnnotations: getAnnotations,
            updateAnnotation: updateAnnotation,
            removeAnnotation: removeAnnotation
        };

        function getAll() {
            return $http.get(baseUrl)
                .then(function (response) {
                    return response.data;
                });
        }

        function getAnnotations() {
            return $http.get(baseUrl + '/annotations')
                .then(function (response) {
                    return response.data;
                });
        }

        function updateAnnotation(annotation) {
            return $http.put(baseUrl + '/annotations/' + annotation.transcriptionId, annotation)
                .then(function (response) {
                    return response.data;
                });
        }

        function removeAnnotation(annotation) {
            return $http.delete(baseUrl + '/annotations/' + annotation.transcriptionId);
        }
    }
})();
