(function () {
  'user strict';
  angular
    .module('app')
    .factory('httpErrorHandler', httpErrorHandler);

  httpErrorHandler.$inject = ['$injector', 'authService'];

  function httpErrorHandler($injector, authService) {
    return {
      manageHttpCode: manageHttpCode
    };

    function manageHttpCode(httpStatusCode, errorMessage, config) {

      var ssNotificationService = $injector.get('ssNotificationService');
      var cfpLoadingBar = $injector.get('cfpLoadingBar');

      if (httpStatusCode === 401) {
        authService.logout();
        return true;
      }

      if (httpStatusCode === 403) {
        var $state = $injector.get('$state');

        ssNotificationService.add({
          type: 'danger',
          msg: errorMessage || 'You are not allowed to access this resource.',
          metadata: {
            keepAliveAfterTransition: true
          }
        });

        cfpLoadingBar.complete()
        $state.go('app.dashboard');
        return true;
      }
    }
  }

})();
