(function () {
  'use strict';

  angular
    .module('app')
    .service('WebSocketConnectionService', WebSocketConnectionService);

  WebSocketConnectionService.$inject = ['$interval', '$timeout', 'host'];

  function WebSocketConnectionService($interval, $timeout, host) {
    var stompClient = null;

    var subscribeQueue = {};
    var subscriptions = {};

    var createTopicId = function (topic, id) {
      return topic + '_' + id;
    };

    var createChannelId = function () {
      var args = Array.prototype.slice.call(arguments);
      return '/' + args.join('/');
    };

    var subscribeTopic = function (topic, id, callback) {
      var topicId = createTopicId(topic, id);
      var topicChannelId = createChannelId('topic', topic, id);

      subscriptions[topicId] = stompClient.subscribe(topicChannelId,
        function onSubscribeSuccessful(response) {
          $timeout(function () {
            callback(JSON.parse(response.body));
          });
        });

      // console.debug('Subscribed to ', topicChannelId);
    };

    var unsubscribeTopic = function (topic, id) {
      var topicId = createTopicId(topic, id);

      if (subscriptions[topicId]) {
        subscriptions[topicId].unsubscribe();
      }

      if (subscribeQueue[topicId]) {
        delete subscribeQueue[topicId];
      }
    };

    function createSocket() {
      return new SockJS(host + 'simonsocket');
    }

    var retryConnectionTimer;

    var WebSocketConnection = {
      connected: false,
      connecting: false,
      connect: function () {

        if (!this.connecting) {
          this.connecting = true;
          var self = this;

          var socket = createSocket();
          stompClient = Stomp.over(socket);

          //IMPORTANT: ENABLE DEBUGGING BY COMMENTING THIS
          stompClient.debug = null;

          function onConnect() {
            // console.log('onConnect')
            self.connected = true;
            $interval.cancel(retryConnectionTimer);

            if (!_.isEmpty(subscribeQueue)) {
              angular.forEach(subscribeQueue, function (func, topicId) {
                console.log('re-subscribing to: ', topicId)
                func();
              });
            }
          }

          function onDisconnect(message) {
            console.log(message);
            self.connected = false;
            self.connecting = false;

            retryConnectionTimer = $interval(function () {
              if (!self.connected && !self.connecting) {
                socket = createSocket();
                stompClient = Stomp.over(socket);
                stompClient.debug = null;
                console.log("Trying to reconnect");
                stompClient.connect({}, onConnect);
              }
            }, 5000);

          }

          stompClient.connect({}, onConnect, onDisconnect);
        }
      },
      subscribe: function (topic, id, callback) {
        if (this.connected) {
          subscribeTopic(topic, id, callback);
        }

        if (!subscribeQueue[createTopicId(topic, id)]) {
          subscribeQueue[createTopicId(topic, id)] = function () {
            subscribeTopic(topic, id, callback);
          };
        }

      },
      unsubscribe: function (topic, id) {
        if (this.connected) {
          unsubscribeTopic(topic, id);
        }
      },
      disconnect: function () {
        if (stompClient != null) {
          stompClient.disconnect();
          this.connected = false;
          this.connecting = false;
        }
      }
    };

    WebSocketConnection.connect();

    return WebSocketConnection;
  }

})();
