(function () {
  'use strict';

  angular
    .module('app')
    .component('mergingTracksModal', {
      controller: MergingTracksModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/components/merging-tracks-modal/merging-tracks-modal.component.html'
    });

  MergingTracksModalController.$inject = ['$location', '$uibModal', 'projectService', 'usSpinnerService'];

  function MergingTracksModalController($location, $uibModal, projectService, usSpinnerService) {
    var me = this;

    me.$onInit = onInit;
    me.ok = ok;
    me.cancel = cancel;
    me.isMergeButtonEnabled = isMergeButtonEnabled;

    function onInit() {
      me.mergeErrorMessage = '';
      listFiles();
      hideLoadingIndicator();
    }

    function ok() {
      if (isMergeButtonEnabled()) {
        showLoadingIndicator();

        var mergeTracksRequest = buildMergeTracksRequest();

        projectService.mergeAudioTracks(me.resolve.project.id, mergeTracksRequest)
          .then(function (response) {
            hideLoadingIndicator();
            me.mergeErrorMessage = '';
            me.close();
            $location.path('/projects/' + response.id);
          })
          .catch(function (reason) {
            console.error(reason)
            hideLoadingIndicator();
            me.mergeErrorMessage = reason;
          });
      }
    }

    function cancel() {
      me.dismiss();
    }

    function buildMergeTracksRequest() {
      var combinedFile = me.files.find(function (file) {
        return file.type === 'combined';
      });

      var trackFiles = me.files.filter(function (file) {
        return file.type === 'track';
      });

      return {
        combinedTrackId: combinedFile.id,
        channelTracks: trackFiles.map(function (file) {
          return {
            id: file.id,
            speakerLabel: file.speakerLabel
          }
        })
      };
    }

    function listFiles() {
      me.files = me.resolve.project.medias.map(function (media) {
        return {
          id: media.id,
          name: media.name,
          type: '',
          speakerLabel: ''
        };
      });
    }

    function isMergeButtonEnabled() {
      return true;
    }

    function hideLoadingIndicator() {
      me.isRequestInProcess = false;
      usSpinnerService.stop('spinner-1');
    }

    function showLoadingIndicator() {
      me.isRequestInProcess = true;
      usSpinnerService.spin('spinner-1');
    }
  }

})();
