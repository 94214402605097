(function () {
    'use strict';

    angular
        .module('app')
        .factory('plansRestService', plansRestService);

    plansRestService.$inject = ['$http', 'host'];

    /* @ngInject */
    function plansRestService($http, host) {
        var basePathUrl = host + 'plans';

        return {
            getAll: getAll
        };

        ////////////////

        function getAll() {
            return $http.get(basePathUrl)
                .then(function (response) {
                    return response.data;
                });
        }
    }

})();

