(function () {
    'use strict';
    angular
        .module('app')
        .service('speakerRestService', speakerRestService);

    speakerRestService.$inject = ['$http', 'host'];
    function speakerRestService($http, host) {
        return {
            add: add,
            update: update,
            remove: remove,
        };

        function add(speaker, mediaId) {
            var resourceUrl = host + 'media/' + mediaId + '/speaker';
            return $http.post(resourceUrl, speaker)
                .then(function (response) {
                    return response.data;
                });
        }

        function update(speaker, mediaId) {
            var resourceUrl = host + 'media/' + mediaId + '/speaker/' + speaker.id;
            return $http.put(resourceUrl, speaker)
                .then(function (response) {
                    return response.data;
                });
        }

        function remove(speaker, mediaId) {
            var resourceUrl = host + 'media/' + mediaId + '/speaker/' + speaker.id;
            return $http.delete(resourceUrl)
                .then(function () {
                    return null;
                });
        }
    }
})();
