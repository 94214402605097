/**
 * Created by Luiz on 20/02/2017.
 */
angular.module("app").filter('timeToSeconds', function() {

    return function(time) {
        //i.e. 00:00:00
        var totalSeconds = 0;
        var commaIndex = time.indexOf(",");
        var decimals = 0;
        if (commaIndex >= 0) {
            // decimals = parseFloat("0."+time.substring(commaIndex+1));
            time = time.substring(0, commaIndex);
        }

        var timeArr = time.split(":");

        for (var i = 0; i < timeArr.length; i++) {
            var currentTime = parseInt(timeArr[i]);

            var counter = timeArr.length-i-1;
            while (counter > 0) {
                currentTime *= 60;
                counter--;
            }

            totalSeconds += currentTime;
        }

        return totalSeconds;
    };
});