(function () {
  'use strict';

  angular
    .module('app')
    .run(appRun);

  appRun.$inject = [
    '$location',
    '$rootScope',
    '$transitions',
    '$window',
    '$q',
    'authService',
    'MediaService',
    'ssNotificationService',
    'usSpinnerService'
  ];

  function appRun(
    $location,
    $rootScope,
    $transitions,
    $window,
    $q,
    authService,
    MediaService,
    ssNotificationService,
    usSpinnerService
  ) {
    //Move to a local function?
    var checkAndRenewToken = function() {
      if (authService.isAuthenticated()) {
        //console.log('renewTokens!');
        authService.renewTokens();
      } else {
        // Handle the authentication
        var query = window.location.search.substring(1);
        if(!query.includes('callback') && query.includes('sharing')){
          window.location.replace("/signin");
        }
        // result in the hash
        console.log('handleAuthentication');
        authService.handleAuthentication();
      }
    }
    checkAndRenewToken();
    //renew every hour
    setInterval(checkAndRenewToken, 60*60*1000);

    $transitions.onSuccess({}, function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      $window.Intercom("update");

      ssNotificationService.getAll().forEach(function (notification) {
        if (!notification.metadata || !notification.metadata.keepAliveAfterTransition) {
          ssNotificationService.remove(notification.id);
        }
      })

    });

    $transitions.onStart({}, function (trans) {
      var from = trans.from();
      var to = trans.to();

      if (to.name !== from.name && from.name === 'app.projectTranscript') {

        var mediaId = $location.search().fileId;
        console.log(mediaId);
        if (mediaId) {
          usSpinnerService.spin('spinner-saving-transcript');

          $rootScope.$broadcast('ps4.forceSyncSave', mediaId);

          var deferred = $q.defer();

          setTimeout(function () {
            usSpinnerService.stop('spinner-saving-transcript');
            deferred.resolve();
          }, 2000);

          return deferred.promise;
        }

      }

    });

    $transitions.onError({}, function (transition) {
      console.log(transition.error());
    });

    MediaService.startListeningUploadEvents();

  }
})();
