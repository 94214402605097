(function () {
    'use strict';
    angular
        .module('app')
        .provider('customEcommerceAnalytics', customEcommerceAnalyticsProvider);

    function customEcommerceAnalyticsProvider() {
        var ecommerceOn = false;

        this.enableEcommerce = function (value) {
            ecommerceOn = value;
        };

        this.$get = customEcommerceAnalyticsService;

        customEcommerceAnalyticsService.$inject = [];

        function customEcommerceAnalyticsService() {
            return {
                addTransaction: addTransaction,
                addItem: addItem,
                trackTrans: trackTrans,
                clearTrans: clearTrans
            };

            function addTransaction(transaction) {
                if (!ecommerceOn) {
                    return;
                }
                ga('require', 'ecommerce');
                ga('ecommerce:addTransaction', transaction);
            }

            function addItem(item) {
                if (!ecommerceOn) {
                    return;
                }
                ga('require', 'ecommerce');
                ga('ecommerce:addItem', item);
            }

            function trackTrans() {
                if (!ecommerceOn) {
                    return;
                }
                ga('ecommerce:send');
            }

            function clearTrans() {
                ga('ecommerce:clear');
            }
        }
    }

})();
