angular.module('app')
        .directive('creditCard', function () {
            return {
                restrict: 'E',
                scope: {
                    displaySaveCardOption: '=?',
                    useSavedCard: '=',
                    submitFn: '&',
                    chargeableTime: '<',
                    isSubscribing: '<',
                    disabledSubmitButton: '<',
                    action: '<',
                    paymentMode: '<'
                },
                controller: ['$filter', '$scope', '$rootScope', function ($filter, $scope, $rootScope) {
                        var getUser = function () {
                            return $rootScope.user || {};
                        };

                        var CREDIT_CARD_TYPES = [
                            {name: 'americanExpress', length: 15, beginWithNumber: 3, pattern: '9999 999999 99999', cvcPattern: '9999'},
                            {name: 'visa', length: 16, beginWithNumber: 4, pattern: '9999 9999 9999 9999', cvcPattern: '999'},
                            {name: 'masterCard', length: 16, beginWithNumber: 5, pattern: '9999 9999 9999 9999', cvcPattern: '999'}
                        ];

                        $scope.DEFAULT_CARD_DIGITS_PATTERN = '9999 9999 9999 9999';
                        $scope.DEFAULT_CVC_PATTERN = '999';

                        // set defaults
                        if ($scope.action != 'updateCard' && getUser().cardDigits) {
                            $scope.useSavedCard = true;
                        }
                        $scope.shouldSaveCard = false;
                        $scope.isSubscribing = false;
                        $scope.minYear = new Date().getFullYear().toString().substr(-2);
                        $scope.uiMaskCardNumberOptions = {
                            allowInvalidValue: true,
                            maskDefinitions: {
                                '*': new RegExp('^[3-5]')
                            }
                        };

                        $scope.getCardType = function () {
                            if (!$scope.cardNumber) {
                                return;
                            }

                            var firstDigitCardString = $scope.cardNumber.charAt(0);
                            var firstDigitCardNumber = firstDigitCardString ? parseInt(firstDigitCardString, 10) : null;
                            if (firstDigitCardNumber) {
                                return $filter('filter')(CREDIT_CARD_TYPES, {beginWithNumber: firstDigitCardNumber})[0];
                            }
                        };

                        $scope.hasSavedCard = function () {
                            return (getUser().cardDigits || getUser().subscriptionCardDigits);
                        };

                        $scope.getCardLastDigits = function () {
                            return $scope.paymentMode == 'subscribe' ? getUser().subscriptionCardDigits : getUser().cardDigits;
                        };

                        $scope.hasError = function (field) {
                            return ($scope.creditCardForm[field].$dirty && $scope.creditCardForm[field].$invalid) ||
                                    ($scope.creditCardForm.$submitted && $scope.creditCardForm[field].$invalid);
                        };

                        $scope.onSubmit = function () {
                            if ($scope.creditCardForm.$valid || $scope.useSavedCard || !$scope.chargeableTime) {

                                var card = {
                                    cardHolderName: $scope.cardHolderName,
                                    cardNumber: $scope.cardNumber,
                                    expirationMonth: $scope.expirationMonth,
                                    expirationYear: $scope.expirationYear,
                                    cvv: $scope.cvv
                                };
                                $scope.submitFn({
                                    useSavedCard: $scope.useSavedCard,
                                    shouldSaveCard: $scope.shouldSaveCard,
                                    card: card
                                });
                            }
                        };
                    }],
                templateUrl: 'app/directives/credit-card/credit-card.html'
            };
        });
