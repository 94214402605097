(function () {
    'use strict';

    angular
        .module('app')
        .controller('projectDetailViewController', ProjectDetailViewController);

    ProjectDetailViewController.$inject = [
        '$window',
        '$scope',
        '$http',
        '$location',
        '$state',
        '$stateParams',
        '$route',
        'host',
        'detailedTransactionService',
        'adminUserRestService',
        '$uibModal',
        'projectAdminService',
        'sharedProjectsRestService',
        'ssNotificationService',
        'sharedProjectsService',
        'adminTeamRestService',
        'timecodeHelperService'
    ];

    function ProjectDetailViewController(
        $window,
        $scope,
        $http,
        $location,
        $state,
        $stateParams,
        $route,
        host,
        detailedTransactionService,
        adminUserRestService,
        $uibModal,
        projectAdminService,
        sharedProjectsRestService,
        ssNotificationService,
        sharedProjectsService,
        adminTeamRestService,
        timecodeHelperService
    ) {
        $scope.projectId = $stateParams.projectId;
        var temp = [];
        var duration;
        $scope.retryJobFlag = false;
        $scope.frameRateOptions = ['23.976', '24', '25', '29.97', '30', '50', '59.94', '60'];
        $scope.project = null;
        $scope.selectedUserItem = null;
        $scope.teamdetails = [];

        detailedTransactionService.getProject($scope.projectId)
            .then(function successCallback(response) {
                $scope.project = response;
                $scope.project.failedMediaEventLogs = response.failedMediaEventLogs.map(function (eventLog) {
                  return {
                    logData: angular.fromJson(eventLog.logData),
                    timestampInUtc: eventLog.timestampInUtc,
                  };
                });

                $scope.projectOwnerEmail = $scope.project.owner.email;

                $scope.selectedUserItem = $scope.project.owner;
                $scope.files = response.files;

                $scope.projectName = response.name;
                $scope.project.publishedUrl = getPublicProjectLinkFromProject();

                for (var i = 0; i < $scope.files.length; i++) {
                    var t = $scope.files[i];
                    if ($scope.project.status == "failed" && t.status == "failed") {
                        $scope.retryJobFlag = true;
                    }
                    t.fps = String(t.fps);

                    t.mediaTimecode = timecodeHelperService.getMediaTimeCode(t);

                    duration = $scope.files[i].duration;
                    t.duration_ = toHHMMSSFormat(duration);
                    temp.push(t);
                }
                $scope.files = temp;
            });

        $scope.autoCompleteOptions = function (searchText) {
            var results = searchText ? $scope.usersListing.filter(createFilterFor(searchText)) : $scope.usersListing;
            return results;
        };
        $scope.deleteProject = function (projectId) {
            var confirm = $window.confirm('Are you sure you want to delete this project?');
            if (confirm) {
                $http.get(host + "projects/remove/" + projectId).then(function () {
                    ssNotificationService.add({
                        type: 'success',
                        msg: 'Project have been deleted successfully.',
                        metadata: {
                            keepAliveAfterTransition: true
                        }
                    });
                    latestTransaction();


                }, function (error) {

                });
            }
        }
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(item) {
                return ((angular.lowercase(item.fullName)).indexOf(lowercaseQuery) === 0
                    || (angular.lowercase(item.email)).indexOf(lowercaseQuery) === 0);
            };
        }
        $scope.getName = function (item) {
            return item.fullName + " (" + item.email + ")";
        }
        adminUserRestService.getAll()
            .then(function (response) {
                $scope.usersListing = response;
            });
        $scope.getFrameSeparatorString = function (media) {
            var frameSeparatorString = ';';

            if (media && (media.fps % 1 === 0 || media.fps === 23.976)) {
                frameSeparatorString = ':';
            }
            return frameSeparatorString;
        };
        $scope.openNewTab = function (url) {
            $window.open(url, '_blank');
        };
        $scope.updateMediaDetails = function (file) {
            if (file.mediaTimecode.time) {
                var time = file.mediaTimecode.time;

                file.userDefinedTimeCode = moment.duration(time)._milliseconds;
                file.userDefinedTimeCodeFrames = parseInt(file.mediaTimecode.frames, 10);
            }
            projectAdminService.updateMedia(file.id, file)
                .then(function successCallback(response) {
                    $route.reload();
                }, function errorCallback(response) {
                });
        };
        $scope.deleteMediaFile = function (file) {
            var confirm = $window.confirm('Are you sure you want to delete this media?');
            if (confirm) {
                projectAdminService.deleteMediaFile(file.id)
                    .then(function successCallback(response) {
                        ssNotificationService.add({
                            type: 'success',
                            msg: 'File Deleted Successfully.',
                            metadata: {
                                keepAliveAfterTransition: true
                            }
                        });
                        $route.reload();
                    }, function errorCallback(response) {
                        ssNotificationService.add({
                            type: 'danger',
                            msg: 'Error processing your request.',
                            metadata: {
                                keepAliveAfterTransition: true
                            }
                        });
                    });
            }
        };
        adminTeamRestService.getTeams()
            .then(function (response) {
                $scope.teamdetails = response.data;
            });
        var toHHMMSSFormat = function (duration) {
            var sec_num = parseInt(duration, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = '0' + hours;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            return hours + ':' + minutes + ':' + seconds;
        };

        $scope.updateProject = function () {
            if ($scope.selectedUserItem.email !== null && typeof $scope.selectedUserItem.email !== 'undefined') {
                var updateProjectPayload = {
                    userEmail: $scope.selectedUserItem.email,
                    name: $scope.projectName
                };
            } else {
                var updateProjectPayload = {
                    userEmail: '',
                    name: $scope.projectName
                };
            }

            detailedTransactionService.update(updateProjectPayload, $scope.projectId)
                .then(function success(resource) {

                }, function error(error) {
                    $scope.resource = error;
                });
        };
        $scope.userDetailedView = function (id) {
            $state.go('app.admin.userManagement', {useriId: id});
        };
        function latestTransaction() {
            $state.go("admin.latestTransaction");
        }

        $scope.openExportModal = function () {
            var shortestMediaDuration = Number.MAX_VALUE;
            var shortestMedia = null;
            if ($scope.project.files && $scope.project.files.length) {
                $scope.project.files
                    .filter(function (media) {
                        return media.status && media.status !== 'empty' && media.duration > 0;
                    })
                    .forEach(function (media) {
                        if (media.duration < shortestMediaDuration) {
                            shortestMediaDuration = media.duration;
                            shortestMedia = media;
                        }
                    });
            }
            $scope.currentMedia = shortestMedia;
            $uibModal.open({
                animation: true,
                component: 'exportTranscriptionModal',
                resolve: {
                    mediaCount: function () {
                        return angular.isArray($scope.project.medias) ? $scope.project.medias.length : 0;
                    },
                    currentMediaId: function () {
                        return $scope.currentMedia.id;
                    },
                    project: function () {
                        return $scope.project;
                    }
                }
            });
        };

        $scope.isTeamMember = function () {
            if ($scope.project && $scope.project.owner && $scope.project.owner.team) {
                return true;
            }
            if ($scope.teamdetails && $scope.teamdetails.length) {
                for (var i = 0; i < $scope.teamdetails.length; i++) {
                    if ($scope.projectOwnerEmail === $scope.teamdetails[i].owner) {
                        return true;
                    }
                }
            }
        };

        function getPublicProjectLinkFromProject() {
          return sharedProjectsService.getProjectPublicLink($scope.project);
        }

        $scope.shareProject = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                backdrop: 'static',
                component: 'shareProjectModal',
                resolve: {
                    project: function () {
                        return $scope.project;
                    },
                    isProjectOwner: true, // Christian: Please verify this. As far as for the admin it should be true.
                    isTeamMember: true, // Christian: Please verify this. As far as for the admin it should be true.
                    isFromAdmin: true
                }
            });

            modalInstance.result
                .then(function (response) {
                    var sharePayload = {
                        id: $scope.project.id,
                        target: response.shareTarget || 'noTeam',
                        members: response.members
                    };

                    var publishPayload = {
                        id: $scope.project.id,
                        target: response.publishTarget || 'noPublic'
                    };

                    sharedProjectsRestService.add(sharePayload)
                        .then(function () {
                            $scope.isProjectShared = response.shareTarget !== 'noTeam';
                        })
                        .catch(function (reason) {
                            ssNotificationService.add({
                                type: 'danger',
                                msg: 'There was an error sharing this project.'
                            });
                            console.log(reason);
                        });

                    sharedProjectsRestService.add(publishPayload)
                        .then(function () {
                            $scope.isProjectPublished = response.publishTarget === 'public';
                        })
                        .catch(function (reason) {
                            ssNotificationService.add({
                                type: 'danger',
                                msg: 'There was an error sharing this project.'
                            });
                            console.log(reason);
                        });
                });
        };

        $scope.retryJob = function () {
            projectAdminService.retryJobRequest($scope.projectId)
                .then(function success(resource) {
                    console.log(resource);
                    ssNotificationService.add({
                        type: 'success',
                        msg: 'Retry job process has been started successfully.'
                    });
                }, function error(error) {
                    console.log(error.data.msg);
                    var message = error.data.msg;
                    ssNotificationService.add({
                        type: 'danger',
                        msg: message
                    });
                });
        };

        $scope.mergeProject = function () {
            $state.go('app.admin.projectMerging', {
              projectId: $scope.projectId,
              userId: $scope.project.owner.id
            });
        };

        $scope.loadOriginalProjectDetailView = function () {
            $state.go('app.admin.projectManagementItem', { projectId: $scope.project.translatedFromProjectId });
        };
    }
})();
