(function () {
    'use strict';
    angular
        .module('app')
        .directive('editableContent', function($timeout) {
            return {
                require: 'ngModel',
                link: function(scope, element, attrs, ngModel) {

                    function read() {
                        ngModel.$setViewValue(element[0].innerText);
                    }

                    ngModel.$render = function() {
                        element.html(ngModel.$viewValue || '');
                    };

                    element.bind('keypress', function(e) {
                        var keyCode = (e.keyCode ? e.keyCode : e.which);
                        return keyCode !== 13;
                    });

                    element.bind('keyup', function(e) {
                        var keyCode = (e.keyCode ? e.keyCode : e.which);
                        if (keyCode === 13 || keyCode === 27) {
                            scope.$apply(read);
                            element.blur();
                        }
                    });

                    element.bind('input', function(e) {
                        var maxNumberOfChars = 60;
                        if (element[0].innerText.length > maxNumberOfChars) {
                            element[0].innerText = element[0].innerText.substring(0, maxNumberOfChars);
                            setEndOfContenteditable(element[0]);
                        }
                    });

                    element.bind('blur', function() {
                        $timeout(function () {
                            element.attr('spellcheck', false);
                        });
                        scope.$apply(read);
                    });

                    element.bind('focus', function() {
                        $timeout(function () {
                            element.attr('spellcheck', true);
                            document.execCommand('selectAll',false,null)
                        });
                    });

                    function setEndOfContenteditable(contentEditableElement) {
                        var range,selection;
                        if (document.createRange) { //Firefox, Chrome, Opera, Safari, IE 9+
                            range = document.createRange();//Create a range (a range is a like the selection but invisible)
                            range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
                            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                            selection = window.getSelection();//get the selection object (allows you to change selection)
                            selection.removeAllRanges();//remove any selections already made
                            selection.addRange(range);//make the range you have just created the visible selection
                        } else if (document.selection) {  //IE 8 and lower
                            range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
                            range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
                            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                            range.select();//Select the range (make it the visible selection
                        }
                    }
                }
            };
        });
})();

