(function () {
    'use strict';
    angular
        .module('app')
        .factory('boxAuthenticationService', boxAuthenticationService);


    boxAuthenticationService.$inject = ['$http', 'host'];
    function boxAuthenticationService($http, host) {
        var baseUrl = host + 'box-auth/oauth2';

        return {
            authorize: authorize,
            deAuthorize: deAuthorize,
            complete: complete,
            getAuthToken: getAuthToken
        };

        function authorize() {
            return $http.get(baseUrl + '/');
        }

        function deAuthorize() {
            return $http.get(baseUrl + '/deauthorize');
        }
        function complete(accesstoken, refreshtoken) {
            return $http.get(baseUrl + '/complete?accesstoken=' + accesstoken + '&refreshtoken=' + refreshtoken);
        }

        function getAuthToken() {
            return $http.get(baseUrl + '/token');
        }
    }

})();
