(function () {
  'use strict';

  angular
    .module('app')
    .component('adminTeamDetailPage', {
      controller: AdminTeamDetailController,
      bindings: {
        teamId: '<'
      },
      templateUrl: 'app/admin/pages/admin-team-detail-page/admin-team-detail-page.component.html'
    });

  AdminTeamDetailController.$inject = [
    '$window',
    'adminTeamRestService',
    'adminSubscriptionRestService',
    'ssNotificationService'
  ];

  function AdminTeamDetailController(
    $window,
    adminTeamRestService,
    adminSubscriptionRestService,
    ssNotificationService
  ) {

    var me = this;
    var backupFormData;

    me.$onInit = onInit;
    me.isSubscriptionCreditValid = isSubscriptionCreditValid;
    me.removeTeamMember = removeTeamMember;
    me.saveChanges = saveChanges;
    me.cancelChanges = cancelChanges;

    function onInit() {
      retreiveTeamInfo();
      retreiveOwnerInfo();
      retreiveMembersInfo();
      retreiveTeamTransactions();
    }

    function makeBackupData() {
      backupFormData = Object.assign({}, me.team);
    }

    function retreiveTeamInfo() {
      adminTeamRestService.getTeam(me.teamId)
        .then(function (response) {
          me.team = response;
          makeBackupData();
        });
    }

    function retreiveOwnerInfo() {
      adminTeamRestService.getOwnerByTeamId(me.teamId)
        .then(function (response) {
          me.owner = response;
          return adminSubscriptionRestService.fetchUserSubscription(me.owner.id)
        })
        .then(function (response) {
          me.stripeSubscriptionDetail = response;
        })
        .catch(function (reason) {
          console.error(reason);
        });
    }

    function retreiveMembersInfo() {
      adminTeamRestService.getMembersByTeamId(me.teamId)
        .then(function (response) {
          me.members = response;
        });
    }

    function retreiveTeamTransactions() {
      adminTeamRestService.getTeamTransactions(me.teamId)
        .then(function (response) {
          me.transactions = response;
        });
    }

    function isSubscriptionCreditValid() {
      if (!me.team) {
        return false;
      }

      return !me.team.subscriptionCredit ||
        (me.team.subscriptionCreditExpireDate &&
          me.team.subscriptionCreditExpireDate > new Date());
    }

    function saveChanges() {
      adminTeamRestService.updateTeam(me.teamId, me.team)
        .then(function (response) {
          me.team = response;
          makeBackupData();
          me.teamDetailsForm.$setPristine();
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({ type: 'danger', msg: "There was a problem updating team details." });
        });
    };

    function cancelChanges() {
      me.teamDetailsForm.$setPristine();
      me.team = Object.assign({}, backupFormData);
    }

    function removeTeamMember(member) {
      if (!$window.confirm("Are you sure you want to remove the user " + member.email + " from this team?")) {
        return;
      }

      adminTeamRestService.removeTeamMember(me.teamId, member.id)
        .then(function () {
          onInit()
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({ type: 'danger', msg: "There was an error removing teammate." });
        })
    };

  }

})();
