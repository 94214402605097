(function () {
    'use strict';

    angular
        .module('app')
        .directive('autoMaxWidthElement', autoMaxWidthElement);

    autoMaxWidthElement.$inject = ['$parse', '$timeout'];

    /* @ngInject */
    function autoMaxWidthElement($parse, $timeout) {
        return {
            link: link,
            restrict: 'A'
        };

        function link(scope, element, attrs) {
            var containerElementSelector = $parse(attrs.autoWidthCointainer)(scope);
            var siblingElementsSelector = $parse(attrs.autoWidthSiblings)(scope);
            var autoWidthOffset = $parse(attrs.autoWidthElementOffset)(scope);
            var elementsWidthListener;
            var forceResizeListener;

            init();

            function init() {
                subscribeToEvents();
                forceResizeListener = scope.$on('forceResizeElement', function () {
                    $timeout(function () {
                        setMaxWidth();
                    }, 250);
                });

                scope.$on('$destroy', unsubscribeFromEvents);
            }

            function subscribeToEvents() {
                elementsWidthListener = scope.$watch(
                    function () {
                        return calculateWith();
                    },
                    function () {
                        setMaxWidth();
                    }
                );
            }

            function setMaxWidth() {
                $timeout(function () {
                    $(element).css('maxWidth', (calculateWith() - autoWidthOffset) + 'px');
                });
            }

            function unsubscribeFromEvents() {
                elementsWidthListener();
                forceResizeListener();
            }


            function calculateWith() {
                var containerElement = $(element).closest(containerElementSelector);

                var containerWidth = containerElement.width();
                var siblingsWidth = siblingElementsSelector
                    .map(function (siblingSelector) {
                        return $(containerElement).find(siblingSelector).width() || 0;
                    })
                    .reduce(function (accumulator, currentValue) {
                        return  accumulator + currentValue;
                    });

                var calculatedWidth = containerWidth - siblingsWidth;

                return calculatedWidth < 0 ? 0 : calculatedWidth;
            }



        }
    }

})();

