(function () {
  'use strict';

  angular
    .module('app')
    .factory('adminTeamRestService', adminTeamRestService);

  adminTeamRestService.$inject = ['$http', 'host'];

  /* @ngInject */
  function adminTeamRestService($http, host) {
    var basePathUrl = host + 'admin/teams/';

    var service = {
      getTeams: getTeams,
      getTeam: getTeam,
      getOwnerByTeamId: getOwnerByTeamId,
      getMembersByTeamId: getMembersByTeamId,
      getTeamTransactions: getTeamTransactions,
      updateTeam: updateTeam,
      deleteTeam: deleteTeam,
      removeTeamMember: removeTeamMember
    };
    return service;

    ////////////////

    function getTeams() {
      return $http.get(basePathUrl)
        .then(function (response) {
          return response.data;
        });
    }

    function getTeam(id) {
      return $http.get(basePathUrl + id)
        .then(function (response) {
          return response.data;
        });
    }

    function updateTeam(id, payload) {
      return $http.put(basePathUrl + id, payload)
        .then(function (response) {
          return response.data;
        });
    }

    function deleteTeam(teamId) {
      return $http.delete(basePathUrl + teamId);
    }

    function getOwnerByTeamId(teamId) {
      return $http.get(basePathUrl + teamId + '/owner')
        .then(function (response) {
          return response.data;
        });
    }

    function getMembersByTeamId(teamId) {
      return $http.get(basePathUrl + teamId + '/members')
        .then(function (response) {
          return response.data;
        });
    }

    function removeTeamMember(teamId, memberId) {
      return $http.delete(basePathUrl + teamId + "/members/" + memberId);
    }

    function getTeamTransactions(teamId) {
      return $http.get(basePathUrl + teamId + '/transactions')
        .then(function (response) {
          return response.data;
        });
    }

  }

})();

