(function () {
  'use strict';
  angular
    .module('app')
    .controller('addAdminController', addAdminController);

  addAdminController.$inject = [
    '$scope',
    'adminService',
    'ssNotificationService'
  ];
  function addAdminController(
    $scope,
    adminService,
    ssNotificationService
  ) {

    $scope.querySearch = querySearch;
    $scope.selectedItemChange = selectedItemChange;

    function init() {
      $scope.selectedAdmin = null;
      adminService.getAllAdmin()
        .then(function (response) {
          $scope.adminListing_ = response;
        })

    }
    init();

    function querySearch(searchText) {
      if (!searchText) {
        return;
      }

      adminService.getAdmin(searchText)
        .then(function (response) {
          $scope.userMatches = response;
          return response;
        })
    }

    function selectedItemChange(item) {
      if (item) {
        $scope.selectedAdmin = item;
      }
    }

    $scope.getName = function (item) {
      return item.fullName + " (" + item.email + ")";
    }
    $scope.saveAdmin = function () {
      if ($scope.selectedAdmin.id !== 'undefined') {
        adminService.saveAdmin($scope.selectedAdmin.id)

          .then(function () {
            init();
          }, function error(error) {
            ssNotificationService.add({ type: 'danger', msg: error });
          });

      }
      ;
    }
    $scope.removeAdmin = function (adminId) {
      if (adminId !== null) {
        adminService.updateAdminById(adminId)
          .then(function success(resource) {
            init();
          }, function error(error) {
            ssNotificationService.add({ type: 'danger', msg: error });
          });
      }
      ;
    }
  }
}());
