(function () {
    'use strict';

    //deprecated 
    angular
        .module("app")
        .directive("transcription", transcriptionDirective);

    function transcriptionDirective($rootScope, $timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {
                // This is the height of the row, depending on the font size of text area.
                var fontSizeInPixels = 16;

                function update(additionalHeight) {
                    $timeout(function () {
                        additionalHeight = additionalHeight || 0;
                        element.css("height", "auto");
                        var allHeight = element[0].scrollHeight + additionalHeight;
                        if (allHeight > 0) {
                            element.css("height", allHeight + "px");
                        }
                    });
                }

                function splitTranscription (possibleRows, originalTranscriptionObject) {
                    $rootScope.$emit("splitTranscription", {
                        originalTranscriptionObject: originalTranscriptionObject,
                        possibleRows: possibleRows
                    });
                }

                function setCaretPosition(elem, caretPos) {
                    if (elem) {
                        if (elem.createTextRange) {
                            var range = elem.createTextRange();
                            range.move('character', caretPos);
                            range.select();
                        } else {
                            if (elem.setSelectionRange) {
                                elem.focus();
                                elem.setSelectionRange(caretPos, caretPos);
                            } else
                                elem.focus();
                        }
                    }
                }

                scope.$watch(attr.ngModel,
                    function () {
                        update();
                    });
                scope.$watch(
                    function () {
                        return $(element[0]).width();
                    },
                    function () {
                        update();
                    }
                );

                $(element).on('keydown', function (e) {
                    var BACKSPACE_KEYCODE = 8;
                    var ENTER_KEYCODE = 13;
                    var ARROW_DOWN = 40;
                    var ARROW_UP = 38;

                    var keyCode = (e.keyCode ? e.keyCode : e.which);

                    var selectionStart = $(element).prop('selectionStart');
                    var selectionEnd = $(element).prop('selectionEnd');
                    var currentContainerElement = $(element).closest('.transcription-row');

                    if (keyCode === BACKSPACE_KEYCODE && selectionStart === 0 && selectionEnd === 0) {
                        scope.transcription.$willBeDeleted = true;
                        $rootScope.$emit('deleteCombineRow', scope.transcription);
                    }

                    if (keyCode === ARROW_DOWN && selectionStart === element[0].value.length) {
                        var nextRowElement = currentContainerElement.next().find('textarea.transcription-text');
                        $timeout(function () {
                            if (nextRowElement[0]) {
                                setCaretPosition(nextRowElement[0], 0);
                            }
                        });
                    }

                    if (keyCode === ARROW_UP && selectionStart === 0) {
                        var previousRowElement = currentContainerElement.prev().find('textarea.transcription-text');
                        $timeout(function () {
                            if (previousRowElement[0]) {
                                setCaretPosition(previousRowElement[0], previousRowElement[0].value.length);
                            }
                        });
                    }

                    if (keyCode === ENTER_KEYCODE) {
                        var firstRow = element[0].value.substring(0, selectionStart);
                        var secondRow = element[0].value.substring(selectionStart);

                        if (!e.ctrlKey && !e.shiftKey && !e.altKey && selectionStart < element[0].value.length) {
                            var possibleRows = [];
                            possibleRows[0] = firstRow;
                            possibleRows[1] = secondRow;
                            if (possibleRows[0].indexOf('\n') === (possibleRows[0].length -1)) {
                                possibleRows[0] = possibleRows[0].slice(0, -1);
                            }

                            var originalTranscriptionObject = angular.copy(scope.transcription);
                            ngModelCtrl.$setViewValue(possibleRows[0]);
                            ngModelCtrl.$render();

                            splitTranscription(possibleRows, originalTranscriptionObject);
                        } else if (e.ctrlKey || e.altKey) {

                            ngModelCtrl.$setViewValue(firstRow + '\n' + secondRow);
                            ngModelCtrl.$render();

                            $timeout(function () {
                                var caretPosition = firstRow.length + 1;
                                update(fontSizeInPixels);
                                setCaretPosition(element[0], caretPosition);
                            });
                        }
                    }
                });

                scope.$on('transcription.autoFocus', function (event, data) {
                    if (data.transcription.id === scope.transcription.id) {
                        $timeout(function () {
                            setCaretPosition(element[0], data.caretPosition);
                        });
                    }
                });

                $(window).resize(function () {
                    update();
                });

                attr.$set("ngTrim", "false");
            }
        }
    }
})();

