(function () {
    'use strict';

    angular
        .module('app')
        .factory('languagesService', languagesService);

    languagesService.$inject = ['$http', 'host'];

    /* @ngInject */
    function languagesService($http, host) {
        var service = {
            getAll: getAll
        };
        return service;

        ////////////////

        function getAll() {
            return $http.get(host + 'languages')
                .then(function (response) {
                    return response.data;
                });
        }
    }

})();

