(function () {
  'use strict';

  angular
    .module('app')
    .component('signUpWiggle', {
      bindings: {
        onClickButton: '&'
      },
      controller: SignUpWiggleController,
      templateUrl: 'app/components/sign-up-wiggle/sign-up-wiggle.component.html'
    });

  SignUpWiggleController.$inject = ['$timeout'];
  function SignUpWiggleController($timeout) {
    var me = this
    var max = 30;
    var min = 10;

    me.$onInit = init;

    function init() {
      scheduleNestWiggle(10000);
    }

    function scheduleNestWiggle(delayInMilliseconds) {
      return $timeout(function () {
        me.isTimeToWiggle = !me.isTimeToWiggle;
        var delayInSeconds = Math.floor(Math.random() * (max - min + 1)) + min;
        return scheduleNestWiggle(delayInSeconds * 1000);

      }, delayInMilliseconds);
    }


  }

})();
