(function () {
    'use strict';

    angular
        .module('app')
        .factory('sharedProjectsRestService', sharedProjectsRestService);

    sharedProjectsRestService.$inject = ['$http', 'host'];

    /* @ngInject */
    function sharedProjectsRestService($http, host) {
        var basePathUrl = host + 'shared-projects';

        var service = {
            getAll: getAll,
            getAllSharedWithMe: getAllSharedWithMe,
            getPeopleISharedProjectWith: getPeopleISharedProjectWith,
            getPeopleUserSharedProjectWith: getPeopleUserSharedProjectWith,
            add: add,
            isPublic: isPublic,
            getSharedInfo: getSharedInfo
        };
        return service;

        ////////////////

        function getAll() {
            return $http.get(basePathUrl)
                .then(function (response) {
                    return response.data;
                });
        }

        function add(payload) {
            return $http.post(basePathUrl, payload);
        }

        function getAllSharedWithMe() {
            return $http.get(basePathUrl + '-with-me')
                .then(function (response) {
                    return response.data;
                });
        }

        function getPeopleISharedProjectWith(projectId) {
            return $http.get(basePathUrl + '/' + projectId + '/members')
                .then(function (response) {
                    return response.data;
                });
        }

        function getPeopleUserSharedProjectWith(projectId) {
            return $http.get(basePathUrl + '/' + projectId + '/project-members')
                .then(function (response) {
                    return response.data;
                });
        }

        function isPublic(projectId) {
            return $http.get(basePathUrl + '/' + projectId + '/isPublic')
                .then(function (response) {
                    return response.data;
                });
        }

        function getSharedInfo(projectId) {
            return $http.get(basePathUrl + '/' + projectId + '/list')
                .then(function (response) {
                    return response.data;
                });
        }
    }

})();

