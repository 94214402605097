(function () {
  'use strict';

  angular
    .module('app')
    .factory('adminSubscriptionRestService', adminSubscriptionRestService);

  adminSubscriptionRestService.$inject = ['$http', 'host'];

  /* @ngInject */
  function adminSubscriptionRestService($http, host) {
    var baseUrl = host + 'admin/subscriptions';

    var service = {
      fetchUserSubscription: fetchUserSubscription,
    };
    return service;

    function fetchUserSubscription(userId) {
      return $http.get(baseUrl + '/users/' + userId)
        .then(function (response) {
          return response.data;
        });
    }

  }

})();

