(function () {
  'use strict';
  angular
    .module('app')
    .component('aboutPage', {
      controller: function () {},
      bindings: {},
      templateUrl: 'app/pages/about-page/about-page.component.html'
    });
})();
