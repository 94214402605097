(function () {
    'use strict';

    var INTERVAL_TYPES = {
      MONTH: 'month',
      YEAR: 'year'
    };

    angular
        .module('app')
        .constant('PLANS', {
            NO_SUBSCRIPTION_PLAN: 'PAY_AS_YOU_GO',
            INTERVAL_TYPES: INTERVAL_TYPES,
            PEOPLE_TYPES: {
              INDIVIDUAL: 'INDIVIDUAL',
              COMPANY: 'COMPANY',
            },
            // ENTERPRISE_PLAN : {
            //   MONTH: {
            //     name: 'enterprise',
            //     active: true,
            //     label: 'Enterprise On-premise',
            //     type: 'COMPANY',
            //     subscriptionCharge: 2000,
            //     interval: INTERVAL_TYPES.MONTH,
            //     planDescriptionList: [
            //       {
            //         itemOrder: 1,
            //         itemContent: 'Includes 100 hours/mo </br> Addtl hours: $10/hr'
            //       },
            //       {
            //         itemOrder: 2,
            //         itemContent: 'Minimum 3 month contract'
            //       },
            //       {
            //         itemOrder: 3,
            //         itemContent: 'Air-gapped, secure, on-prem'
            //       },
            //       {
            //         itemOrder: 4,
            //         itemContent: 'Companies with high-security demands & large volumes </br> Eg: studios, large productions, enterprise </br> Those who have SOC2/TPN/CDSA/MPAA compliance certifications </br> Remote shoots with unreliable Internet'
            //       },
            //       {
            //         itemOrder: 5,
            //         itemContent: '<a target="_blank" href="https://blog.simonsays.ai/private-secure-auto-transcription-on-premise-not-cloud-based-for-your-sensitive-21ddda56f1da">See more info.</a>'
            //       }
            //     ]
            //   },
            //   YEAR: {
            //     name: 'enterprise',
            //     active: true,
            //     label: 'Enterprise On-premise',
            //     type: 'COMPANY',
            //     subscriptionCharge: 20000,
            //     interval: INTERVAL_TYPES.YEAR,
            //     planDescriptionList: [
            //       {
            //         itemOrder: 1,
            //         itemContent: 'Includes 1200 hours/yr </br> Addtl hours: $10/hr'
            //       },
            //       {
            //         itemOrder: 2,
            //         itemContent: 'Air-gapped, secure, on-prem'
            //       },
            //       {
            //         itemOrder: 3,
            //         itemContent: 'Companies with high-security demands & large volumes </br> Eg: studios, large productions, enterprise </br> Those who have SOC2/TPN/CDSA/MPAA compliance certifications </br> Remote shoots with unreliable Internet'
            //       },
            //       {
            //         itemOrder: 4,
            //         itemContent: '<a target="_blank" href="https://blog.simonsays.ai/private-secure-auto-transcription-on-premise-not-cloud-based-for-your-sensitive-21ddda56f1da">See more info.</a>'
            //       }
            //     ]
            //   }


            // }
        });
})();
