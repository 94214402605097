(function () {
    'use strict';

    angular
        .module('app')
        .factory('dateHelperService', [
            function () {
                return {
                    toUTCDate: toUTCDate
                };

                function toUTCDate(date) {
                    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),  date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                }
            }
        ]);
})();
