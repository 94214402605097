(function () {
    'use strict';

    angular
        .module('app')
        .filter('mediaDetailsFilter', mediaDetails);

    mediaDetails.$inject = ['$filter'];
    function mediaDetails($filter) {
        return mediaDetailsFilter;

        function mediaDetailsFilter(mediaDetailsJson) {
            var resultArray = [];

            try {
                var mediaDetailsArray = JSON.parse(mediaDetailsJson);
                if (mediaDetailsArray.length) {
                    resultArray.push('<table>');

                    resultArray.push('<thead>');
                    resultArray.push('<tr>');
                    resultArray.push('  <th>');
                    resultArray.push('  Name');
                    resultArray.push('  </th>');
                    resultArray.push('  <th>');
                    resultArray.push('  Minutes');
                    resultArray.push('  </th>');
                    resultArray.push('</tr>');
                    resultArray.push('</thead>');

                    resultArray.push('<tbody>');

                    mediaDetailsArray.sort(function (a, b) {
                        var nameA = a.name.toUpperCase();
                        var nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    mediaDetailsArray.forEach(function (mediaDetail) {
                        resultArray.push('<tr>');
                        resultArray.push('  <td class="media-details-tooltip-name">');
                        resultArray.push(   mediaDetail.name);
                        resultArray.push('  </td>');

                        resultArray.push('  <td>');
                        resultArray.push(   $filter('number')(mediaDetail.duration / 60, 0));
                        resultArray.push('  </td>');
                        resultArray.push('</tr>');

                    });
                    resultArray.push('</tbody>');

                    resultArray.push('</table>');
                }


            } catch (e) {
                console.warn("Cannot parse mediaDetails");
            }

            return resultArray.join(' ');
        }
    }

})();
