(function () {
    'use strict';

    angular
        .module('app')
        .directive('selectOnClick', selectOnClick);

    selectOnClick.$inject = ['$window'];

    /* @ngInject */
    function selectOnClick($window) {
        var directive = {
            link: link,
            restrict: 'A',
        };
        return directive;

        function link(scope, element, attrs) {
            element.on('click', onClick);

            function onClick() {
                if (!$window.getSelection().toString()) {
                    // Required for mobile Safari
                    this.setSelectionRange(0, this.value.length)
                }
            }
        }
    }

})();

