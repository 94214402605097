angular.module("ngConstants", [])

.constant("host", "https://atlantistv-staged.backend.aitranscribe.com/")

.constant("ogUrl", "https://atlantistv-staged.aitranscribe.com/")

.constant("LANDING_PAGE_URL", "https://atlantistv-staged.aitranscribe.com/signin")

.constant("PUBLIC_SITE_URL", "https://public.atlantistv.aitranscribe.com")

.constant("AUTH0_CONFIG", {
	"clientID": "hi3vkwLAWxT3hceUi06ZSuKVpUpYoyMP",
	"domain": "atlantistv-aitranscribe.auth0.com",
	"responseType": "token id_token",
	"redirectUri": "https://atlantistv-staged.aitranscribe.com/callback",
	"scope": "openid profile email read:messages",
	"audience": "https://atlantistv.aitranscribe.com",
	"leeway": 180
})

.constant("GDRIVE_CLIENT_ID", "583103794895-ghk8f0pb4qbaksmju5eflcbrnnsa9ikp.apps.googleusercontent.com")

.constant("APP_CONFIG", {
	"ROUTE_PREFIX": "/app",
	"ROUTE_ADMIN_PREFIX": "/admin",
	"LOGOUT_REDIRECT_TO": "https://atlantistv-staged.aitranscribe.com/signin"
})

.constant("whitelabel", true)

.constant("company", "ATLANTIS TÉLÉVISION")

.constant("intercomId", "lny5peyv")

;