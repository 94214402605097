(function () {
  'use strict';

  angular
    .module('app')
    .constant('PROJECT_STATUS', {
      OPEN: 'open',
      UPLOADING: 'uploading',
      TRANSCRIBING: 'transcribing',
      READY: 'ready',
      TRANSLATING: 'translating',
      TRANSLATED: 'translated',
      SUBTITLING: 'subtitling',
      SUBTITLED: 'subtitled'
    });
})();
