(function () {
  'use strict';

  angular
    .module('app')
    .factory('ssStorageService', ssStorageService);

  ssStorageService.$inject = ['store'];

  function ssStorageService(store) {
    return store.getNamespacedStore('ss.ai');
  }

})();
