(function () {
    'use strict';
    angular
        .module('app')
        .factory('ssNotificationService', ssNotificationService);

    ssNotificationService.$inject = ['toastr', 'stringHelperService'];

    function ssNotificationService(toastr, stringHelperService) {
        var notifications = [];
        var publicProperties = ['id', 'type', 'msg', 'metadata'];

        return {
            add: add,
            clearAll: clearAll,
            remove: remove,
            getAll: getAll
        };

        function add(messageConfig) {
            var toastrMethod;

            switch (messageConfig.type) {
                case 'success':
                    toastrMethod = toastr.success;
                    break;
                case 'warning':
                    toastrMethod = toastr.warning;
                    break;
                case 'danger':
                    toastrMethod = toastr.error;
                     break;
                default:
                    toastrMethod = toastr.info;
                    break;
            }
            var toast = toastrMethod(messageConfig.msg, messageConfig.title);

            var newNotification = {
                id: stringHelperService.generateRandomString(10),
                type: messageConfig.type,
                msg: messageConfig.msg,
                metadata: messageConfig.metadata,
                toast: toast
            };
            notifications.push(newNotification);
            return _.pick(newNotification, publicProperties);
        }

        function remove(id) {
            var notificationToRemove = notifications.find(function (notification) {
                return notification.id === id;
            });

            if (notificationToRemove) {
                if(notificationToRemove.toast) {
                    toastr.clear(notificationToRemove.toast)
                }

                _.remove(notifications, function (notification) {
                    return notificationToRemove.id === notification.id;
                });
            }
        }

        function clearAll() {
            toastr.clear();
            notifications = [];
        }

        function getAll() {
            return notifications
                .map(function(notification) {
                   return _.pick(notification, publicProperties);
                });
        }
    }

})();
