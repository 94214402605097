(function () {
  'use strict';
  angular
    .module('app')
    .component('ssFooter', {
      // TODO: make this component reusable in the future
      controller: ssFooterController,
      templateUrl: 'app/components/ss-footer/ss-footer.component.html',
    });

    ssFooterController.$inject = ['company', 'whitelabel'];

    function ssFooterController(company, whitelabel) {
      var me = this;

      me.company = company;
      me.whitelabel = whitelabel;
    }
})();
