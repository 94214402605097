(function () {
  'use strict';
  angular
    .module('app')
    .service('fullStoryWrapperService', fullStoryWrapperService);

  fullStoryWrapperService.$inject = [];

  function fullStoryWrapperService() {
    return {
      identify: identify,
      logout: logout
    };

    function identify(user) {
      if (isFSLoaded() && user && user.id) {
        FS.identify(user.fsUid, {
          email: user.email
        });
      }
    }

    function logout() {
      if (isFSLoaded()) {
        FS.identify(false);
      }
    }

    function isFSLoaded() {
      return typeof FS !== 'undefined';
    }
  }

})();
