(function () {
    'use strict';

    angular
        .module('app')
        .factory('adminService', adminService);

    adminService.$inject = ['$http', 'host'];

    /* @ngInject */
    function adminService($http, host) {
        var basePathUrl = host + 'super-admin';
        
        var service = {
            getAdmin:getAdmin,
            saveAdmin:saveAdmin,
            updateAdminById:updateAdminById,
            getAllAdmin:getAllAdmin
        };
        return service;

        function getAdmin(name){
            return $http.get(basePathUrl+"/admin/"+name)
                    .then(function (response) {
                    return response.data;
                });;
        }
        function getAllAdmin(){
            return $http.get(basePathUrl+"/admin/admins")
                    .then(function (response) {
                    return response.data;
                });;
        }

        function saveAdmin(adminId) {
            return $http.post(basePathUrl + '/admin/' + adminId );
        }
        function updateAdminById(id){
            return $http.put(basePathUrl+"/admin/" + id);
        }
        
    }
    
})();

