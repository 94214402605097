(function () {
  'use strict';

  angular
    .module('app')
    .factory('adminUserRestService', adminUserRestService);

  adminUserRestService.$inject = ['$http', '$httpParamSerializer', 'host'];

  /* @ngInject */
  function adminUserRestService($http, $httpParamSerializer, host) {
    var basePathUrl = host + 'admin/users';

    return {
      getAll: getAll,
      getRecentActiveUsers: getRecentActiveUsers,
      getRecentJoinedUsers: getRecentJoinedUsers,
      getAllThatMatchesKeyword: getAllThatMatchesKeyword,
      getUserById: getUserById,
      getUserTransactions: getUserTransactions,
      getUserProjects: getUserProjects,
      updateUser: updateUser,
      deleteUser: deleteUser,
      synchronizingSubscription: synchronizingSubscription,
      fetchUserStripeInfo: fetchUserStripeInfo
    };

    function getAll(queryParams) {
      var queryString = '';
      if (queryParams) {
        queryString = '?' + $httpParamSerializer(queryParams);
      }

      return $http.get(basePathUrl + queryString)
        .then(function (response) {
          return response.data;
        });
    }

    function getRecentActiveUsers(topTypeCount) {
      var queryParams = {
        topTypeCount: topTypeCount,
        type: 'recentActive'
      };
      return getAll(queryParams);
    }

    function getRecentJoinedUsers(topTypeCount) {
      var queryParams = {
        topTypeCount: topTypeCount,
        type: 'recentJoined'
      };
      return getAll(queryParams);
    }

    function getAllThatMatchesKeyword(keyword) {
      var queryParams = {
        keyword: keyword
      };
      return getAll(queryParams);
    }

    function getUserById(id) {
      return $http.get(basePathUrl + '/' + id)
        .then(function (response) {
          return response.data;
        });
    }

    function getUserTransactions(id, pageNumber, pageSize) {
      var queryString = '';

      if (pageSize) {
        var queryParams = {
          pageNumber: pageNumber || 0,
          pageSize: pageSize
        };
        queryString = '?' + $httpParamSerializer(queryParams);
      }

      return $http.get(basePathUrl + '/' + id + '/transactions' + queryString)
        .then(function (response) {
          return fixOffsetPageNumber(response.data);
        });
    }

    function getUserProjects(id, pageNumber, pageSize) {
      var queryString = '';

      if (pageSize) {
        var queryParams = {
          pageNumber: pageNumber || 0,
          pageSize: pageSize
        };
        queryString = '?' + $httpParamSerializer(queryParams);
      }

      return $http.get(basePathUrl + '/' + id + '/projects' + queryString)
        .then(function (response) {
          return fixOffsetPageNumber(response.data);
        });
    }

    function updateUser(userId, payload) {
      return $http.put(basePathUrl + '/' + userId, payload)
        .then(function (response) {
          return response.data;
        });
    }

    function synchronizingSubscription(userId) {
      return $http.post(basePathUrl + '/' + userId + '/subscription-synchronizing')
        .then(function (response) {
          return response.data;
        });
    }

    function fetchUserStripeInfo(userId) {
      return $http.get(basePathUrl + '/' + userId + '/stripe-info')
        .then(function (response) {
          return response.data;
        });
    }

    function deleteUser(userId) {
      return $http.delete(basePathUrl + '/' + userId);
    }

    function fixOffsetPageNumber(pageable) {
      pageable.number = pageable.number + 1;
      return pageable;
    }

  }

})();

