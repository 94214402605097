(function () {
  'use strict';

  angular
    .module('app')
    .config(appRouteConfig);

  appRouteConfig.$inject = ['$urlRouterProvider', '$stateProvider', 'APP_CONFIG'];

  function appRouteConfig($urlRouterProvider, $stateProvider, APP_CONFIG) {

    $stateProvider
      .state('landingPage', {
        url: '/',
        component: 'landingPage'
      })
      .state('authCallbackPage', {
        url: '/callback',
        component: 'authCallbackPage'
      })
      .state('signinRedirect', {
        url: '/signin',
        component: 'authRedirectPage'
      })
      .state('signupRedirect', {
        url: '/signup',
        component: 'authRedirectPage'
      });

    $stateProvider
      .state('public', {
        url: '',
        abstract: true,
        component: 'publicPage'
      })
      .state('public.pricing', {
        url: '/pricing',
        component: 'subscribePage'
      })
      .state('public.about', {
        url: '/about',
        component: 'aboutPage'
      });

    $stateProvider
      .state('app', {
        url: APP_CONFIG.ROUTE_PREFIX,
        abstract: true,
        component: 'appPage',
        resolve: {
          user: function (usersRestService) {
            return usersRestService.getUserInfo();
          }
        }
      })
      .state('app.dashboard', {
        url: '',
        component: 'dashboardPage'
      })
      .state('app.noFound', {
        url: '/no-found',
        component: 'noFoundOops'
      })
      .state('app.accountSettings', {
        url: '/account-settings',
        reloadOnSearch: false,
        component: 'accountSettingsPage'
      })
      .state('app.project', {
        url: '/projects/:projectId',
        component: 'projectDetailsPage',
        resolve: {
          project: projectResolve,
          state: function () {
            return 'app.project';
          },
          subState: redirectToSubState
        }
      })
      .state('app.projectUpload', {
        url: '/projects/upload/:projectId',
        component: 'projectOpenPage',
        resolve: {
          project: projectResolve,
          state: function () {
            return 'app.projectUpload';
          },
          subState: redirectToSubState
        }
      })
      .state('app.projectTranscript', {
        url: '/projects/transcript/:projectId',
        component: 'projectTranscriptsPage',
        resolve: {
          project: projectResolve,
          state: function () {
            return 'app.projectTranscript';
          },
          subState: redirectToSubState
        }
      })

      .state('app.about', {
        url: '/about',
        component: 'aboutPage'
      })
      .state('app.subscribe', {
        url: '/subscribe',
        component: 'subscribePage'
      });


    $stateProvider
      .state('app.admin', {
        url: APP_CONFIG.ROUTE_ADMIN_PREFIX,
        abstract: true,
      })
      // TODO: Refator to components
      .state('app.admin.latestTransaction', {
        url: '/latestTransaction',
        templateUrl: 'app/admin/transactions/latest-transaction.html',
        controller: 'latestTransactionController',
        controllerAs: 'controller'
      })
      .state('app.admin.adminManagement', {
        url: '/addAdmin',
        templateUrl: 'app/admin/super-admin/admin.html',
        controller: 'addAdminController',
        controllerAs: 'controller'
      })
      .state('app.admin.blacklistWhitelist', {
        url: '/blacklistWhitelist',
        templateUrl: 'app/admin/black-whitelist/blacklist-whitelist.html',
        controller: 'blacklistWhitelistController',
        controllerAs: 'controller'
      })
      .state('app.admin.detailedTransaction', {
        url: '/detailedTransaction/:projectId',
        templateUrl: 'app/admin/transactions/detailed-transaction.html',
        controller: 'detailedTransactionController',
        controllerAs: 'controller'
      })
      .state('app.admin.userManagementList', {
        url: '/users',
        component: 'adminUserListPage'
      })
      .state('app.admin.userManagementItem', {
        url: '/users/:userId',
        component: 'adminUserDetailPage',
        resolve: {
          userId: function ($stateParams) {
            return $stateParams.userId
          }
        }
      })
      .state('app.admin.projectManagementItem', {
        url: '/projectDetailView/:projectId',
        templateUrl: 'app/admin/projects/project-detail-view.html',
        controller: 'projectDetailViewController',
        controllerAs: 'controller'
      })
      .state('app.admin.teamManagementList', {
        url: '/teams',
        component: 'adminTeamListPage'
      })
      .state('app.admin.teamManagementItem', {
        url: '/teams/:teamId',
        component: 'adminTeamDetailPage',
        resolve: {
          teamId: function ($stateParams) {
            return $stateParams.teamId
          }
        }
      })
      .state('app.admin.plans', {
        url: '/plans',
        component: 'adminPlansPage'
      })
      .state('app.admin.defaultConfig', {
        url: '/default-config',
        component: 'adminDefaultConfigPage',
      })
      .state('app.admin.projectMerging', {
        url: '/mergeProject/:projectId/:userId',
        templateUrl: 'app/admin/projects/merge-project.html',
        controller: 'mergeProjectController',
        controllerAs: 'controller'
      })
      .state('app.admin.metrics', {
        url: '/metrics',
        component: 'adminMetricsPage'
      })
      .state('app.admin.publishedProjects', {
        url: '/published-projects',
        templateUrl: 'app/admin/published-projects/published-projects.html',
        controller: 'PublishedProjectsController',
        controllerAs: '$ctrl',
        resolve: {
          publishedProjects: function (usersRestService, $state, publishedProjectsRestService) {
            return usersRestService.getUserInfo()
              .then(function (user) {
                var userRole = user.role.name;
                if (['ROLE_SUPERADMIN', 'ROLE_ADMIN'].indexOf(userRole) === -1) {
                  throw new Error('No Authorized');
                }
                return publishedProjectsRestService.getAll();
              })
              .catch(function (reason) {
                console.error(reason);
                $state.go("app.dashsboard");
              });

          }
        }
      })
      .state('app.noAuthorize', {
        url: '/no-authorize',
        component: 'noAuthorizePage'
      })
      .state('boxAuthCallbackPage', {
        url: '/box-auth-completion',
        component: 'boxCallbackPage'
      });

    $urlRouterProvider.otherwise('/app/no-found');
  }

  publicProjectResolve.$inject = ['$stateParams', 'projectService', 'sharedProjectsService'];

  function publicProjectResolve($stateParams, projectService, sharedProjectsService) {
    return projectService.get($stateParams.projectId)
      .then(function (response) {

        var userId = $stateParams.userId;
        var projectName = $stateParams.projectName;
        var userName = $stateParams.userName;

        if (userId !== response.data.owner.id) {
          throw new Error('Incorrect public url');
        }
        if (userName !== sharedProjectsService.getUserNamePartLink(response.data.owner)) {
          throw new Error('Incorrect public url');
        }

        if (projectName !== sharedProjectsService.getProjectNamePartLink(response.data)) {
          throw new Error('Incorrect public url');
        }

        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return null;
      });
  }

  projectResolve.$inject = ['$stateParams', 'projectService', 'ssStorageService'];

  function projectResolve($stateParams, projectService, ssStorageService) {
    return projectService.get($stateParams.projectId)
      .then(function (response) {
        ssStorageService.set('languageRegionCode', response.data.languageRegionCode);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return null;
      });
  }

  redirectToSubState.$inject = ['projectService', 'project', 'state'];
  function redirectToSubState(projectService, project, state) {
    projectService.redirectToSubState(project, state);
  }

})();
