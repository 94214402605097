(function () {
    'use strict';

    angular
        .module('app')
        .filter('middleEllipses', middleEllipses);

    function middleEllipses() {

        var DEFAULT_MIDDLE_ELLIPSES_CHARACTER = '...';

        return function(input, limit, middleEllipsesCharacter) {

            middleEllipsesCharacter = middleEllipsesCharacter || DEFAULT_MIDDLE_ELLIPSES_CHARACTER;

            if (limit < 3) {
                throw new Error('middleEllipses: Limit should be at least 3');
            }

            // Do nothing, the string doesn't need truncation.
            if (input.length <= limit) {
                return input;
            }

            var lengthOfTheSidesAfterTruncation = Math.floor((limit - 1) / 2);
            var finalLeftPart = input.slice(0, lengthOfTheSidesAfterTruncation);
            var finalRightPart = input.slice(input.length - lengthOfTheSidesAfterTruncation);

            return finalLeftPart + middleEllipsesCharacter + finalRightPart;
        };

    }
})();
