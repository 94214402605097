(function () {
    'use strict';

    angular
            .module('app')
            .component('subscribeToPlanModal', {
                controller: SubscribeToPlanModalController,
                bindings: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                templateUrl: 'app/components/subscribe-to-plan-modal/subscribe-to-plan-modal.component.html'
            });

    SubscribeToPlanModalController.$inject = ['$http', '$httpParamSerializer', '$rootScope', 'ssNotificationService', 'host', 'usSpinnerService', 'customEcommerceAnalytics'];

    function SubscribeToPlanModalController($http, $httpParamSerializer, $rootScope, ssNotificationService, host, usSpinnerService, customEcommerceAnalytics) {
        var me = this;

        me.$onInit = onInit;
        me.subscribe = subscribe;
        me.cancel = cancel;

        function onInit() {}

        function subscribe(plan, useSavedCard, card) {
            me.disabledSubmitButton = true;
            usSpinnerService.spin('spinner-1');

            var planData = me.resolve.planInfo;

            //TODO: Move into a service
            var url = host + 'subscription/subscribe/' + plan + '?';

            var queryParams = {
                use_saved_card: useSavedCard,
            };

            if (!useSavedCard) {
                queryParams.cardHolderName = card.cardHolderName;
                queryParams.number = card.cardNumber;
                queryParams.cvc = card.cvv;
                queryParams.exp_month = card.expirationMonth;
                queryParams.exp_year = card.expirationYear;
            }

            var queryString = $httpParamSerializer(queryParams);
            url += queryString;

            $http.post(url)
                    .then(function () {
                        usSpinnerService.stop('spinner-1');

                        var userRandomId = $rootScope.user.id + (Math.random() * 10);

                        // Clear transaction
                        customEcommerceAnalytics.clearTrans();

                        // start the transaction
                        customEcommerceAnalytics.addTransaction({
                            id: userRandomId,
                            affiliation: planData.planEnum,
                            revenue: planData.subscriptionCharge,
                            shipping: '0',
                            tax: '0'
                        });

                        customEcommerceAnalytics.addItem({
                            id: userRandomId,
                            name: planData.planEnum,
                            sku: planData.planEnum + '-SUB',
                            category: planData.planEnum,
                            price: planData.subscriptionCharge,
                            quantity: 1
                        });

                        // send transaction
                        customEcommerceAnalytics.trackTrans();


                        me.disabledSubmitButton = false;

                        me.close({$value: {
                                planName: me.resolve.planInfo.fullLabel
                            }});
                    })
                    .catch(function (error) {
                        var errorMessage = 'There was a problem with this card.';

                        if (error && error.data && error.data.message) {
                          errorMessage = error.data.message;
                        }
                        ssNotificationService.add({
                            type: 'danger',
                            msg: errorMessage
                        });

                        usSpinnerService.stop('spinner-1');
                        me.disabledSubmitButton = false;
                    });
        }

        function cancel() {
            me.dismiss();
        }


    }

})();
