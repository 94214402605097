(function () {
    'use strict';

    angular
            .module('app')
            .factory('latestTransactionService', latestTransactionService);

    latestTransactionService.$inject = ['$http', 'host'];

    /* @ngInject */
    function latestTransactionService($http, host) {
        var basePathUrl = host + 'admin/projects';

        var service = {
            getProjects: getProjects

        };
        return service;

        ////////////////

        function getProjects(payload) {
            return $http.post(basePathUrl + '/project-transactions', JSON.stringify(payload));
        }


    }

})();

