(function () {
  'use strict';

  angular
    .module('app')
    .service('projectService', projectService);

  projectService.$inject = ['$http', '$state', 'PROJECT_STATUS', 'host'];

  function projectService($http, $state, PROJECT_STATUS, host) {

    var validMediaStatusesToConsiderInPS4 = ['transcribed', 'transcribing', 'translated', 'translating', 'subtitled', 'subtitling'];

    var ps4ProjectStatuses = [
      PROJECT_STATUS.READY,
      PROJECT_STATUS.TRANSCRIBING,
      PROJECT_STATUS.TRANSLATING,
      PROJECT_STATUS.TRANSLATED,
      PROJECT_STATUS.SUBTITLING,
      PROJECT_STATUS.SUBTITLED
    ];

    //TODO: move function definition outside of the object. Refactor me please...
    return {
      redirectToSubState: redirectToSubState,
      remove: remove,
      getUserProjects: getUserProjects,
      getUserDirectories: getUserDirectories,
      newDirectory: newDirectory,
      moveProject: moveProject,
      removeDirectory: removeDirectory,
      newSyncProject: newSyncProject,
      updateToAllMedias: updateToAllMedias,
      newProject: function (payload) {
        var url = host + "projects"
        return $http.post(url, payload)
          .then(function (response) {
            return response.data;
          });
      },
      save: function (project) {
        var projectCopy = angular.copy(project);
        delete projectCopy.owner;
        return $http.put(host + "projects/" + project.id, projectCopy);
      },
      get: function (id) {
        return $http({
          url: host + "projects/" + id,
          method: 'GET'
        });
      },
      transcribe: function (id) {
        return $http({
          url: host + "projects/" + id + "/transcribing",
          method: 'POST'
        });
      },
      getShortestMedia: function (projectId) {
        return this.get(projectId).then(function (response) {
          var project = response.data;
          var shortestMediaDuration = Number.MAX_VALUE;
          var shortestMedia = null;
          if (project.medias && project.medias.length)
            project.medias
              .filter(function (media) {
                return media.status && validMediaStatusesToConsiderInPS4.indexOf(media.status) !== -1 && media.duration > 0;
              })
              .forEach(function (media) {
                if (media.duration < shortestMediaDuration) {
                  shortestMediaDuration = media.duration;
                  shortestMedia = media;
                }
              });
          return shortestMedia;
        });
      },
      export: function (options, projectId, callbackSuccess, callbackError) {
        var url = host + 'projects/' + projectId + '/exported-file/';
        var config = {};

        if (!options.destinationTarget || options.destinationTarget === 'local') {
          config.responseType = 'arraybuffer';
        }

        return $http.post(url, options, config)
          .then(callbackSuccess)
          .catch(function (reason) {
            if (reason.status === 422) {
              var arr = new Uint8Array(reason.data);
              var str = String.fromCharCode.apply(String, arr);
              if (/[\u0080-\uffff]/.test(str)) {
                throw new Error('this string seems to contain (still encoded) multibytes');
              }
              reason.data = angular.fromJson(str);
            }
            if (reason.status === 400) {
              reason.data = "Your selected media is not transcribed.";
            }
            callbackError(reason);
          });
      },
      exportSubtitle: function (projectId, callbackSuccess, callbackError) {
        var url = host + 'projects/' + projectId + '/exported-subtitle/';

        return $http.post(url)
          .then(callbackSuccess)
          .catch(function (reason) {
            if (reason.status === 422) {
              var arr = new Uint8Array(reason.data);
              var str = String.fromCharCode.apply(String, arr);
              if (/[\u0080-\uffff]/.test(str)) {
                throw new Error('this string seems to contain (still encoded) multibytes');
              }
              reason.data = angular.fromJson(str);
            }
            callbackError(reason);
          });
      }
    };

    function getUserProjects() {
      return $http.get(host + 'projects')
        .then(function (response) {
          return response.data;
        });
    }

    function getUserDirectories(){
      return $http.get(host + 'directory')
        .then(function (response) {
          return response.data;
        });
    }

    function newDirectory(payload){
      var resourceUrl = host + 'directory';
      return $http.post(resourceUrl, payload)
        .then(function (response) {
          return response.data;
        });
    }

    function moveProject(payload){
      var resourceUrl = host + 'directory/move';
      return $http.post(resourceUrl, payload)
        .then(function (response) {
          return response.data;
        });
    }

    function removeDirectory(directoryId){
      return $http.delete(host + "directory/remove/" + directoryId);
    }

    function updateToAllMedias(projectId, payload) {
      var resourceUrl = host + "project/" + projectId + "/medias";
      return $http.put(resourceUrl, payload);
    }

    function remove(projectId) {
      return $http.delete(host + "projects/" + projectId);
    }

    function newSyncProject(payload) {
      var resourceUrl = host + 'projects/mobile';
      return $http.post(resourceUrl, payload)
        .then(function (response) {
          return response.data;
        });
    }

    function redirectToSubState(project, state) {

      if (!project || !project.id) {
        $state.go('app.noFound');
        return;
      }
      if (!isProjectOpen(project)) {
        if (state !== 'app.projectTranscript') {
          $state.go('app.projectTranscript', { projectId: project.id });
        }
      } else {
        if (state !== 'app.projectUpload') {
          $state.go('app.projectUpload', { projectId: project.id });
        }
      }
    }

    function isProjectOpen(project) {
      if (!project) {
        return;
      }

      return ps4ProjectStatuses.indexOf(project.status) === -1;
    }
  }

})();
