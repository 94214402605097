(function () {
    'use strict';

    angular
        .module('app')
        .component('exportTranscriptionModal', {
            controller: ExportTranscriptionModalController,
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'app/components/export-transcription-modal/export-transcription-modal.component.html'
        });

    ExportTranscriptionModalController.$inject = [
      '$state',
      '$uibModal',
      'CustomAnalyticsService',
      'languagesService',
      'projectService',
      'usSpinnerService',
      'whitelabel'
    ];

    function ExportTranscriptionModalController(
      $state,
      $uibModal,
      CustomAnalyticsService,
      languagesService,
      projectService,
      usSpinnerService,
      whitelabel
    ) {
        var me = this;

        me.$onInit = onInit;
        me.ok = ok;
        me.cancel = cancel;
        me.isExportButtonEnabled = isExportButtonEnabled;
        me.getCurrentSelectedTypeFile = getCurrentSelectedTypeFile;
        me.whitelabel = whitelabel;

        function onInit() {
            hideLoadingIndicator();
            me.exportErrorMessage = '';

            me.subtitleExportOption = {
                    title: 'WYSIWYG Subtitle Editor',
                    name: 'wysiwyg'
            }

            me.translateOption = {
                title: 'Translate',
                name: 'translate'
            };

            me.otherExportSoftwareOption = {
                title: 'Other',
                name: 'other'
            };

            me.otherExportOptions = [
                {
                    title: 'Xml',
                    name: 'Xml',
                    fileType: 'xml'
                },
                {
                    title: 'Edius',
                    name: 'edius',
                    fileType: 'edius'
                },
                {
                    title: 'Json',
                    name: 'json',
                    fileType: 'json'
                },
                {
                    title: 'Lumberjack Ready',
                    name: 'lumberjackText',
                    fileType: 'lumberjackText'
                },
                {
                    title: 'Text',
                    name: 'txt',
                    fileType: 'txt'
                },
                {
                    title: 'Final Cut Pro 7',
                    name: 'finalCutPro7',
                    fileType: 'xml'
                }
            ];

            me.avidMediaComposerOptions = [
                {
                    title: 'Markers/Locators (UTF-8)',
                    fileType: 'avidLocatorUTF8',
                    name: 'avidLocator'
                },
                {
                    title: 'Markers/Locators (Mac OS Roman)',
                    fileType: 'avidLocatorMacOSRoman',
                    name: 'avidLocator'
                },
                {
                    title: 'ScriptSync',
                    fileType: 'avidScriptSync',
                    name: 'avidScriptSync'
                },
                {
                    title: 'STL',
                    name: 'stl',
                    fileType: 'stl'
                }
            ];

            me.subtitlesOptions = [
                {
                    title: 'SRT',
                    name: 'srt',
                    fileType: 'srt'
                },
                {
                    title: 'STL',
                    name: 'stl',
                    fileType: 'stl'
                },
                {
                    title: 'WebVTT',
                    name: 'vtt',
                    fileType: 'vtt'
                },
                {
                    title: 'SAMI',
                    name: 'sami',
                    fileType: 'sami'
                }
            ];

            me.softwareApps = [
                {
                    title: 'Microsoft Word',
                    name: 'word',
                    fileType: 'doc'
                },
                {
                    title: 'Microsoft Excel',
                    name: 'excel',
                    fileType: 'csv'
                },
                {
                    title: 'Adobe Premiere',
                    name: 'adobePremiere',
                    fileType: 'xml'
                },
                {
                    title: 'Final Cut Pro X',
                    name: 'finalCutProX',
                    fileType: 'fcpXml'
                },
                {
                    title: 'Adobe Audition',
                    name: 'adobeAudition',
                    fileType: 'xml'
                },
                {
                    title: 'Avid Media Composer',
                    name: 'avidMediaComposer'
                },
                {
                    title: 'DaVinci Resolve',
                    name: 'davinciResolve',
                    fileType: 'edl'
                },
                {
                    title: 'Subtitles',
                    name: 'subtitles'
                }
            ];

            me.selectedSoftwareApp = me.softwareApps[0].name;
            me.otherExportOption = me.otherExportOptions[0].fileType;
            me.selectedAvidOption = me.avidMediaComposerOptions[0].fileType;
            me.selectSubtitlesOption = me.subtitlesOptions[0].fileType;

            me.fcpXExportType = 'captions';

            me.withTimeCode = true;
            me.onlyBookmarks = false;
            me.currentMediaId = null;

            getAvailableTranslationLanguages();
        }

        function ok(destinationTarget) {

            if (me.selectedSoftwareApp) {

                if (me.selectedSoftwareApp === me.translateOption.name) {
                    openTranslateModal();
                } else if (me.selectedSoftwareApp === me.subtitleExportOption.name) {
                    showLoadingIndicator();
                    exportSubtitle(me.resolve.project.id);
                } else {
                    showLoadingIndicator();

                    var options = {
                        fileType: getCurrentSelectedTypeFile(),
                        onlyBookmarks: me.onlyBookmarks,
                        withTimeCode: me.withTimeCode,
                        currentMediaId: me.currentMediaId,
                        destinationTarget: destinationTarget,
                        avidLocatorType: me.selectedAvidOption,
                        jsonFileOptions: JSON.stringify({ fcpXExportType: me.fcpXExportType })
                    };

                    exportTranscription(options, me.resolve.project.id);
                }
            }
        }

        function cancel() {
            me.dismiss();
        }

        function getAvailableTranslationLanguages() {
            var sourceProjectLanguageCode = me.resolve.project.languageRegionCode.split(/-(.+)/)[0];
            languagesService.getAll()
                .then(function (languages) {
                    me.availableTranslateLanguages = languages.filter(function (language) {
                        return language.translationCode && language.code !== sourceProjectLanguageCode;
                    });

                    me.sourceProjectLanguage = languages.find(function (language) {
                        return language.code === sourceProjectLanguageCode;
                    });

                    me.isSourceProjectLanguageSupported = languages.find(function (language) {
                      return language.translationCode && language.translationCode === me.sourceProjectLanguage.translationCode;
                  });
                });
        }

        function isExportButtonEnabled() {
            return Boolean(getCurrentSelectedTypeFile()) || (me.selectedSoftwareApp === me.translateOption.name) || (me.selectedSoftwareApp === me.subtitleExportOption.name);
        }

        function getCurrentSelectedTypeFile() {
            var selectedSoftwareObject = _.find(me.softwareApps, {name: me.selectedSoftwareApp});
            var fileType = selectedSoftwareObject ? selectedSoftwareObject.fileType : '';

            if (!fileType && me.selectedSoftwareApp === me.otherExportSoftwareOption.name) {
                fileType = me.otherExportOption;
            }

            if (!fileType && me.selectedSoftwareApp === 'avidMediaComposer') {
                fileType = me.selectedAvidOption;
                if(fileType === 'avidLocatorUTF8' || fileType === 'avidLocatorMacOSRoman') {
                    fileType = 'avidLocator';
                }
            }

            if(!fileType && me.selectedSoftwareApp === 'subtitles') {
                fileType = me.selectSubtitlesOption;
            }

            if(fileType === "Xml" || fileType === "finalCutPro7")
                fileType = 'xml';
            if(fileType === 'edius')
                fileType = 'edl';

            return fileType;
        }

        function openTranslateModal() {
            me.close();

            $uibModal.open({
                animation: true,
                component: 'translateProjectModal',
                resolve: {
                    projectId: function () {
                        return me.resolve.project.id;
                    },
                    availableTranslateLanguages: function () {
                        return me.availableTranslateLanguages;
                    }
                }
            });
        }

        function exportTranscription(options, projectId) {
            me.exportErrorMessage = '';

            CustomAnalyticsService.trackEvent('Export', 'Click export button', null, null);

            // TODO: use promises instead of callbacks. This is an anti-pattern!! (Legacy code)
            projectService.export(options, projectId, onExportSuccess, onExportError);

            function onExportSuccess(response) {
              if (response.status === 201) {
                saveOnDropbox(response.data);
              } else {
                downloadFileLocally(response);
              }
            }
        }

        function exportSubtitle(projectId) {
            me.exportErrorMessage = '';

            //CustomAnalyticsService.trackEvent('Export', 'Click export button', null, null);

            // TODO: use promises instead of callbacks. This is an anti-pattern!! (Legacy code)
            projectService.exportSubtitle(projectId, onExportSuccess, onExportError);

            function onExportSuccess(response) {
                $state.go('app.project', {projectId: response.data.projectId}, {reload: true});
                me.close();
            }
        }

        function downloadFileLocally(response) {
          var data = response.data;
          var contentType = response.headers('Content-type');
          var contentDisposition = response.headers('Content-disposition');
          var filename = contentDisposition.split('filename=')[1];
          var blob = new window.Blob([data], {
              type: contentType + ';charset=utf-8'
          });

          var url = window.URL.createObjectURL(blob);

          var a = document.createElement('a');
          a.download = filename;
          a.href = url;
          a.textContent = filename;
          document.body.appendChild(a);
          a.click();

          setTimeout(function removeExportFileElement() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
          }, 100);

          hideLoadingIndicator();
          me.close();
        }

        function saveOnDropbox(url) {
          Dropbox.save({
            files: [
              {url: url}
            ],
            success: function () {
              hideLoadingIndicator();
              me.close();
            },

            // Cancel is called if the user presses the Cancel button or closes the Saver.
            cancel: function () {
              hideLoadingIndicator();
            },

            // Error is called in the event of an unexpected response from the server
            // hosting the files, such as not being able to find a file. This callback is
            // also called if there is an error on Dropbox or if the user is over quota.
            error: function (errorMessage) {
              onExportError({
                data: errorMessage
              });
            }
          });
        }

        function onExportError(reason) {
          hideLoadingIndicator();
          if (reason.status === 422) {
              me.exportErrorMessage = reason.data.message;
          } else if (reason.status === 400) {
              me.exportErrorMessage = reason.data;
          } else {
            me.exportErrorMessage = reason.data ? reason.data : reason;
          }

          console.error(reason);
      }

        function hideLoadingIndicator() {
            me.isRequestInProcess = false;
            usSpinnerService.stop('spinner-1');
        }

        function showLoadingIndicator() {
            me.isRequestInProcess = true;
            usSpinnerService.spin('spinner-1');
        }
    }

})();
