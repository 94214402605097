(function () {
    'use strict';

    angular
        .module('app')
        .constant('SPEECH_API_SPEED', {
            '1': 1,
            '2': 1,
            '3': 1,
            '4': 0.5
        });
})();

