(function () {
  'use strict';

  angular
    .module('app')
    .component('dashboardPage', {
      controller: DashboardController,
      bindings: {
        user: '<'
      },
      templateUrl: 'app/pages/dashboard-page/dashboard-page.component.html'
    });

  DashboardController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'projectService',
    'ssNotificationService',
    'sharedProjectsRestService',
    'usersRestService',
    'WebSocketConnectionService',
    'whitelabel'
  ];

  function DashboardController(
    $rootScope,
    $scope,
    $state,
    $window,
    projectService,
    ssNotificationService,
    sharedProjectsRestService,
    usersRestService,
    WebSocketConnectionService,
    whitelabel
  ) {
    var me = this;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;

    me.createNewProject = createNewProject;
    me.createNewFolder = createNewFolder;
    me.getProjectImage = getProjectImage;
    me.getProjectsIShared = getProjectsIShared;
    me.getSharedProjectsWithMe = getSharedProjectsWithMe;
    me.newSyncProject = newSyncProject;
    me.onDeselectProjectsWithMeTab = onDeselectProjectsWithMeTab;
    me.retrieveProjects = retrieveProjects;
    me.retriveDirectory = retriveDirectory;
    me.setTab = setTab;
    me.showConfirmDelete = showConfirmDelete;

    me.prepareProjectToTag = prepareProjectToTag;
    me.getProjectTag = getProjectTag;

    me.createLabel = createLabel;
    me.updateLabel = updateLabel;
    me.toogleTagToProject = toogleTagToProject;
    me.deleteUserTag = deleteUserTag;

    me.labelFilter = labelFilter;

    me.whitelabel = whitelabel;
    me.folderName = ''
    me.addFolder = addFolder;
    me.isAddFolder = false;

    me.toggleFolder = toggleFolder;
    me.toggleFolderOutside = toggleFolderOutside;
    me.zoomImage = zoomImage;
    me.zoomoutImage = zoomoutImage;
    me.selectProjects = selectProjects;
    me.selectedProjects = [];
    me.moveType = null;
    me.showConfirmDeleteFolder = showConfirmDeleteFolder;
    //for temp
    me.selectedProjectId = null;

    me.isShowTrashFolder = isShowTrashFolder;
    me.isHideTrashFolder = isHideTrashFolder;
    me.isShowTrashFolderProject = isShowTrashFolderProject;
    me.isHideTrashFolderProject = isHideTrashFolderProject;
    me.editFolderName = editFolderName;
    me.tempEditFolder = tempEditFolder;
    me.tempFolderName = null;
    me.parentDNDElement = "";
    me.receivedProjects = false;

    function onInit() {
      me.newUserTag = {};
      me.popoverTags = {};
      me.directories = [];
      me.showAdditionalButtons = false;
      me.activeIndexTab = 0;
      me.projectTypes = ['My Projects', 'Shared with me', 'Sharing with others'];
      // document.cookie = "selectedFolderName=null";
      // document.cookie = "selectedFolderId=null";
      subscribeToEvents();

      retrieveProjects();
      retriveDirectory();
      retrieveUserTags();
      retrieveUserProjectTags();
    }

    function onDestroy() {
      unSubscribeFromEvents();
    }

    function prepareProjectToTag(project) {
      console.log(project);
      me.popoverTags = {};
      me.popoverTags[project] = true;
      me.newUserTag = {};
      me.userTagToUpdate = {};
      me.projectToTag = project;
      me.stepTag = 0;
    }

    function labelFilter(project) {
      if (!angular.isArray(me.userTags)) {
        return true;
      }

      var tagFilters = me.userTags.filter(function (userTag) {
        return userTag.filterActive;
      });
      // $rootScope.$emit("labelFilter", tagFilters);
      if (tagFilters && tagFilters.length) {
        var projectTag = getProjectTag(project.id)
        if (!projectTag) {
          return false;
        }
        return tagFilters.find(function (tag) {
          return tag.id === projectTag.id;
        });
      }

      return true;
    }

    function createLabel() {
      usersRestService.createUserTags(me.newUserTag)
        .then(function (response) {
          retrieveUserTags();
          return toogleTagToProject(response);
        })
        .then(function () {
          me.newUserTag = {};
          me.stepTag = 0;
        })
        .catch(function (reason) {
          var errorMessage = 'There was an error creating the label';
          if (reason.status === 422 && reason && reason.data && reason.data.message) {
            errorMessage = reason.data.message;
          }
          ssNotificationService.add({
            type: 'danger',
            msg: errorMessage
          });
        });
    }

    function updateLabel() {
      usersRestService.updateUserTags(me.userTagToUpdate.id, me.userTagToUpdate)
        .then(function (response) {
          retrieveUserTags();
        })
        .then(function () {
          me.userTagToUpdate = {};
          me.stepTag = 0;
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error updating the label'
          });
        });
    }

    function retrieveUserTags() {
      // $rootScope.$emit("retrieveUserTags");
      usersRestService.getUserTags()
        .then(function (response) {
          me.userTags = response;
        });
    }

    function retrieveUserProjectTags() {
      // $rootScope.$emit("retrieveUserProjectTags");
      usersRestService.getUserProjectTags()
        .then(function (response) {
          me.userProjectTags = response;
        });
    }

    function getProjectTag(projectId) {
      if (angular.isArray(me.userProjectTags)) {
        var userProjectTag = me.userProjectTags.find(function (userProjectTag) {
          return userProjectTag.projectId === projectId;
        });
        if (userProjectTag) {
          if (me.userTags) {
            return me.userTags.find(function (userTag) {
              return userTag.id === userProjectTag.tagId;
            })
          }
        }
      }
    }

    function toogleTagToProject(userTag, isSelected) {
      var promise;
      if (!isSelected) {
        promise = usersRestService.associateTagToProject({
          tagId: userTag.id,
          projectId: me.projectToTag.id
        });
      } else {
        var userProjectTag = me.userProjectTags.find(function (userProjectTag) {
          return userProjectTag.projectId === me.projectToTag.id;
        });

        promise = usersRestService.desassociateTagFromProject(userProjectTag.id);
      }

      return promise
        .then(function () {
          retrieveUserProjectTags();
          me.popoverTags[me.projectToTag.id] = false;
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error assigning the label'
          });
        });
    }

    function deleteUserTag(tagId) {
      var confirm = $window.confirm('Are you sure you want to delete this label? This will affect all your projects that were using this label.');
      if (!confirm) {
        return;
      }

      usersRestService.deleteUserTags(tagId)
        .then(function () {
          retrieveUserTags();
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error deleting the label'
          });
        });
    }

    function subscribeToEvents() {
      WebSocketConnectionService.subscribe('newProject/user', $rootScope.user.id, function (newProject) {
        // console.log(newProject);
        if (!me.projects) {
          me.projects = [];
        }
        var foundProject = me.projects.find(function (project) {
          return project.id === newProject.id;
        });

        if (!foundProject) {
          me.projects.push(newProject);
          me.receivedProjects = true;
        }
      });
    }

    function unSubscribeFromEvents() {
      if ($rootScope.user, $rootScope.user.id) {
        WebSocketConnectionService.unsubscribe('newProject/user', $rootScope.user.id);
      }
    }

    function retrieveProjects() {
      projectService.getUserProjects()
        .then(function (projects) {
          me.projects = projects;

          if (me.projects.length) {
            for (let i = 0; i < me.projects.length; i++) {
              if (me.projects[i].name.length > 20) {
                me.projects[i].tempName = me.projects[i].name.substring(0, 20) + '....';
              }
              else {
                me.projects[i].tempName = me.projects[i].name;
              }
              me.projects[i].staticImgPath = 'img/poster-video.png';
            }
            // $rootScope.$emit("CallOnInit", {});
            // console.log(me.projects)
          }
          me.receivedProjects = true;
        })
        .catch(function (reason) {
          if (!reason.xhrStatus || reason.xhrStatus !== "abort") {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error getting the user projects.'
          });
          }
        });
    }

    function retriveDirectory() {
      projectService.getUserDirectories()
        .then(function (directories) {
          me.directories = directories;
        })
        .catch(function (reason) {
          if (!reason.xhrStatus || reason.xhrStatus !== "abort") {
            console.error(reason);
            ssNotificationService.add({
              type: 'danger',
              msg: 'There was an error getting the user directories.'
            });
          }
        });
    }

    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function createNewProject() {
      // let selectedFolderName = getCookie("selectedFolderName");
      // let selectedFolderId = getCookie("selectedFolderId");
      // let payload = {
      //   directoryName: selectedFolderName != 'null' ? selectedFolderName : null,
      //   directoryId: parseInt(selectedFolderId) ? parseInt(selectedFolderId) : null,
      //   name: 'My New Project'
      // }

      let payload = {
        name: 'My New Project'
      }
      projectService.newProject(payload)
        .then(function (response) {
          $state.go('app.project', { projectId: response.id });
        })
        .catch(function (reason) {
          console.error(reason);
          ssNotificationService.add({
            type: 'danger',
            msg: 'There was an error creating the project.'
          });
        });
    }

    function createNewFolder(e) {
      // if (event.keyCode === 13) {
      if (e) {
        if (me.folderName) {
          let result = {
            "name": me.folderName,
            "empty": false
          }

          projectService.newDirectory(result)
            .then(function (response) {
              if (response) {
                retriveDirectory();
                me.folderName = '';
                me.isAddFolder = false;
              }
            })
            .catch(function (reason) {
              console.error(reason);
              ssNotificationService.add({
                type: 'danger',
                msg: 'There was an error creating the folder.'
              });
            });
        }
        else {
          let result = {
            "name": 'My New Folder',
            "empty": false
          }

          projectService.newDirectory(result)
            .then(function (response) {
              if (response) {
                retriveDirectory();
                me.folderName = '';
                me.isAddFolder = false;

                setTimeout(function () {
                  document.getElementById("myAnchor0").focus();
                }, 1000);
              }
            })
            .catch(function (reason) {
              console.error(reason);
              ssNotificationService.add({
                type: 'danger',
                msg: 'There was an error creating the folder.'
              });
            });
        }
      }
      else {
        me.folderName = '';
        me.isAddFolder = false;
      }
      // }
      // else if (e === false) {
      //   me.folderName = '';
      //   me.isAddFolder = false;
      // }
    }

    function newSyncProject() {
      projectService.newSyncProject({ name: 'New Sync Project' })
        .then(function (response) {
          $state.go('app.project', { projectId: response.id });
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: "danger",
            msg: reason
          });
        })
    }

    function showConfirmDelete(projectId) {
      var confirm = $window.confirm('Are you sure you want to delete this project and ALL its content?');
      if (confirm) {
        deleteProject(projectId);
      }
    }

    function deleteProject(projectId) {
      projectService.remove(projectId)
        .then(function () {
          var myProjectIndex = _.findIndex(me.projects, { id: projectId });
          var sharedProjectIndex = _.findIndex(me.sharedProjects, { id: projectId });

          if (myProjectIndex !== -1) {
            //remove from folder in UI, if it contains a folder
            if (me.projects[myProjectIndex].directory) {
              alterFolderCount(me.projects[myProjectIndex].directory.id, -1);
            }
            me.projects.splice(myProjectIndex, 1);
          }

          if (sharedProjectIndex !== -1) {
            me.sharedProjects.splice(sharedProjectIndex, 1);
          }
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: "danger",
            msg: 'Error deleting project.'
          });
        });
    }

    function getSharedProjectsWithMe() {
      sharedProjectsRestService.getAllSharedWithMe()
        .then(function (response) {
          me.sharedProjectsWithMe = response;
          WebSocketConnectionService.subscribe('sharedProjectWith/user', $rootScope.user.id, function (newSharedProjectWithUser) {
            if (!_.find(me.sharedProjectsWithMe, { id: newSharedProjectWithUser.id })) {
              if (newSharedProjectWithUser.owner.team.id === $rootScope.user.team.id) {
                me.sharedProjectsWithMe.unshift(newSharedProjectWithUser);
              }
            }
          });

          WebSocketConnectionService.subscribe('unsharedProjectWith/user', $rootScope.user.id, function (unsharedProjectId) {
            var sharedProjectIndexToBeRemoved = _.findIndex(me.sharedProjectsWithMe, { id: unsharedProjectId });
            if (sharedProjectIndexToBeRemoved !== -1) {
              me.sharedProjectsWithMe.splice(sharedProjectIndexToBeRemoved, 1);
            }
          });
        });
    }

    function getProjectsIShared() {
      sharedProjectsRestService.getAll()
        .then(function (response) {
          me.sharedProjects = response;
        })
    }

    function onDeselectProjectsWithMeTab() {
      WebSocketConnectionService.unsubscribe('sharedProjectWith/user', $rootScope.user.id);
      WebSocketConnectionService.unsubscribe('unsharedProjectWith/user', $rootScope.user.id);
    }

    function setTab(index) {
      me.activeIndexTab = index;
    }

    function getProjectImage(path) {
      if (!path) {
        return 'img/poster-video.png';
      }

      return path;
    };

    function addFolder() {
      me.isAddFolder = !me.isAddFolder;
      if (me.isAddFolder) {
        var folderInput = $window.document.getElementById('folderInput');
        setTimeout(function () {
          folderInput.focus();
        }, 10);

      }
    }

    function toggleFolder(index, event) {
      if (event.type == "keydown" || event.type == "click") {
        return false;
      }

      if (me.directories[index].isOpen || me.directories[index].isOpen === true) {
        me.directories[index].isOpen = false;
      }
      else {
        me.directories[index].isOpen = true;
      }
    }

    function toggleFolderOutside(index, event) {
      if (me.directories[index].isOpen || me.directories[index].isOpen === true) {
        me.directories[index].isOpen = false;
      }
      else {
        me.directories[index].isOpen = true;
      }
    }

    function zoomImage(project) {
      project.isImagezoom = true;

      if (project.img === null || project.img === undefined) {
        // project.zoomImageSrc = project.medias[0].frameFilePath;
        project.zoomImageSrc = project.staticImgPath;
      }
      else {
        // project.zoomImageSrc = project.img;
        project.zoomImageSrc = project.staticImgPath;
      }
    }

    function zoomoutImage(project) {
      project.isImagezoom = false;
      project.zoomImageSrc = null;
    }

    function selectProjects(project, folder) {
      if (project.selected === true) {
        project.selected = false;
        var id = project.id;

        for (var i = 0; i < me.selectedProjects.length; i++) {
          if (me.selectedProjects[i].id == id) {

            me.selectedProjects.splice(i, 1);
            break;
          }
        }

      }
      else {
        if (me.selectedProjects.length > 0) {
          let currentProjectDirectory = me.selectedProjects[0].directory.name;
          // debugger;
          if (currentProjectDirectory === folder.name) {
            project.selected = true;
            me.selectedProjects.push(project)
          }
          else {

            for (var i = 0; i < me.selectedProjects.length; i++) {
              me.selectedProjects[i].selected == false
            }
            me.selectedProjects = [];
            project.selected = true;
            me.selectedProjects.push(project)
          }
        }
        else {
          project.selected = true;
          me.selectedProjects.push(project)
        }

      }
      // console.log(me.selectedProjects)
    }

    $scope.getSelectedItemsIncluding = function (list, item) {
      // console.log('getSelectedItemsIncluding')
      // console.log(list)
      // console.log(item)
      // item.selected = true;
      // debugger
      if (list === undefined) {
        me.moveType = 'MainToFolder';
      }
      else {
        me.moveType = null;
      }
      me.selectedProjectId = item.projectId;
      return true;
    };

    $scope.onDragstart = function (list, event) {
      // console.log('onDragstart')
      // console.log(list)
      // console.log(event)
    };

    $scope.onDrop = function (list, items, index) {
      // debugger
      let mType = null;
      let projectId = null;
      let directoryId = null
      if (list != null) {
        //folder to folder
        mType = 'FolderToFolder';
        list.empty = false;
        projectId = me.selectedProjectId;
        directoryId = list.id;
        let result = me.projects.filter(function (item) { return item.projectId === me.selectedProjectId; });
        if (result[0].directory) {
          alterFolderCount(result[0].directory.id, -1);
        }
        result[0].directory = list;
        alterFolderCount(directoryId, 1);
      }
      else {
        //folder to outside
        mType = 'FolderToMain';
        projectId = me.selectedProjectId;
        directoryId = null;
        let result = me.projects.filter(function (item) { return item.projectId === me.selectedProjectId; });
        if (result[0].directory) {
          alterFolderCount(result[0].directory.id, -1);
        }
        result[0].directory = null;
      }

      if (me.moveType === null) {
        me.moveType = mType;
      }

      if (list === null && me.moveType === "MainToFolder") {
        return true;
      }

      if (me.moveType === 'FolderToFolder' || me.moveType === 'MainToFolder') {
        let result = me.directories.filter(function (item) { return item.id === directoryId; });
        result[0].isOpen = true;
        // if (result[0].isOpen || result[0].isOpen === true) {
        //   result[0].isOpen = false;
        // }
        // else {
        //   result[0].isOpen = true;
        // }

        // console.log(result[0])
      }

      let payload = {
        projectsId: [projectId],
        folderId: directoryId,
        isFrom: me.moveType
      }

      projectService.moveProject(payload)
        .then(function (response) {
          // retriveDirectory();
        })
        .catch(function (reason) {
          console.error(reason);
          // ssNotificationService.add({
          //   type: 'danger',
          //   msg: 'There was an error moving the project.'
          // });
        });
      return true;
    }

    $scope.onMoved = function (list) {
      // console.log('onMoved')
      // console.log(list)
    };

    $scope.dragendCallback = function (folder) {
      // folder.dragging = false
      // console.log('dragendCallback')
    };

    function showConfirmDeleteFolder(folderId) {
      var confirm = $window.confirm('Are you sure you want to delete this folder and ALL its content?');
      if (confirm) {
        deleteFolder(folderId);
      }
    }

    function alterFolderCount(folderId, change) {
      var myDirectoryIndex = _.findIndex(me.directories, { id: folderId });
      if (myDirectoryIndex !== -1) {
        me.directories[myDirectoryIndex].totalProjectCount += change;
      }
    }

    function deleteFolder(folderId) {
      projectService.removeDirectory(folderId)
        .then(function (response) {
          console.log(response);
          var myDirectoryIndex = _.findIndex(me.directories, { id: folderId });

          if (myDirectoryIndex !== -1) {
            me.directories.splice(myDirectoryIndex, 1);
          }
        })
        .catch(function (reason) {
          console.error(reason);

          ssNotificationService.add({
            type: "danger",
            msg: 'Error deleting folder.'
          });
        });
    }

    function isShowTrashFolder(index, folder) {
      me.directories[index].showTrashFolder = true;
    }

    function isHideTrashFolder(index, folder) {
      if (me.directories[index].showTrashFolder || me.directories[index].showTrashFolder === true) {
        me.directories[index].showTrashFolder = false;
      }
    }

    function isShowTrashFolderProject(index, project) {
      me.projects[index].showTrashProject = true;
    }

    function isHideTrashFolderProject(index, project) {
      if (me.projects[index].showTrashProject || me.projects[index].showTrashProject === true) {
        me.projects[index].showTrashProject = false;
      }
    }

    function editFolderName(folder) {
      if (folder.name === '' || folder.name === null) {
        folder.name = me.tempFolderName;
      }
      else {
        let result = {
          "name": folder.name,
          "id": folder.id
        }

        let projects = me.projects.filter(function (item) {
          if (item.directory && item.directory.id) {
            return item.directory.id === folder.id;
          }
        });

        for (let index = 0; index < projects.length; index++) {
          projects[index].directory.name = folder.name;
        }


        projectService.newDirectory(result)
          .then(function (response) {
            if (response) {
              // retriveDirectory();
              // retrieveProjects();

              // let result = me.directories.filter(function (item) { return item.id === folder.id; });
              // result.name = folder.name;

              if (result.isOpen || result.isOpen === true) {
                result.isOpen = false;
              }
              else {
                result.isOpen = true;
              }
            }
          })
          .catch(function (reason) {
            console.error(reason);
            ssNotificationService.add({
              type: 'danger',
              msg: 'There was an error editing the folder.'
            });
          });
      }
    }

    function tempEditFolder(folder) {
      me.tempFolderName = folder.name;
    }

  }


}());
