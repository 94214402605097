(function () {
    'use strict';

    angular
        .module('app')
        .directive('tooltipFollowCursor', tooltipFollowCursor);

    tooltipFollowCursor.$inject = ['$document'];

    /* @ngInject */
    function tooltipFollowCursor($document) {
        return {
            link: link,
            restrict: 'A'
        };

        function link(scope, element, attrs) {
            var tooltip = angular.element('<span class="follower-tooltip"></span>');
            var body = $document.find('body').eq(0);
            body.append(tooltip);


            element.on('mousemove', function(e) {
                if (isTouchDevice()) {
                    return;
                }

                var offsetElement = $(element).offset();
                var elementWidth = $(element).width();

                var xCursorPosition = e.pageX;
                var yCursorPosition = e.pageY;

                var percentagePosition = (xCursorPosition - offsetElement.left) / elementWidth;

                tooltip[0].style.display = 'block';
                tooltip[0].style.top = (yCursorPosition + 5) + 'px';
                tooltip[0].style.left = (xCursorPosition + 15) + 'px';

                var tooltipCallbackResult = scope.$eval(attrs.tooltipFollowCursor, {$percentage: percentagePosition});
                tooltip[0].innerHTML = tooltipCallbackResult;

            });

            element.on('mouseleave', function(e) {
                if (isTouchDevice()) {
                    return;
                }
                tooltip[0].style.display = 'none';
            });

            scope.$on('$destroy', function () {
                element.off('mousemove');
                element.off('mouseleave');
            });

            function isTouchDevice() {
                return 'ontouchstart' in window;
            }
        }
    }



})();

