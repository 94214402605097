(function () {
    'use strict';

    angular
        .module('app')
        .component('translateProjectModal', {
            controller: TranslateProjectModalController,
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'app/components/translate-project-modal/translate-project-modal.component.html'
        });

    TranslateProjectModalController.$inject = [
        '$state',
        'ssNotificationService',
        'usSpinnerService',
        'translationService'
    ];

    function TranslateProjectModalController(
        $state,
        ssNotificationService,
        usSpinnerService,
        translationService
    ) {
        var me = this;
        var isRequestProcessing = false;

        me.$onInit = onInit;
        me.translate = translate;
        me.getProjectTranslationPrice = getProjectTranslationPrice;
        me.isSubmitButtonDisabled = isSubmitButtonDisabled;
        me.checkAllLanguages = checkAllLanguages;
        me.uncheckAllLanguages = uncheckAllLanguages;
        me.cancel = cancel;

        function onInit() {
            hideLoadingIndicator();
            getProjectTranslationPrice();
        }

        function getProjectTranslationPrice() {
            translationService.getProjectPrice(me.resolve.projectId, getSelectedLanguages().length)
                .then(function (response) {
                    me.priceDetails = response;
                    me.priceDetails.totalTime = moment.duration(me.priceDetails.totalDuration / 60, 'minutes')._data;
                    me.priceDetails.chargeableTime = moment.duration(me.priceDetails.chargeableTimeInSeconds / 60, 'minutes')._data;
                    me.priceDetails.chargeableTimeInMinutes = me.priceDetails.chargeableTimeInSeconds / 60;
                })
                .catch(function (reason) {
                    ssNotificationService.add({
                        type: 'danger',
                        msg: reason
                    });
                    console.error(reason);
                });
        }

        function translate(useSavedCard, card, shouldSaveCard) {
            showLoadingIndicator();

            var payload = {
                paymentDetails: {
                    useSavedCard: useSavedCard,
                    card: card,
                    shouldSaveCard: shouldSaveCard
                },
                targetLanguageIds: getSelectedLanguages().map(function (language) {
                  return language.id;
                })
            };

            translationService.translateProject(me.resolve.projectId, payload)
                .then(function (response) {
                    hideLoadingIndicator();
                    me.close();
                    $state.go('app.project', {projectId: response.projectId}, {reload: true});
                })
                .catch(function (reason) {
                    ssNotificationService.add({
                        type: 'danger',
                        msg: reason
                    });
                    console.error(reason);
                    hideLoadingIndicator();
                });


        }

        function showLoadingIndicator() {
            usSpinnerService.spin('spinner-1');
            isRequestProcessing = true;
        }

        function hideLoadingIndicator() {
            usSpinnerService.stop('spinner-1');
            isRequestProcessing = false;
        }

        function isSubmitButtonDisabled() {
            return isRequestProcessing || !getSelectedLanguages().length;
        }

        function getSelectedLanguages() {
          return me.selectedLanguages || [];
        }

        function checkAllLanguages() {
          me.selectedLanguages = [].concat(me.resolve.availableTranslateLanguages);
          getProjectTranslationPrice();
        }

        function uncheckAllLanguages() {
          me.selectedLanguages = [];
          getProjectTranslationPrice();
        }

        function cancel() {
            me.dismiss();
        }

    }

})();
