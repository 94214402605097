(function () {
  'use strict';

  angular
    .module('app')
    .component('adminDefaultConfigPage', {
      bindings: {},
      controller: AdminDefaultConfigController,
      templateUrl: 'app/admin/pages/admin-default-config-page/admin-default-config-page.component.html'
    });

  AdminDefaultConfigController.$inject = ['adminDefaultConfigRestService', 'ssNotificationService'];

  function AdminDefaultConfigController(adminDefaultConfigRestService, ssNotificationService) {
    var me = this;
    var backupFormData;

    me.$onInit = onInit;
    me.updateConfig = updateConfig;
    me.cancelUpdate = cancelUpdate;

    function onInit() {
      adminDefaultConfigRestService.getDefaultConfig()
        .then(function (response) {
          me.defaultConfig = response;
          makeBackupData();
        })
        .catch(function () {
          ssNotificationService.add({
            type: 'danger',
            msg: 'Error getting default config info.',
          });
        });
    }

    function updateConfig() {
      adminDefaultConfigRestService.updateDefaultConfig(me.defaultConfig)
        .then(function (response) {
          me.defaultConfig = response;
          makeBackupData();
          me.defaultConfigForm.$setPristine();
        })
        .catch(function () {
          ssNotificationService.add({
            type: 'danger',
            msg: 'Error updating default config info.',
          });
        })
    }

    function cancelUpdate() {
      me.defaultConfigForm.$setPristine();
      me.defaultConfig = Object.assign({}, backupFormData);
    }

    function makeBackupData() {
      backupFormData = Object.assign({}, me.defaultConfig);
    }
  }

})();
